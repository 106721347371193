import React from 'react';
import { useQuery } from 'react-query';
import { Box, Chip, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchCRKRegistry, fetchKomitentById, getCRK } from '../api';
import { ClientAvatar, ServerError, Spinner } from '.';

const CRK = ({ application }) => {
  const { data: registriData, error: registriError, isLoading: registriIsLoading } = useQuery(
    'CRKregistry',
    fetchCRKRegistry
  );
  const { data: userData, error: userError, isLoading: userIsLoading } = useQuery(
    ['fetchKomitentById', application.komitent],
    () => fetchKomitentById(application.komitent)
  );
  const { data: CRKdata, error: CRKerror, isLoading: CRKisLoading } = useQuery(
    ['getCRK', application.brojzahtjeva],
    () => getCRK(application.brojzahtjeva)
  );

  const getZaduzenjeZirant = (dataRow) => {
    for (let i = 0; i < registriData.zaduzenjeZirant.length; i++) {
      if (registriData.zaduzenjeZirant[i].value == dataRow.zaduzenjeZirant) return registriData.zaduzenjeZirant[i].text;
    }
    return null;
  };
  const getPodtipZaduzenja = (dataRow) => {
    for (let i = 0; i < registriData.podtipZaduzenja.length; i++) {
      if (registriData.podtipZaduzenja[i].value == dataRow.podtipZaduzenja) return registriData.podtipZaduzenja[i].text;
    }
    return null;
  };

  return (
    <Container>
      <Box m={2}>
        <Typography variant="h5">Podaci iz CRK registra</Typography>
      </Box>
      {(registriError || CRKerror || userError) && <ServerError />}
      {(CRKisLoading || registriIsLoading || userIsLoading) && <Spinner />}
      {CRKdata &&
        registriData &&
        userData &&
        CRKdata.map((crkdata, index) => (
          <>
            <Divider />
            <Box ml={2} key={index}>
              <ListItem>
                <ListItemAvatar>
                  <div>
                    <ClientAvatar
                      type={userData[0].vrstaKomitenta}
                      gender={userData[0].spol}
                      delinquent={userData[0].delikvent}
                    />
                  </div>
                </ListItemAvatar>
                <ListItemText
                  primary={`${userData[0].ime} ${userData[0].otac == null ? '' : `(${userData[0].otac})`} ${
                    userData[0].prezime == null ? '' : userData[0].prezime
                  }`}
                  secondary={`Datum provjere: ${
                    crkdata.datumProvjere ? new Date(crkdata.datumProvjere).toLocaleDateString('de-DE') : '-'
                  }`}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
              {crkdata.zaduzenjeZirant && (
                <StyledChip size="small" label={getZaduzenjeZirant(crkdata)} color="primary"></StyledChip>
              )}
              {crkdata.podtipZaduzenja && (
                <StyledChip size="small" label={getPodtipZaduzenja(crkdata)} color="secondary"></StyledChip>
              )}
            </Box>
            <Box display="flex" flexDirection="column" m={2} mt={4} mb={0}>
              <Typography variant="body2">Naziv institucije</Typography>
              <BotText variant="subtitle1">{crkdata.nazivInstitucije || '-'}</BotText>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="100%" display="flex" flexDirection="column" m={2} ml={2} mt={0} bgcolor="background.paper">
                <Typography variant="body2">Datum odobrenja</Typography>
                <BotText variant="subtitle1">
                  {crkdata.datumOdobrenja ? new Date(crkdata.datumOdobrenja).toLocaleDateString('de-DE') : '-'}
                </BotText>
                <Typography variant="body2">Dozvoljeni limit</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.dozvoljeniLimit * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Iznos rate</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.iznosRate * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Tekuća klasifikacija</Typography>
                <BotText variant="subtitle1">{crkdata.tekucaKlasifikacija || '-'}</BotText>
                <Typography variant="body2">Iznos</Typography>
                <BotText variant="subtitle1">{Math.round(crkdata.iznos * 100 + Number.EPSILON) / 100 || '-'}</BotText>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" m={2} ml={2} mt={0} bgcolor="background.paper">
                <Typography variant="body2">Kraj otplate</Typography>
                <BotText variant="subtitle1">
                  {crkdata.krajOtplate ? new Date(crkdata.krajOtplate).toLocaleDateString('de-DE') : '-'}
                </BotText>
                <Typography variant="body2">Neiskorišteni limit</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.neiskoristeniLimit * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Glavnica u dugu</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.glavnicaDug * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Razlog gašenja</Typography>
                <BotText variant="subtitle1">{crkdata.razlogGasenja || '-'} </BotText>
                <Typography variant="body2">Ostatak duga</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.ostatakDuga * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
              </Box>
            </Box>
          </>
        ))}
    </Container>
  );
};

const Container = styled('div')`
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
`;
const BotText = styled(Typography)`
  ${({ theme }) => `
    margin-bottom:15px;
    `}
`;
const StyledChip = styled(Chip)`
  margin-right: 4px;
  margin-top: -16px;
  font-size: 0.75rem;
  height: 16px;

  border-radius: 3px;
`;
export default CRK;
