const columns = [
  { header: 'Izvještaj', key: 'izvjestaj' },
  { header: 'Opis', key: 'opis' },
  { header: 'Klijent', key: 'klijent' },
  { header: 'Radnik', key: 'radnik' },
  { header: 'Mobilni telefon', key: 'mobilniTelefon' },
  { header: 'Fiksni telefon', key: 'fiskni' },
  { header: 'Broj ugovora', key: 'brojUgovora' },
  { header: 'Djelatnost', key: 'djelatnost' },
  { header: 'Dani kašnjenja', key: 'daniKasnjenja' },
  { header: 'Datum isplate', key: 'datumIsplate' },
  { header: 'Telefoni sudužnika', key: 'telefonMob' },
];

export default columns;
