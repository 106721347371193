import { styled, Typography } from '@mui/material';

export const WrapTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  @media only screen and (max-width: 330px) {
    white-space: nowrap;
    width: 80px;
  }
  @media only screen and (min-width: 330px) and (max-width: 350px) {
    white-space: nowrap;
    width: 100px;
  }
  @media only screen and (min-width: 350px) and (max-width: 390px) {
    white-space: nowrap;
    width: 130px;
  }
  @media only screen and (min-width: 390px) and (max-width: 420px) {
    white-space: nowrap;
    width: 180px;
  }
  @media only screen and (min-width: 420px) and (max-width: 460px) {
    white-space: nowrap;
    width: 190px;
  }
  @media only screen and (min-width: 460px) and (max-width: 500px) {
    white-space: nowrap;
    width: 220px;
  }
`;

export const WrapDescription = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  @media only screen and (max-width: 330px) {
    white-space: nowrap;
    width: 80px;
  }
  @media only screen and (min-width: 330px) and (max-width: 350px) {
    white-space: nowrap;
    width: 100px;
  }
  @media only screen and (min-width: 350px) and (max-width: 390px) {
    white-space: nowrap;
    width: 130px;
  }
  @media only screen and (min-width: 390px) and (max-width: 430px) {
    white-space: nowrap;
    width: 160px;
  }
  @media only screen and (min-width: 430px) and (max-width: 460px) {
    white-space: nowrap;
    width: 190px;
  }
  @media only screen and (min-width: 460px) and (max-width: 500px) {
    white-space: nowrap;
    width: 210px;
  }
`;
