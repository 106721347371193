import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosArrowBack } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Form, Input } from '@components/rhf/src/MUI5';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  styled,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import {
  editAnalysis,
  editAnalysisSuduznik,
  getAnalysis,
  getComitentAnalysis,
  getSuduznici,
  insertAnalysis,
  deleteAnalysis,
} from '../api';
import { ServerError, Spinner } from '../components';
import { getFirstLine } from '../utils';
import AnalysisCodebtorLegal from './AnalysisCodebtorLegal';
import AnalysisCodebtorPersonal from './AnalysisCodebtorPersonal';
import { IoTrashOutline } from 'react-icons/io5';

const AnalysisLider = ({ brojZahtjeva, komitentId, previous, next, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [value, setValue] = React.useState('1');
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { mutateAsync: mutateAdd } = useMutation(insertAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno unesena analiza.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
      queryClient.invalidateQueries(['getComitentAnalysis', brojZahtjeva, komitentId]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });
  //Duznik
  const { mutateAsync: mutateEdit } = useMutation(editAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno sačuvane promjene.', {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
      queryClient.invalidateQueries(['getComitentAnalysis', brojZahtjeva, komitentId]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  //suduznici
  const { mutateAsync: mutateEditSuduznik } = useMutation(editAnalysisSuduznik, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno sačuvane promjene.', {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
      queryClient.invalidateQueries(['getComitentAnalysis', brojZahtjeva, komitentId]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutate: mutateDelete } = useMutation(deleteAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno obrisana stavka analize.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
      queryClient.invalidateQueries(['getComitentAnalysis', brojZahtjeva, komitentId]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
    },
  });

  const methodsDuznik = useForm({});

  //Analize suduznika
  const analysis = useQuery(['getAnaliza', brojZahtjeva], () => getAnalysis(brojZahtjeva), {
    enabled: !!brojZahtjeva,
    onSuccess: (res) => {},
  });
  //Analiza duznika
  const comitentAnalysis = useQuery(
    ['getComitentAnalysis', brojZahtjeva, komitentId],
    () => getComitentAnalysis(brojZahtjeva, komitentId),
    {
      enabled: !!brojZahtjeva,
      onSuccess: (res) => {
        methodsDuznik.reset(res);
      },
    }
  );
  let suduznici = useQuery(['suduznici', brojZahtjeva], () => getSuduznici(brojZahtjeva));

  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    values.komitent = komitentId;
    //Neophodno porediti polje po polje?
    if (
      !(
        values.comitentInstallment == comitentAnalysis.data.comitentInstallment &&
        values.description == comitentAnalysis.data.description &&
        values.householdCost == comitentAnalysis.data.householdCost &&
        values.householdInstallment == comitentAnalysis.data.householdInstallment &&
        values.investmentIncome == comitentAnalysis.data.investmentIncome &&
        values.otherLoanInstallments == comitentAnalysis.data.otherLoanInstallments &&
        values.value == comitentAnalysis.data.value
      )
    ) {
      return mutateEdit(values);
    } else {
      enqueueSnackbar('Nije došlo do promjena u analizi.', {
        variant: 'warning',
        autoHideDuration: 1000,
      });
    }
  };

  const addNewAnalysis = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: komitentId };
    mutateAdd(values);
  };

  const deleteAnalysisF = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: komitentId };
    mutateDelete(values);
  };
  return (
    <Box sx={{ width: '100%' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {(analysis.error || comitentAnalysis.error || suduznici.error) && <ServerError error={analysis.error} />}
      {(analysis.isLoading || comitentAnalysis.isLoading || suduznici.isLoading) && <Spinner />}
      {!analysis.error &&
        !analysis.isLoading &&
        !comitentAnalysis.error &&
        !comitentAnalysis.isLoading &&
        !suduznici.error &&
        !suduznici.isLoading && (
          <Box width="90vw">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList variant="scrollable" onChange={handleChange}>
                  <Tab label="Dužnik" value="1" />
                  {suduznici.data.data &&
                    suduznici.data.data.map((suduznik, i) => {
                      return <Tab label="Sudužnik" value={(i + 2).toString()} />;
                    })}
                </TabList>
              </Box>

              <TabPanel value="1">
                {_.isEmpty(comitentAnalysis.data) ? (
                  <Button onClick={() => addNewAnalysis()} variant="contained" color="primary" type="submit">
                    Dodaj analizu
                  </Button>
                ) : (
                  <Form spacing={3} methods={methodsDuznik} onSubmit={handleSubmit} inputProps={{ disabled: disabled }}>
                    <Grid container spacing={1}>
                      <Grid item xs={10}>
                        <Typography mb={3} variant="h6" color="primary">
                          Analiza domaćinstva/pravnog lica
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton size="large" onClick={() => setDeleteOpen(true)}>
                          <IoTrashOutline size={20} id="trashIcon" />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography mt={1} variant="body2">
                          Rata traženog kredita
                        </Typography>
                        <Typography mt={4} variant="body2">
                          Prihodi podnosioca zahtjeva
                        </Typography>
                        <Typography mt={3} variant="body2">
                          Prihodi ostalih clanova domacinstva
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.loanInstallment * 1000 + Number.EPSILON) / 1000 || '0'}
                        </BotText>
                        <BotText variant="subtitle1">{comitentAnalysis.data.comitentIncome || '0'}</BotText>
                        <BotText variant="subtitle1">{comitentAnalysis.data.householdIncome || '0'}</BotText>
                      </Grid>
                    </Grid>
                    <Input label="Troškovi domaćinstva/Troškovi pravnog lica" name="householdCost" />
                    <Input label="Troškovi rate kredita podnosioca zahtjeva" name="comitentInstallment" type="number" />
                    <Input
                      label="Troškovi rate kredita članova domaćinstva"
                      name="householdInstallment"
                      type="number"
                    />
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography mt={1} variant="body2">
                          A rezultat
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.resultA * 1000 + Number.EPSILON) / 1000 || '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <Input label="Prihodi od investicije" name="investmentIncome" type="number" />
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography mt={1} variant="body2">
                          B rezultat
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.resultB * 1000 + Number.EPSILON) / 1000 || '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <DividerStyle />

                    <Typography ml={1} mb={3} variant="h6" color="primary">
                      Koeficijenti
                    </Typography>
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        {comitentAnalysis.data.komitentType == 'F' && (
                          <Typography variant="body2">Pokazatelj rizika plasmana kredita</Typography>
                        )}
                        <Typography variant="body2">Pokazatelj rizika plasmana kredita (sa investicijom)</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {comitentAnalysis.data.komitentType == 'F' && (
                          <BotText variant="subtitle1">
                            {Math.round(comitentAnalysis.data.creditRiskPlacement * 1000 + Number.EPSILON) / 1000 ||
                              '0'}
                          </BotText>
                        )}
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.creditRiskPlacementInvestition * 1000 + Number.EPSILON) /
                            1000 || '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <DividerStyle />

                    <Typography ml={1} mb={3} variant="h6" color="primary">
                      Ocjena kreditne sposobnosti dužnika
                    </Typography>
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography mt={1} variant="body2">
                          Prihodi dokumentovano
                        </Typography>
                        <Typography mt={4} variant="body2">
                          Prihodi izjava
                        </Typography>
                        <Typography mt={4} variant="body2">
                          Prihodi ukupno
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">{comitentAnalysis.data.documentedIncome || '0'}</BotText>
                        <BotText variant="subtitle1">{comitentAnalysis.data.statementIncome || '0'}</BotText>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.sumIncome * 1000 + Number.EPSILON) / 1000 || '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <Input label="Ostale rate kredita" name="otherLoanInstallments" type="number" />
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography variant="body2">Pokazatelj kreditne sposobnosti &ge; 1</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.creditworthiness * 1000 + Number.EPSILON) / 1000 || '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <DividerStyle />

                    <Typography ml={1} mb={3} variant="h6" color="primary">
                      Podaci o zalogu pokretne imovine
                    </Typography>
                    <Input label="Opis" name="description" multiline />
                    <Input label="Vrijednost" name="value" type="number" />
                    <Grid container mb={3}>
                      <Grid item xs={10}>
                        <Typography variant="body2">Pokazatelj pokrivenosti rate {'>'} 1.5</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <BotText variant="subtitle1">
                          {Math.round(comitentAnalysis.data.rateCoverageIndicator * 1000 + Number.EPSILON) / 1000 ||
                            '0'}
                        </BotText>
                      </Grid>
                    </Grid>
                    <Button onClick={() => handleSubmit} variant="contained" color="primary" type="submit">
                      Spremi promjene
                    </Button>
                    <Box height="16px"></Box>
                  </Form>
                )}
              </TabPanel>

              {suduznici.data.data &&
                suduznici.data.data.map((suduznik, i) => {
                  let a;
                  analysis.data.map((analiza, j) => {
                    if (analiza.komitent == suduznik.suduznik) {
                      a = analiza;
                    }
                  });
                  if (suduznik.clientType == 'F') {
                    return (
                      <TabPanel value={(i + 2).toString()}>
                        <AnalysisCodebtorPersonal
                          analysis={a}
                          suduznik={suduznik}
                          brojZahtjeva={brojZahtjeva}
                          next={next}
                          mutateEdit={mutateEditSuduznik}
                          mutateAdd={mutateAdd}
                          mutateDelete={mutateDelete}
                          setDeleteOpen={setDeleteOpen}
                        ></AnalysisCodebtorPersonal>
                      </TabPanel>
                    );
                  } else {
                    return (
                      <TabPanel value={(i + 2).toString()}>
                        <AnalysisCodebtorLegal
                          analysis={a}
                          suduznik={suduznik}
                          brojZahtjeva={brojZahtjeva}
                          next={next}
                          mutateEdit={mutateEditSuduznik}
                          mutateAdd={mutateAdd}
                        ></AnalysisCodebtorLegal>
                      </TabPanel>
                    );
                  }
                })}
              <ActionsWrapper>
                <Box
                  sx={{ backgroundColor: 'white' }}
                  pt={6}
                  pb={6}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  width={'100%'}
                  pl={6}
                  pr={3}
                >
                  {!!previous && (
                    <Box flexGrow={1} mr={2}>
                      <ArrowButton
                        variant="contained"
                        color="inherit"
                        onClick={previous}
                        disableElevation
                        disableRipple
                      >
                        <Box display="flex" alignItems="center">
                          <IoIosArrowBack size={22} />
                        </Box>
                      </ArrowButton>
                    </Box>
                  )}
                </Box>
              </ActionsWrapper>
            </TabContext>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
              <DialogTitle>{'Brisanje analize'}</DialogTitle>
              <DialogContent>
                <DialogContentText>Obrisati odabranu analizu?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteAnalysisF}>Da</Button>
                <Button onClick={() => setDeleteOpen(false)} autoFocus>
                  Ne
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
    </Box>
  );
};
const ActionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
`;
const ArrowButton = styled(Button)`
  ${({ theme }) => `
  min-width: 40px;
  height: 44px;
  background-color: white;
  border: 0.5px solid ${theme.palette.primary.main};
  color:${theme.palette.primary.main};
  `}
`;
const DividerStyle = styled(Divider)`
  ${({ theme }) => `
  margin-bottom:12px;
  background-color:${theme.palette.darkGray};
`}
`;
const BotText = styled(Typography)`
  margin-bottom: 12px;
`;
export default AnalysisLider;
