import React from 'react';
import { useQuery } from 'react-query';
import { getKreditniSluzbenici } from '../api';

const EmployeesList = () => {
  const { data } = useQuery('empoloyeesList', getKreditniSluzbenici);
  console.log('data---', data);

  return <>Radnicii</>;
};

export default EmployeesList;
