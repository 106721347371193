import React from 'react';
import { AiFillBank, AiOutlineUser } from 'react-icons/ai';
import { Avatar, Box, Chip, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const CRKMikraDetails = ({ user, crkdata, registry }) => {
  const theme = useTheme();

  const getZaduzenjeZirant = (dataRow) => {
    for (let i = 0; i < registry.zaduzenjeZirant.length; i++) {
      if (registry.zaduzenjeZirant[i].value == dataRow.zaduzenjeZirant) return registry.zaduzenjeZirant[i].text;
    }
    return null;
  };
  const getPodtipZaduzenja = (dataRow) => {
    for (let i = 0; i < registry.podtipZaduzenja.length; i++) {
      if (registry.podtipZaduzenja[i].value == dataRow.podtipZaduzenja) return registry.podtipZaduzenja[i].text;
    }
    return null;
  };
  const getTipInstitucije = (dataRow) => {
    for (let i = 0; i < registry.tipInstitucije.length; i++) {
      if (registry.tipInstitucije[i].value == dataRow) return registry.tipInstitucije[i].text;
    }
    return null;
  };
  const renderAvatar = (dataRow) => {
    if (dataRow.vrstaKomitenta == 'F' && dataRow.spol == 'Z')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.primary.main} />
        </AvatarOutline>
      );
    if (dataRow.vrstaKomitenta == 'F')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.secondary.main} />
        </AvatarOutline>
      );
    return (
      <AvatarOutline variant="rounded">
        <AiFillBank size={32} color={theme.palette.text.placeholder} />
      </AvatarOutline>
    );
  };
  const renderDelikvent = (dataRow) => {
    if (dataRow.delikvent == 'D' || dataRow.delikvent == 1) return <AvatarDelikvent>D</AvatarDelikvent>;
  };
  return (
    <Container>
      <Box ml={2}>
        <ListItem>
          <ListItemAvatar>
            <div>
              {renderAvatar(user)}
              {renderDelikvent(user)}
            </div>
          </ListItemAvatar>
          <ListItemText
            primary={`${user.ime} ${user.otac == null ? '' : `(${user.otac})`} ${
              user.prezime == null ? '' : user.prezime
            }`}
            secondary={`Datum provjere: ${new Date(crkdata.datumProvjere).toLocaleDateString('de-DE')}`}
            primaryTypographyProps={{ variant: 'subtitle1' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        {crkdata.zaduzenjeZirant && (
          <StyledChip size="small" label={getZaduzenjeZirant(crkdata)} color="primary"></StyledChip>
        )}
        {crkdata.podtipZaduzenja && (
          <StyledChip size="small" label={getPodtipZaduzenja(crkdata)} color="secondary"></StyledChip>
        )}
      </Box>
      <Box display="flex" flexDirection="column" m={2} mt={4} mb={0}>
        <Typography variant="body2">Naziv institucije</Typography>
        <BotText variant="subtitle1">{crkdata.sifraInstitucije || '-'}</BotText>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box width="100%" display="flex" flexDirection="column" m={2} ml={2} mt={0} bgcolor="background.paper">
          <Typography variant="body2">JMBG</Typography>
          <BotText variant="subtitle1">{user.komitent}</BotText>
          <Typography variant="body2">BP1/BP2</Typography>
          <BotText variant="subtitle1">{'BP' + crkdata.tipIzvjestaja}</BotText>
          <Typography variant="body2">Kraj otplate</Typography>
          <BotText variant="subtitle1">{new Date(crkdata.krajOtplate).toLocaleDateString('de-DE')}</BotText>
          <Typography variant="body2">Ostatak duga</Typography>
          <BotText variant="subtitle1" color={theme.palette.darkGray}>
            {Math.round(crkdata.ostatakDuga * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Neiskorišteni limit</Typography>
          <BotText variant="subtitle1">
            {Math.round(crkdata.neiskoristeniLimit * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Glavnica u dugu</Typography>
          <BotText variant="subtitle1">{Math.round(crkdata.glavnica * 100 + Number.EPSILON) / 100 || '-'}</BotText>
          <Typography variant="body2">Tekuća klasifikacija</Typography>
          <BotText variant="subtitle1">{crkdata.tekucaKlasifikacija || '-'}</BotText>
          <Typography variant="body2">Datum promjene klasifikacije</Typography>
          <BotText variant="subtitle1">
            {new Date(crkdata.datumPromjeneKlasifikacije).toLocaleDateString('de-DE')}
          </BotText>

          <Typography variant="body2">Tip institucije</Typography>
          <BotText variant="subtitle1">{getTipInstitucije(crkdata.tipInstitucije) || '-'}</BotText>
          <Typography variant="body2">Vrsta garancije</Typography>
          <BotText variant="subtitle1">{crkdata.vrstaGarancije || '-'}</BotText>
          <Typography variant="body2">Pokrivenost akreditiva</Typography>
          <BotText variant="subtitle1">{crkdata.pokrivenostAkreditiva || '-'}</BotText>
          <Typography variant="body2">Datum aktivacije</Typography>
          <BotText variant="subtitle1">{new Date(crkdata.datumAktivacije).toLocaleDateString('de-DE')}</BotText>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" m={2} ml={12} mt={0} bgcolor="background.paper">
          <Typography variant="body2">Adresa</Typography>
          <BotText variant="subtitle1">{user.adresa}</BotText>
          <Typography variant="body2">Datum odobrenja</Typography>
          <BotText variant="subtitle1">{new Date(crkdata.datumOdobrenja).toLocaleDateString('de-DE')}</BotText>
          <Typography variant="body2">Iznos</Typography>
          <BotText variant="subtitle1" color={theme.palette.primary.main}>
            {Math.round(crkdata.iznos * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Dozvoljeni limit</Typography>
          <BotText variant="subtitle1">
            {Math.round(crkdata.dozvoljeniLimit * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Iznos rate</Typography>
          <BotText variant="subtitle1">{Math.round(crkdata.iznosRate * 100 + Number.EPSILON) / 100 || '-'}</BotText>
          <Typography variant="body2">Kamata u dugu</Typography>
          <BotText variant="subtitle1" color={theme.palette.darkGray}>
            {Math.round(crkdata.kamataDug * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Prethodna klasifikacija</Typography>
          <BotText variant="subtitle1">{crkdata.prethodnaKlasifikacija || '-'}</BotText>
          <Typography variant="body2">Žirant vraća kredit</Typography>
          <BotText variant="subtitle1">{crkdata.zirantVracaKredit || '-'}</BotText>
          <Typography variant="body2">Iznos garancije</Typography>
          <BotText variant="subtitle1">
            {Math.round(crkdata.iznosGarancije * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
          <Typography variant="body2">Razlog gašenja</Typography>
          <BotText variant="subtitle1">{crkdata.razlogGasenja || '-'}</BotText>
          <Typography variant="body2">Iznos akreditiva</Typography>
          <BotText variant="subtitle1">
            {Math.round(crkdata.iznosAkreditiva * 100 + Number.EPSILON) / 100 || '-'}
          </BotText>
        </Box>
      </Box>
    </Container>
  );
};
const Container = styled('div')`
  max-width: 500px;
  margin: auto;
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.lightGray};
    float:left;
    height:50px;
    width:50px;
    border-radius:8px;
    margin-right:10px;
    margin-top:7px;
    `}
`;
const AvatarDelikvent = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.primary.main};
    position:relative;
    float:left;
    margin-left:-30px;
    top:35px;
    height:22px;
    width:22px;
    z-index:2;
    font-size:1rem;
    `}
`;
const BotText = styled(Typography)`
  margin-bottom: 15px;
`;

const StyledChip = styled(Chip)`
  margin-right: 4px;
  margin-top: -16px;
  font-size: 0.75rem;
  height: 16px;
`;

export default CRKMikraDetails;
