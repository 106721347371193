
const getCoords = () => {
  return new Promise((resolve, reject) => {
    // Check if Permission API is implemented
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' })
        .then((permission) => {
          // Check if geolocation is granted or prompt
          if (permission.state === 'granted' || permission.state === 'prompt') {
            // Get the current position if available
            navigator.geolocation.getCurrentPosition((pos) => {
              // Resolve with the coordinates
              resolve(pos.coords);
            }, (error) => {
              // Handle errors with the geolocation API
              reject(new Error(`Geolocation error: ${error.message}`));
            }, {
              // Add a timeout in case the geolocation API takes too long to respond
              timeout: 10000,
              // best possible results (this can result in slower response times or increased power consumption)
              enableHighAccuracy: true,

            });
          } else {
            // Geolocation permission was not granted or prompt
            resolve(null);
          }
        })
        .catch((error) => {
          // Handle errors with the Permission API
          reject(new Error(`Permission error: ${error.message}`));
        });
    } else {
      // Permission API is not implemented
      resolve(null);
    }
  });
};

export default getCoords;

/*const getCoords = () => {
  return new Promise((resolve, reject) =>
    navigator.permissions
      ? // Permission API is implemented
        navigator.permissions
          .query({
            name: 'geolocation',
          })
          .then((permission) =>
            // is geolocation granted?
            (permission.state === 'granted' || permission.state === 'prompt') && navigator.geolocation
              ? navigator.geolocation.getCurrentPosition((pos) => resolve(pos.coords))
              : resolve(null)
          )
          .catch(() => resolve(null))
      : // Permission API was not implemented
        resolve(null)
  );
}; */


