import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Form, Input, Select } from '@components/rhf/src/MUI5';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import {
  getApplication,
  getRazlogOdbijanja,
  getStatusOdbijenRazlozi,
  setOdobrioKOdbor1,
  statusOdbijen,
  statusOdbijenRazlog,
  statusOdobren,
} from '../api';
import { ActionsWrapper, CheckboxWrapper, DocumentButton } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import ApplicationApproved from '../images/ApplicationApprovedCheck.svg';
import ApplicationDenied from '../images/ApplicationDenied.svg';
import documents2 from '../images/documents2.svg';
import documents4 from '../images/documents4.svg';
import { getFirstLine } from '../utils';

const ApplicationAproved = ({ brojZahtjeva, previous, next, disabled, invalidate, kompletiran }) => {
  const odlukaBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_prod_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/zahtjevi/odluka_odbijen.rdf';
  const odlukaLink = `${odlukaBaseLink}&p_brojzahtjeva=${brojZahtjeva}`;

  const obavijestBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_prod_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/zahtjevi/obavijest_odbijen.rdf';
  const obavijestLink = `${obavijestBaseLink}&p_brojzahtjeva=${brojZahtjeva}`;

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [openApproved, setOpenApproved] = useState(false);
  const [openDenied, setOpenDenied] = useState(false);
  const [approved, setApproved] = useState(false);
  const [denied, setDenied] = useState(false);
  const { officerUsername } = useOfficerProfile();


  const methods = useForm({
    mode: 'onBlur',
  });
  const control = useForm({
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;
  const theme = useTheme();

  const applicationDeniedReasons = useQuery('reasons', getStatusOdbijenRazlozi);

  const deniedReason = useQuery(['getRazlogOdbijanja', brojZahtjeva], () => getRazlogOdbijanja(brojZahtjeva), {
    enabled: !!brojZahtjeva,
    onSuccess: (res) => {
      control.reset(res);
    },
  });

  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      setApproved(res.status == '1' || res.status == '6');
      setDenied(res.status == '2');
    },
  });

  const { mutate: mutateStatusOdobren } = useMutation(statusOdobren, {
    onSuccess: (res) => {
      setOpenApproved(false);
      enqueueSnackbar('Uspješno ste odobrili kreditni zahtjev.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpenApproved(false);
    },
  });
  const { mutate: mutateStatusOdbijen } = useMutation(statusOdbijen, {
    onSuccess: (res) => {
      setOpenDenied(false);
      invalidate();
      queryClient.invalidateQueries(['loanApplication', brojZahtjeva]);

      enqueueSnackbar('Zahtjev je prebačen u status Odbijen.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpenDenied(false);
    },
  });
  const { mutate: mutateStatusOdbijenRazlog } = useMutation(statusOdbijenRazlog, {
    onSuccess: (res) => {
      let values = { brojZahtjeva: brojZahtjeva, modifiedBy: officerUsername };
      mutateStatusOdbijen(values);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpenDenied(false);
    },
  });
  const { mutateAsync: mutateOdobrioKOdbor1 } = useMutation(setOdobrioKOdbor1, {
    onSuccess: (res) => {
      setOpenApproved(true);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    values.applicationAproved = Number(approved);
    values.modifiedBy = officerUsername;

    if (denied) {
      if (!control.getValues().reason)
        enqueueSnackbar('Molimo unesite razlog odbijanja zahtjeva.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      else {
        setOpenDenied(true);
      }
    } else if (approved) return mutateOdobrioKOdbor1(values);
  };
  const statusOdobrenSubmit = () => {
    const values = { brojZahtjeva: brojZahtjeva, modifiedBy: officerUsername };
    mutateStatusOdobren(values);
  };
  const statusOdbijenSubmit = () => {
    const values = { brojZahtjeva: brojZahtjeva };
    values.modifiedBy = officerUsername;
    values.reason = control.getValues().reason;
    values.description = control.getValues().description;
    setOpenDenied(false);
    mutateStatusOdbijenRazlog(values);
  };
  const handleChangeDenied = (event) => {
    if (event.target.checked) setApproved(false);
    setDenied(event.target.checked);
  };
  const handleChangeApproved = (event) => {
    if (event.target.checked) setDenied(false);
    setApproved(event.target.checked);
  };
  return (
    <Box>
      <Box display="flex" width="100%" justifyContent="center" mb={6}>
        <Box flexGrow={1}>
          <Checkbox
            icon={<CheckboxWrapper image={ApplicationDenied} label="Zahtjev odbijen" />}
            checkedIcon={
              <CheckboxWrapper color={theme.palette.secondary.main} image={ApplicationDenied} label="Zahtjev odbijen" />
            }
            onChange={handleChangeDenied}
            checked={denied}
            disabled={disabled}
          />
        </Box>
        <Box flexGrow={1}>
          <Checkbox
            icon={<CheckboxWrapper image={ApplicationApproved} label="Zahtjev odobren" />}
            checkedIcon={
              <CheckboxWrapper
                color={theme.palette.secondary.main}
                image={ApplicationApproved}
                label="Zahtjev odobren"
              />
            }
            onChange={handleChangeApproved}
            checked={approved}
            disabled={disabled}
          />
        </Box>
      </Box>
      {denied && !deniedReason.isLoading && applicationDeniedReasons.data && (
        <Form spacing={4} methods={control} onSubmit={handleSubmit} inputProps={{ disabled: disabled }}>
          <Select label="Razlog odbijanja" name="reason" options={applicationDeniedReasons.data || []} md={12} />
          <Input label="Opis" name="description" />
        </Form>
      )}
      {denied && disabled && (
        <Box mt={6} mb={10}>
          <DocumentButton
            link={obavijestLink}
            name={`Obavijest-${brojZahtjeva}`}
            label="Obavijest o odbijanju"
            icon={documents4}
          />
          <DocumentButton
            link={odlukaLink}
            name={`Odluka-${brojZahtjeva}`}
            label="Odluka o odbijanju"
            icon={documents2}
          />
        </Box>
      )}
      <Form spacing={4} methods={methods} onSubmit={disabled ? next : handleSubmit}>
        <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
      </Form>
      <Dialog
        open={openDenied}
        onClose={() => setOpenDenied(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Prelazak u status odbijen?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Prelazak u status odbijen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={statusOdbijenSubmit}>Da</Button>
          <Button onClick={() => setOpenDenied(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openApproved}
        onClose={() => setOpenApproved(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Prelazak u status odobren?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Prelazak u status odobren?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={statusOdobrenSubmit}>Da</Button>
          <Button onClick={() => setOpenApproved(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApplicationAproved;
