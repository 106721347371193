import React from 'react';
import GoogleMapReact from 'google-map-react';
import Mark from './Marker';

const Map = ({ data, style = { height: `100vh`, width: '100%' }, zoom = 7 }) => {
  let center;
  data.every(({ lng, lat }) => {
    if (lng) {
      center = { lng: Number(lng), lat: Number(lat) };
      return false;
    }
    return true;
  });

  const renderGoogleAPI = (map, maps) => {
    //postavi linije
  };

  return (
    // Important! Always set the container height explicitly
    <div key="Map" style={style}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyD4332I7y6gSl_g59J9ZUqoQT_UQR9sIEE' }}
        defaultCenter={
          center || {
            lat: 44.198512899418546,
            lng: 17.91524040501319,
          }
        }
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderGoogleAPI(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        {data.map((item) => {
          return (
            <Mark
              lat={item.lat}
              lng={item.lng}
              item={item}
              key={'k' + item?.id}
              color={item?.color}
              type={item?.type}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
