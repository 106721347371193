import { erpAxios } from '..';

const addDokument = async (data) => {
  const res = await erpAxios.post(`/dokumenti/upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export default addDokument;
