import React, { useEffect, useState } from 'react';
import { AiFillBank, AiOutlineUser } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, List, ListItem, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getClientAnswers } from '../api';
import { ClientDetailsLider, Spinner } from '../components';
import AnswersListItem from '../components/Survey/AnswersListItem';
import Layout from '../layout';
import { scrollToTop } from '../utils';
import NotFound from './404';

const ClientLider = () => {
  const location = useLocation();
  const theme = useTheme();
  const [toggle, setToggle] = useState(false);

  let client;
  useEffect(() => setToggle(true), [client]);

  const { isLoading, data, isFetching } = useQuery('getClientAnswers', () => getClientAnswers(client.komitent), {
    enabled: toggle,
  });

  if (location.state) client = location.state.client;
  else return <NotFound />;

  let avatarColor;
  let AvatarIcon;

  if (client.vrstaKomitenta === 'F' && client.spol === 'M') {
    avatarColor = theme.palette.secondary.main;
    AvatarIcon = AiOutlineUser;
  } else if (client.vrstaKomitenta === 'F') {
    avatarColor = theme.palette.primary.main;
    AvatarIcon = AiOutlineUser;
  } else {
    avatarColor = theme.palette.darkGray;
    AvatarIcon = AiFillBank;
  }

  return (
    <Layout
      topBarProps={{
        backLink: '/',
        forwardLink: { pathname: '/client/edit', state: { client } },
        text: 'Detalji o komitentu',
      }}
    >
      <Box display="flex" justifyContent="center">
        <AvatarOutline>
          <Box display="flex" justifyContent="center">
            <AvatarIcon size={32} color={avatarColor} />
          </Box>
        </AvatarOutline>
      </Box>
      <ClientDetailsLider client={client} />
      <Box display="flex" flexDirection="row" justifyContent="center" mb={8}>
        <Button
          variant="contained"
          color="primary"
          onClick={scrollToTop}
          component={Link}
          to={{ pathname: '/loanapplication', state: { client } }}
        >
          Zahtjev za kredit
        </Button>
        <Box width={16} />
        <Button
          variant="contained"
          color="secondary"
          onClick={scrollToTop}
          component={Link}
          to={{ pathname: '/survey', state: { client } }}
        >
          Prodajni razgovor
        </Button>
      </Box>
      <LottieContainer>
        <Box m={2}>
          <Typography variant="h5">Ankete</Typography>
        </Box>
        {isLoading && isFetching && <Spinner />}
        {!isLoading && !isFetching && !!client && (
          <Box textAlign="center">
            {data?.length === 0 ? (
              <EmptyList mt={5} p={5}>
                <Typography variant="body2" align="left" gutterBottom>
                  Trenutno ne postoji niti jedna popunjena anketa. Klikom na prodajni razgovor otvarate kreirane ankete
                  koje možete popuniti.
                </Typography>
              </EmptyList>
            ) : (
              <List>
                {data?.map((answer, i) => (
                  <ListItem
                    key={i}
                    alignItems="flex-start"
                    component={Link}
                    to={{ pathname: `/answers/${answer?._id}`, state: { client, back: 'client' } }}
                  >
                    <AnswersListItem
                      props={answer}
                      bgcolor={theme.palette.background.survey}
                      user={true}
                      avatar={theme.palette.background.block}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        )}
      </LottieContainer>
    </Layout>
  );
};

const LottieContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > div {
    margin: auto;
    min-width: 38vw;
    height: 100%;
    width: 100%;
  }
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  height: 72px;
  width: 72px;
  background-color:${theme.palette.lightGray};
  `}
`;
const EmptyList = styled(Box)`
  ${({ theme }) => `
  border-left: 6px solid ${theme.palette.secondary.main};
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  `}
`;

export default ClientLider;
