import React from 'react';
import {
  styled,
  Fab,
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
} from '@mui/material';

const InputRadio = ({ props, position }) => {
  return (
    <FormControl fullWidth>
      <Box mb={5}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={1.5} md={1}>
            <QuestionNumber size="small">{position}</QuestionNumber>
          </Grid>
          <Grid item xs={10.5} md={11}>
            <Typography variant="body1">{props.name}</Typography>
          </Grid>
        </Grid>
      </Box>
      <RadioGroup>
        {props.choices.map((odgovor, index) => (
          <FormControlLabel
            key={index}
            value={index}
            control={<Radio size="small" disabled />}
            label={
              odgovor.other ? (
                <OtherField label={odgovor.text} disabled={true} size="small" variant="standard" />
              ) : (
                odgovor.text
              )
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const QuestionNumber = styled(Fab)`
  ${({ theme }) => `
  background-color: #7B78FE;
  color: white;
  min-height:0;
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px;
  `}
`;

const OtherField = styled(TextField)`
  margin-top: -18px !important;
`;

export default InputRadio;
