import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, RadioButton } from '@components/rhf/src/MUI5';
import { Box } from '@mui/material';
import { addProcjenaRizika, editProcjenaRizika, getProcjenaRizika, getStepenRizika } from '../api';
import { ActionsWrapper } from '../components';
import { getFirstLine } from '../utils';
import { ServerError, Spinner } from '.';

const RiskAssessment = ({ brojZahtjeva, previous, next, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: mutateAddAssessment } = useMutation(addProcjenaRizika, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno unesena procjena rizika.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateEditAssessment } = useMutation(editProcjenaRizika, {
    onSuccess: () => {
      enqueueSnackbar('Spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const methods = useForm({
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const assessment = useQuery(['getProcjenaRizika', brojZahtjeva], () => getProcjenaRizika(brojZahtjeva), {
    onSuccess: (res) => {
      methods.setValue('risk', res.procjenaRizicnosti);
    },
  });
  const stepenRizika = useQuery('stepenRizika', getStepenRizika);
  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    if (values.risk != assessment.data.procjenaRizicnosti) {
      if (!assessment.data) {
        return mutateAddAssessment(values);
      } else return mutateEditAssessment(values);
    }
    next();
  };
  return (
    <Box>
      {(stepenRizika.error || assessment.error) && <ServerError error={stepenRizika.error || assessment.error} />}
      {(stepenRizika.isLoading || assessment.isLoading) && <Spinner />}
      {stepenRizika.data && !assessment.error && !assessment.isLoading && (
        <Form
          spacing={4}
          methods={methods}
          onSubmit={disabled ? next : handleSubmit}
          inputProps={{ disabled: disabled }}
        >
          <RadioButton
            title="Vaša procjena rizika"
            name="risk"
            control={methods}
            options={stepenRizika.data}
          ></RadioButton>
          <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
        </Form>
      )}
    </Box>
  );
};

export default RiskAssessment;
