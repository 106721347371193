const dateFormat = (date) => {
  if (date) {
    let dateAdapted = new Date(date).toLocaleDateString('default', {
      month: 'short',
    });
    if (dateAdapted) {
      dateAdapted = ('0' + date.getDate()).slice(-2) + '-' + dateAdapted + '-' + (date.getFullYear() % 100);
      return dateAdapted;
    }
  } else return null;
};

export default dateFormat;
