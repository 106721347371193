const MuiFormHelperText = {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      position: 'absolute',
      marginTop: '50px',
    },
    contained: {
      marginTop: '59px',
    },
  },
};

export default MuiFormHelperText;
