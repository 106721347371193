import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Input, PhoneInput, Select } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { loanOfficerSchemaCodebtor } from '@partner/validation';
import { DatePicker } from '.';

const CodebtorsClientCreate = ({ handleSubmitKomitent, datumIsteka, setDatumIsteka, registry }) => {
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(loanOfficerSchemaCodebtor),
    mode: 'onBlur',
  });
  const theme = useTheme();

  return (
    <Box p={6} mb={6} ml={-5} mr={-5} sx={{ backgroundColor: theme.palette.lightGray }}>
      <Form spacing={4} methods={methods} onSubmit={handleSubmitKomitent} inputProps={{}}>
        <Input fullWidth label="JMBG / JIB" name="jmbg" type="number" />
        <Input fullWidth label="Ime" name="firstName" />
        <Input fullWidth label="Prezime" name="lastName" />
        <Input fullWidth label="Broj LK" name="lk" />
        <DatePicker datum={datumIsteka} setDatum={setDatumIsteka} label="Datum isteka važenja lične karte" />
        <Input label="Adresa" name="address" md={6} />
        <Select label="Grad" name="municipality" options={registry ? registry.data.municipalities : []} md={6} />
        <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
        <AddButton variant="contained" type="submit">
          Novi komitent
        </AddButton>
      </Form>
    </Box>
  );
};
const AddButton = styled(Button)`
  ${({ theme }) => `
  background-color:white;
  color:${theme.palette.secondary.main};
  border: 0.5px solid ${theme.palette.secondary.main};
  `}
`;
export default CodebtorsClientCreate;
