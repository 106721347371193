import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getApplications, getStatus } from '../api';
import { ApplicationAvatar, ServerError } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import LoanApplicationsHeader from '../images/LoanApplicationsHeader.png';
import MainLayout from '../layout/MainLayout';
import NavBar from '../layout/NavBar';
import { dateFormatReverse } from '../utils';

const LoanApplications = () => {
  const { officerSifra: id } = useOfficerProfile();
  const status = useQuery('status', getStatus);
  /*const crk = useQuery(['crkSluzbenici', id], () => getOfficerCRK(id), {
    enabled: !!id,
  });*/
  const applications = useQuery(['getApplications', id], () => getApplications(id), {
    enabled: !!id,
  });
  const theme = useTheme();

  let currentTime = new Date();
  let pastTime = new Date(new Date().setDate(currentTime.getDate() - 90));
  let dateLabel = `${pastTime.getDate()}.${
    pastTime.getMonth() + 1
  }.${pastTime.getFullYear()}-${currentTime.getDate()}.${currentTime.getMonth() + 1}.${currentTime.getFullYear()}`;

  const getStatusName = (sifra) => {
    for (let i = 0; i < status.data.length; i++) {
      if (sifra == status.data[i].value) return status.data[i].text;
    }
    return null;
  };
  /*const renderCrkChip = (dataRow) => {
    let crkStatus = false;
    for (let i = 0; i < crk.data.length; i++) {
      if (crk.data[i].brojzahtjeva == dataRow.brojzahtjeva) crkStatus = true;
    }
    if (crkStatus) {
      return (
        <StyledChip
          sx={{ width: '56px' }}
          backchipcolor={theme.palette.success.main}
          chipcolor={theme.palette.success.contrastText}
          label="CRK"
        ></StyledChip>
      );
    }
  };*/
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };
  return (
    <MainLayout
      topBarProps={{
        backLink: '/',
        text: 'Lista zahtjeva',
      }}
    >
      {(applications.error || status.error) && <ServerError error={applications.error || status.error} />}

      <Box mb={7}>
        <BoxHeader pt={3} pb={3} pl={8} display="flex" flexDirection="column" mt={4}>
          <Typography variant="caption" color="white">
            {dateLabel}
          </Typography>
          <Box mt={2} mb={2}>
            <Typography variant="h6" color="white" mr={1}>
              Zahtjevi
            </Typography>
          </Box>
          <Typography variant="body2" color="white">
            {!!applications.data ? applications.data.length + ' zahtjeva' : ''}
          </Typography>
          <Box
            position="absolute"
            height="87px"
            width="86%"
            sx={{
              backgroundImage: `url(${LoanApplicationsHeader})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </BoxHeader>
        {!!applications.data && !!status.data && (
          <List sx={{ width: '100%' }}>
            {applications.data.map((application) => {
              return (
                <Box key={application.brojzahtjeva}>
                  <ListItem
                    key={application.brojzahtjeva}
                    button
                    alignItems="flex-start"
                    component={Link}
                    to={{ pathname: '/loanapplication', state: { application } }}
                    onClick={scrollTop}
                  >
                    <Box mb={2} display="flex" width="100%" overflow="hidden">
                      <Box>
                        <ListItemAvatar>
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <ApplicationAvatar status={application.status} />
                          </Box>
                        </ListItemAvatar>
                      </Box>
                      <Box mt={1} flexGrow={1} pl={2} pr={2}>
                        <ListItemText
                          primary={`${application.ime} ${application.prezime}`}
                          secondary={
                            <Box mt={2} display="flex" flex-direction="row">
                              <Typography variant="body2">
                                {application.datumzahtjeva ? dateFormatReverse(application.datumzahtjeva) : '-'}
                              </Typography>
                              <Typography variant="body2">|</Typography>
                              <Typography variant="body2">
                                {application.brojrata || '-'}{' '}
                                {application.brojrata && application.brojrata > 1 && application.brojrata < 5
                                  ? 'rate'
                                  : 'rata'}
                              </Typography>
                            </Box>
                          }
                          primaryTypographyProps={{ variant: 'subtitle1' }}
                          secondaryTypographyProps={{ variant: 'caption', component: 'span' }}
                        />
                      </Box>
                      <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minWidth="75px"
                        flexDirection="column"
                      >
                        <ListItemText
                          primary={`${application.iznos} KM`}
                          //secondary={renderCrkChip(application)}
                          primaryTypographyProps={{ variant: 'subtitle1', align: 'right' }}
                          //secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
                        />
                        <StyledChip
                          backchipcolor={theme.palette.lightGray}
                          label={getStatusName(application.status)}
                        ></StyledChip>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </Box>
              );
            })}
          </List>
        )}
      </Box>

      {(applications.isLoading || status.isLoading) && (
        <Box>
          {Array(10)
            .fill()
            .map(() => {
              return (
                <Skeleton variant="rectangular" height={40} sx={{ mb: '38px', mt: '38px', ml: '12px', mr: '12px' }} />
              );
            })}
        </Box>
      )}

      <NavBar />
    </MainLayout>
  );
};
const StyledChip = styled(Chip)`
  font-size: 0.75rem;
  margin-bottom: 6px;
  height: 16px;
  width: 80px;
  border-radius: 3px;
  background-color: ${({ backchipcolor }) => backchipcolor || 'initial'};
  color: ${({ chipcolor }) => chipcolor || 'initial'};
`;
const BoxHeader = styled(Box)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main}99;
  border-radius: 8px;
  min-height:73px;
`}
`;

export default LoanApplications;
