import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useKeycloak } from '@react-keycloak/web';
import Map from '../components/Map';
import ServerError from '../components/ServerError';
import Spinner from '../components/Spinner';
import { getDailyEventsByUser, getMapAnswers } from '../api';
import MainLayout from '../layout/MainLayout';
import EmployeeSearchBar from '../components/EmployeeSearchBar';
import useOfficerProfile from '../hooks/useOfficerProfile';

const MapPage = () => {
  const { keycloak } = useKeycloak();
  const { officerId } = useOfficerProfile();
  const isAdmin = keycloak.hasRealmRole('admin');
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (!isAdmin) setEmployee(officerId);
  }, [isAdmin, officerId]);
  let mapData = [];
  const { data, error, isLoading } = useQuery(['getDailyEventsByUser', employee], () => getDailyEventsByUser(employee));
  const {
    data: surveyData,
    error: surveyError,
    isLoading: surveyLoading,
  } = useQuery(['getMapAnswers', employee], () => getMapAnswers(employee));

  data?.forEach((item) => {
    mapData.push({ ...item, type: 'Novi zahtjev', color: '#008EED' });
  });
  surveyData?.forEach((item) => {
    mapData.push({ ...item, type: 'Anketa', color: '#D52941' });
  });

  if (isLoading || surveyLoading) return <Spinner />;

  if (error || surveyError) return <ServerError error={error} />;
  return (
    <MainLayout
      topBarProps={{
        backLink: '/',
        text: 'Mape događaja',
      }}
    >
      {isAdmin && <EmployeeSearchBar employeeSelect={setEmployee} employee={employee} />}
      <Map data={mapData} />
    </MainLayout>
  );
};

export default MapPage;
