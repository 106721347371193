import { erpAxios } from '.';

const Costs = async (data) => {
  const res = await erpAxios.post(`/costsAndDates/dates`, data);
  const res2 = await erpAxios.post(`/costsAndDates/proceduraPustanje`, data);
  return res;
};

export default Costs;


