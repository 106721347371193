import React, { useEffect } from 'react';
import useOfficerProfile from '../hooks/useOfficerProfile';
import getCoords  from '../utils/getCoords';
import {
    insertEvent,
  } from '../api';

  
  const LocationTracker = () => {

    const {officerUsername} = useOfficerProfile();
    

    useEffect(() => {
        const insertEventAndDelay = async () => {
          try {
              const coords = await getCoords();
              
                await insertEvent({
                lat: coords ? coords.latitude : null,
                lng: coords ? coords.longitude : null,
                alt: coords ? coords.altitude : null,       
                emp: officerUsername ? Number(officerUsername) :null,
                });
                console.log('Location saved successfully');
            
            setTimeout(insertEventAndDelay, 60000); // Wait 1 minute and call insertEventAndDelay again
          } catch (error) {
            console.error(error);
          }
        };
      
        insertEventAndDelay(); // Call insertEventAndDelay for the first time
      }, []);

      return null;

  };

  export default LocationTracker;