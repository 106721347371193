import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Checkbox,
  styled,
} from '@mui/material';
import { fetchSurvey, fillSurvey, getLoanInfo, getUserLoans } from '../api';
import Spinner from '../components/Spinner';
import SpecialListItem from '../components/Survey/SpecialListItem';
import { createYupSchema } from '../components/Survey/SurveyValidation';
import useOfficerProfile from '../hooks/useOfficerProfile';
import SurveyIcon from '../images/SurveyIcon.svg';
import Layout from '../layout';
import getCoords from '../utils/getCoords';

const Survey = () => {
  let { id } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const officer = useOfficerProfile();

  const [redirect, setRedirect] = useState(false);
  const [brojugovora, setBrojugovora] = useState('');
  const [loanhook, enableLoanhook] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const messageSuccess = 'Uspješno ste popunili anketu.';
  const messageError = 'Desila se greška prilikom popunjavanja ankete. Pokušajte ponovo.';

  let client;
  if (location.state) client = location.state.client;

  const { isLoading, error, data } = useQuery(['fetchSurvey', id], () => fetchSurvey(id));

  const {
    isLoading: isLoadingLoans,
    error: errorLoans,
    data: dataLoans,
  } = useQuery(['getUserLoans', client?.komitent], () => getUserLoans(client?.komitent, client), {
    enabled: !!client,
  });

  const { data: dataInfo } = useQuery(['getLoanInfo', brojugovora], () => getLoanInfo(brojugovora), {
    enabled: loanhook && brojugovora?.length > 1 && !!client,
  });

  const schema = data?.questions.reduce(createYupSchema, {});
  const validateSchema = yup.object().shape(schema);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validateSchema),
    mode: 'onBlur',
  });

  const onSubmit = (answers) => submitSurvey(answers);
  const submitSurvey = async (answers) => {
    setDisabled(true);
    await getCoords().then((coords) => {
      mutate({
        survey: data,
        answers: Object.keys(answers).map((k, index) => ({
          pitanje: k,
          odgovor: answers[k],
          type: data?.questions[index]?.type,
        })),
        client,
        officer,
        loan: !brojugovora.length ? 'Nije povezana sa kreditom.' : brojugovora,
        loanInfo: dataInfo,
        location: {
          type: 'CreateSurvey',
          coordinates: {
            lat: coords ? coords.latitude : null,
            lng: coords ? coords.longitude : null,
            alt: coords ? coords.altitude : null,
          },
        },
      });
      setDisabled(false);
    });
  };

  const { mutate } = useMutation(fillSurvey, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllAnswers');
      reset();
      enqueueSnackbar(messageSuccess, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRedirect(true);
    },
    onError: (_) => {
      enqueueSnackbar(messageError, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  useEffect(() => enableLoanhook(true), []);

  if (redirect) return <Redirect to={{ pathname: '/survey', state: { client } }} />;
  return (
    <Layout
      topBarProps={{
        backLink: { pathname: '/survey', state: { client } },
        text: 'Anketa',
      }}
    >
      <FormContainer pl={5} pr={5}>
        {(isLoading || isLoadingLoans) && <Spinner />}
        {!error && !isLoading && !errorLoans && !isLoadingLoans && (
          <Box p={5}>
            <Box mb={5}>
              <SpecialListItem data={data} image={SurveyIcon} background="#7B78FE" description={true} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {data?.loanDependency && data?.userDependency ? (
                <Box mb={5}>
                  <FormControl fullWidth>
                    <InputLabel>Odabir kredita </InputLabel>
                    <Select sx={{ bgcolor: 'white' }} onChange={(e) => setBrojugovora(e.target.value)} required>
                      {dataLoans?.length ? (
                        dataLoans.map((loan) => <MenuItem value={`${loan?.ZAHTJEV_ID}`}>{loan?.BROJUGOVORA}</MenuItem>)
                      ) : (
                        <MenuItem>Komitent nema aktivnih kredita</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <></>
              )}
              {data?.questions?.map((field, i) => (
                <FormQuestion pl={5} pr={5} pt={8} pb={8} mb={5} key={i}>
                  <FormControl fullWidth>
                    <Box mb={5}>
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item xs={1.5} md={1}>
                          <QuestionNumber size="small">{i + 1}</QuestionNumber>
                        </Grid>
                        <Grid item xs={10.5} md={11}>
                          <Typography variant="body1">{field.name}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormControl>
                  {field?.type === 'text' ? (
                    <TextField
                      fullWidth
                      label="Unesite odgovor"
                      multiline
                      rows={2}
                      variant="outlined"
                      {...register(field?.name)}
                    />
                  ) : (
                    <RadioGroup>
                      {field?.choices.map((odgovor, index) => (
                        <FormControlLabel
                          key={index}
                          value={odgovor?.text}
                          {...(watch(field.name) ? null : register(field.name))}
                          control={<Radio size="small" />}
                          onChange={(event) => setValue(field.name, event.target.value)}
                          label={
                            odgovor?.other ? (
                              <OtherField
                                label={odgovor?.text}
                                size="small"
                                variant="standard"
                                onChange={(event) => setValue(field.name, event.target.value)}
                              />
                            ) : (
                              odgovor.text
                            )
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}
                  <Box display="flex" alignItems="center" mt={4} mb={-2} ml={-2}>
                    <Checkbox
                      value="Neprimjenjivo"
                      label={<Typography variant="body1">"Neprimjenjivo"</Typography>}
                      onChange={(event) => {
                        if (event.target.checked) setValue(field.name, event.target.value);
                      }}
                      style={{
                        color: 'grey',
                      }}
                    />
                    <Typography variant="body1">Neprimjenjivo</Typography>
                  </Box>
                </FormQuestion>
              ))}
              <Button variant="contained" type="submit" color="secondary" disabled={disabled} fullWidth>
                Popuni anketu
              </Button>
            </form>
          </Box>
        )}
      </FormContainer>
    </Layout>
  );
};

const FormContainer = styled(Box)`
  ${({ theme }) => `
  min-width: 100%;
  height: 100%;
  margin-left: -6%;
  margin-right: -6%;
  background-color: ${theme.palette.background.survey};
`}
`;

const QuestionNumber = styled(Fab)`
  ${({ theme }) => `
  background-color: #7B78FE;
  color: white;
  min-height:0;
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px;
  `}
`;

const FormQuestion = styled(Box)`
  border-radius: 5px;
  background-color: white;
`;

const OtherField = styled(TextField)`
  margin-top: -18px !important;
`;

export default Survey;
