import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  styled,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Checkbox,
} from '@mui/material';
import { GrFormClose } from 'react-icons/all';

const SurveyForma = ({ updateSurvey }) => {
  const { register, handleSubmit, reset } = useForm();
  const messageSuccess = 'Uspješno ste dodali pitanje.';
  const { enqueueSnackbar } = useSnackbar();
  const [textinput, setTextinput] = useState(true);
  const [required, setRequired] = useState(true);
  const [inputList, setInputList] = useState([{ text: '', other: false, required: true }]);

  const onSubmit = (data) => {
    handleField(data);
    reset();
  };

  const handleSelectChange = (event) => {
    switch (event.target.value) {
      case 2:
        setTextinput(false);
        break;
      default:
        setTextinput(true);
        setInputList([{ text: '', other: false, required: required }]);
        break;
    }
  };

  const handleAddClick = (toggle) => setInputList([...inputList, { text: '', other: toggle, required: required }]);

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index].text = value;
    setInputList(list);
  };

  const handleField = (data) => {
    updateSurvey({
      name: data.naziv,
      type: textinput ? 'text' : 'radio',
      choices: textinput ? [] : inputList,
      required: required,
    });
    setInputList([{ text: '', other: false, required: required }]);
    setTextinput(true);
    enqueueSnackbar(messageSuccess, {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction={'column'} spacing={5}>
        <Grid item>
          <FormControl fullWidth>
            <TextField label="Naziv pitanja" size="small" {...register('naziv')} required />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl size="small" fullWidth>
            <InputLabel>Vrsta odgovora</InputLabel>
            <Select onChange={handleSelectChange} defaultValue="" required>
              <MenuItem value={1}>Tekst</MenuItem>
              <MenuItem value={2}>Odabir</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!textinput ? (
          <Grid item>
            <FormControl fullWidth>
              <Grid container direction={'column'} spacing={2}>
                {inputList.map((input, index) =>
                  index == 0 && index == inputList.length - 1 ? (
                    <Grid item>
                      <TextField
                        key={index}
                        size="small"
                        name="odgovor"
                        label="Naziv opcije"
                        value={input.text}
                        onChange={(e) => handleInputChange(e, index, false)}
                        required
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    <Grid item>
                      <TextField
                        size="small"
                        key={index}
                        name="odgovor"
                        label="Naziv opcije"
                        value={input.text}
                        onChange={(e) => handleInputChange(e, index, false)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment key={index} position="end">
                              <IconButton onClick={() => handleRemoveClick(index)}>
                                <RemoveIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                        fullWidth
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </FormControl>
            <Box mt={3}>
              <AddOtherOption display="inline" onClick={() => handleAddClick()}>
                Dodaj opciju
              </AddOtherOption>
              <AddOption display="inline"> ili</AddOption>
              <AddOtherOption display="inline" onClick={() => handleAddClick(true)}>
                {' '}
                dodaj ostalo
              </AddOtherOption>
            </Box>
          </Grid>
        ) : null}

        <Grid item>
          <Button variant="contained" type="submit">
            Potvrdi
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const AddOption = styled(Typography)`
  font-size: 13px;
`;

const AddOtherOption = styled(Typography)`
  ${({ theme }) => `
  font-size: 13px;
  text-decoration:none;
  color: ${theme.palette.primary.main};
  `}
`;

const RemoveIcon = styled(GrFormClose)`
  font-size: 20px;
  margin-right: -10px;
`;

export default SurveyForma;
