import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CRKMikraDetails from './components/CRKMikraDetails';
import selectClient from './hooks/selectClient';
import useLocationGrant from './hooks/useLocationGrant';
import NotFound from './pages/404';
import Analytics from './pages/Analytics';
import Answers from './pages/Answers';
import ClientCreateLider from './pages/ClientCreateLider';
import ClientCreateMikra from './pages/ClientCreateMikra';
import ClientCreatePartner from './pages/ClientCreatePartner';
import ClientEditLider from './pages/ClientEditLider';
import ClientEditMikra from './pages/ClientEditMikra';
import ClientEditPartner from './pages/ClientEditPartner';
import ClientLider from './pages/ClientLider';
import ClientMikra from './pages/ClientMikra';
import ClientPartner from './pages/ClientPartner';
import Clients from './pages/Clients';
import Employees from './pages/Employees';
import LoanAppParent from './pages/LoanAppParent';
import LoanApplications from './pages/LoanApplications';
import LoanApplicationsMikra from './pages/LoanApplicationsMikra';
import LocationNotFound from './pages/LocationNotFound';
import Map from './pages/Map';
import Notifications from './pages/Notifications';
import Survey from './pages/Survey';
import SurveyCreate from './pages/SurveyCreate';
import SurveyList from './pages/SurveyList';

const Routes = () => {
  const isLocationGranted = useLocationGrant();

  return (
    <Suspense fallback="Loading...">
      <Switch>
      {!isLocationGranted && (
          <Route path="*">
            <LocationNotFound />
          </Route>
        )}
        <Redirect exact from="/" to="/clients" />
        <Route exact path="/clients">
          <Clients />
        </Route>
        <Route exact path="/client/create">
          {selectClient() === 'Partner' && <ClientCreatePartner />}
          {selectClient() === 'Mikra' && <ClientCreateMikra />}
          {selectClient() === 'Lider' && <ClientCreateLider />}
        </Route>
        <Route exact path="/client">
          {selectClient() === 'Partner' && <ClientPartner />}
          {selectClient() === 'Mikra' && <ClientMikra />}
          {selectClient() === 'Lider' && <ClientLider />}
        </Route>
        <Route exact path="/client/edit">
          {selectClient() === 'Partner' && <ClientEditPartner />}
          {selectClient() === 'Mikra' && <ClientEditMikra />}
          {selectClient() === 'Lider' && <ClientEditLider />}
        </Route>
        {selectClient() === 'Mikra' && (
          <Route exact path="/crk/details">
            <CRKMikraDetails />
          </Route>
        )}
        {selectClient() === 'Partner' && (
          <Route exact path="/employees">
            <Employees />
          </Route>
        )}
        <Route exact path="/loanapplication">
          <LoanAppParent />
        </Route>
        <Route exact path="/loanapplications">
          {selectClient() === 'Partner' && <LoanApplications />}
          {selectClient() === 'Mikra' && <LoanApplicationsMikra />}
          {selectClient() === 'Lider' && <LoanApplications />}
        </Route>
        <Route exact path="/survey">
          <SurveyList />
        </Route>
        <Route exact path="/survey/create/">
          <SurveyCreate />
        </Route>

        <Route exact path="/survey/:id" children={<Survey />}></Route>
        <Route exact path="/answers/:id" children={<Answers />}></Route>
        <Route exact path="/survey/:id/analytics" children={<Analytics />}></Route>
        {selectClient() === 'Partner' && (
          <Route exact path="/notifications">
            <Notifications />
          </Route>
        )}
        {(selectClient() === 'Partner' || selectClient() === 'Lider') && (
          <Route exact path="/map">
            <Map />
          </Route>
        )}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
