const white = '#FFFFFF';
const black = '#000000';
const lightGray = '#F6F6F6';
const darkGray = '#808080';
const gray = '#D3D3D3';
const babyBlue = '#F1F7FE';

const palette = {
  black,
  white,
  lightGray,
  darkGray,
  gray,
  primary: {
    contrastText: white,
    main: '#417DCC',
  },
  secondary: {
    contrastText: white,
    main: '#4FCE91',
  },
  error: {
    contrastText: white,
    main: '#D52941',
  },
  warning: {
    contrastText: white,
    main: '#ECA72C',
  },
  info: {
    contrastText: white,
    main: '#008EED',
  },
  success: {
    contrastText: white,
    main: '#558811',
  },
  text: {
    primary: '#000000',
    secondary: '#3F3F3F',
    placeholder: '#5f6368',
    link: '#008EED',
    main: '#3F3F3F',
  },
  background: {
    default: '#ffffff',
    paper: white,
    block: '#f9fafb',
    survey: '#F4F6F8',
    secondary: babyBlue,
  },
  divider: '#DADCE0',
};

export default palette;
