import { Avatar, Box, Button, Divider, Input, ListItem, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transparentize } from 'polished';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiFillFileImage, AiFillFileText, AiOutlineClose } from 'react-icons/ai';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';
import selectClient from '../hooks/selectClient';
import folder from '../images/folder.png';

const Upload = ({ docTypes, handleChange, defaultFiles, disabled, disableDelete=true }) => {
  const [files, setFiles] = useState(defaultFiles);
  const [, filesChange] = useReducer((x) => x + 1, 0);
  const upload = useRef(null);

  const documentPlaceholder = { ID: -1, NAZIV: 'Odaberite tip dokumenta...' };
  let documentTypes = [...docTypes];
  documentTypes.sort(function (a, b) {
    if (a.NAZIV < b.NAZIV) return -1;
    if (a.NAZIV > b.NAZIV) return 1;
    return 0;
  });
  documentTypes.unshift(documentPlaceholder);

  const { getInputProps } = useDropzone({
    multiple: false,
    accept: [
      'image/*',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/docx',
      'application/pdf',
      'text/plain',
      'text/*',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxSize: 25 * 1024 * 1024,
    onDrop: async (fileArray, fileErrors) => {
      try {
        if (fileErrors.length > 0 && fileErrors[0].errors.length > 0) throw fileErrors[0].errors[0];

        const [file] = fileArray;
        file.preview = URL.createObjectURL(file);

        file.id = -1;
        //file.docType = 0;
        file.docType = -1;
        handleChange([file, ...files]); //u ovoj liniji upravljati fajlom
      } catch (error) {
        console.log(error);
      }
    },
  });
  const removeFile = (file) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i] === file) {
        files.splice(i, 1);
      }
    }
    if (file.preview) URL.revokeObjectURL(file.preview);
    handleChange(files);
    filesChange();
  };
  const selectChange = (file, event) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i] === file) {
        files[i].docType = event.target.value;
        handleChange(files);
        filesChange();
      }
    }
  };

  const inputChange = (file, event) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i] === file) {
        files[i].opis = event.target.value;
        handleChange(files);
        filesChange();
      }
    }
  };

  useEffect(() => {
    setFiles(defaultFiles);
    return (
      function cleanup() {
        files.forEach((file) => {
          if (file.preview) URL.revokeObjectURL(file.preview);
        });
      },
      [files]
    );
  }, [defaultFiles]);
  return (
    <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
      <input disabled={disabled} {...getInputProps()} ref={upload} />
      <FileButton fullWidth onClick={() => upload.current.click()}>
        <Box>
          <img src={folder} alt="" />
          <Typography variant="body2">Priložite fotografije</Typography>
        </Box>
      </FileButton>
      <Box>
        {files.map((file, index) => {
          let imageExt =
            file.extension == '.png' ||
            file.extension == '.svg' ||
            file.extension == '.jpg' ||
            file.extension == '.jpeg';
          let imagePreview = !!file.type && file.type.includes('image');
          let pdfExt = file.extension == '.pdf' || (!!file.type && file.type.includes('pdf'));
          let otherExt = !imageExt && !imagePreview && !pdfExt;
          return (
            <Box key={index}>
              <FileItem display="flex">
                <ListItem>
                  <Box
                    width="60px"
                    mr="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ color: '#87CBF3' }}
                  >
                    {imagePreview && <FitAvatar alt="Image" variant="rounded" src={file.preview} />}
                    {pdfExt && <BsFileEarmarkPdfFill size={35} />}
                    {imageExt && <AiFillFileImage size={35} />}
                    {otherExt && <AiFillFileText size={35} />}
                  </Box>
                  <ListItemText
                    sx={{ overflow: 'hidden' }}
                    primary={
                      <Box mb={2} ml={2} maxWidth="230px">
                        <Typography noWrap variant="body2">
                          {file.name}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <BoxStyle flexGrow={1} ml={2} mr={2} maxWidth={'250px'}>
                        <Select
                          fullWidth
                          value={file.docType}
                          disabled={disabled || (!file.type && selectClient() === 'Mikra')}
                          onChange={(e) => selectChange(file, e)}
                        >
                          {documentTypes.map((type) => {
                            return (
                              <StyledMenuItem value={type.ID} key={type.ID}>
                                <Typography variant="caption">{type.NAZIV}</Typography>
                              </StyledMenuItem>
                            );
                          })}
                        </Select>

                        {selectClient() === 'Mikra' && (
                          <Box mt={2}>
                            <Input
                              label="Opis"
                              placeholder="Unesite opis..."
                              value={file.opis || ''}
                              onChange={(e) => inputChange(file, e)}
                              disabled={disabled || !file.type}
                            />
                          </Box>
                        )}

                      </BoxStyle>
                    }
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
                <Box flexGrow={1}>
                  {!disableDelete && selectClient() != 'Lider' && (
                    <CloseButton
                      onClick={() => {
                        removeFile(file);
                      }}
                    >
                      <AiOutlineClose size={12} />
                    </CloseButton>
                  )} 
                </Box>
              </FileItem>
              <Divider width="100%" />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  justify-content: left;
  padding-left: 5px;
`;
const BoxStyle = styled(Box)`
  ${({ theme }) => `
  & .MuiInput-underline:before {
    border-bottom: 1px solid ${theme.palette.secondary.main};
    width:97%
  }
  & .MuiSelect-icon {
    color: ${theme.palette.info.placeholder}20;
  }
  & .MuiSelect-select {
    padding:4px;
  }
  `}
`;
const FileButton = styled(Button)`
  ${({ theme }) => `
  padding: 20px;
  height:150px;
  border-radius:8px;
  border:0.1rem solid;
  border-style: dashed;
  background-color:${theme.palette.background.secondary};
  border-color:${transparentize(0.8, theme.palette.primary.main)};
  `}
`;
const FileItem = styled(Box)`
  margin-top: 10px;
  width: 100%;
  border-radius: 8px;
  height: auto; 
  padding: 10px;
`;
const CloseButton = styled(Button)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
    height: 30px;
    min-width: 30px;
    padding: 0;
    margin-top: 12px;
    border-radius: 50%;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  `}
`;
const FitAvatar = styled(Avatar)`
  & .MuiAvatar-img {
    object-fit: fill;
  }
`;
export default Upload;
