import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import selectClient from '../hooks/selectClient';
import breakpoints from './breakpoints';
import components from './components';
import liderPalette from './liderPalette';
import mikraPalette from './mikraPalette';
import partnerPalette from './partnerPalette';
import typography from './typography';

let palette;
if (selectClient()=='Partner') {
  palette = partnerPalette;
} else if (selectClient()=='Mikra') {
  palette = mikraPalette;
} else if (selectClient()=='Lider') {
  palette = liderPalette;
}

const theme = createTheme({
  components,
  palette,
  typography,
  breakpoints,
  spacing: 4,
  shape: {
    borderRadius: 4,
  },
  topBarHeight: 80,
  mobileTopBarHeight: 40,
  navbarWidth: 90,
  mobileNavbarWidth: '73vw',
});

export default responsiveFontSizes(theme);
