import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Input, PhoneInput, RadioButton, Select } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import { loanOfficerSchema } from '@mikra/validation';

const ClientEditFormMikra = ({ handleSubmit, registry, client }) => {
  let city;
  city = client.grad;

  const methods = useForm({
    defaultValues: {
      jmbg: client.komitent,
      firstName: client.ime,
      lastName: client.prezime,
      fathersName: client.otac,
      lk: client.lk,
      address: client.adresa,
      municipality: city,
      mobilePhone: client.telefon,
      landlinePhone: client.telefonFix,
      remark: client.napomena,
      biznis: client.biznisRegistrovan,
    },
    resolver: yupResolver(loanOfficerSchema),
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  return (
    <Form spacing={4} methods={methods} onSubmit={handleSubmit} inputProps={{}}>
      <Input label="JMBG / JIB" name="jmbg" type="number" />
      <Input label="Ime" name="firstName" xs={6} />
      <Input label="Prezime" name="lastName" xs={6} />
      <Input label="Ime roditelja" name="fathersName" md={6} />
      <Input label="Broj LK" name="lk" md={6} />

      <Input label="Adresa" name="address" md={6} />
      <Select label="Grad" name="municipality" options={registry.cities} md={6} />
      <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
      <PhoneInput label="Fiksni telefon (opcionalno)" name="landlinePhone" type="tel" md={6} />
      <Input label="Napomena" name="remark" md={12} multiline rows={6} />
      <RadioButton
        title="Biznis registrovan"
        name="biznis"
        control={methods}
        options={[
          { value: 1, icon: 'Da' },
          { value: 2, icon: 'Ne' },
          { value: 3, icon: 'Bez biznisa' },
        ]}
      ></RadioButton>

      <Button
        disabled={isSubmitting}
        startIcon={isSubmitting && <CircularProgress size={20} />}
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
      >
        Spremi promjene
      </Button>
    </Form>
  );
};
export default ClientEditFormMikra;
