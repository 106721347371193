import { transparentize } from 'polished';

const white = '#FFFFFF';
const black = '#000000';
const lightGray = '#F5F5F5';
const darkGray = '#808080';
const gray = '#D3D3D3';

const palette = {
  black,
  white,
  gray,
  lightGray,
  darkGray,
  primary: {
    contrastText: white,
    main: '#8C64D2',
  },
  secondary: {
    contrastText: white,
    main: '#49A9E2',
  },
  error: {
    contrastText: white,
    main: '#ff4242',
  },
  warning: {
    contrastText: white,
    main: '#FFCC00',
  },
  info: {
    contrastText: white,
    main: '#2D8FD5',
    placeholder: '#222222',
  },
  success: {
    contrastText: white,
    main: '#66b821',
  },
  text: {
    primary: '#000000',
    secondary: '#3F3F3F',
    placeholder: '#5f6368',
    link: '#008EED',
    main: '#3F3F3F',
  },
  background: {
    default: '#ffffff',
    paper: white,
    block: '#f9fafb',
    survey: '#F4F6F8',
    secondary: transparentize(0.93, '#8C64D2'),
  },
};

export default palette;
