import React from 'react';
import { Button, FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import selectClient from '../hooks/selectClient';
import MikraServerErrorData from '../lotties/Mikra/serverError.json';
import PartnerServerErrorData from '../lotties/Partner/serverError.json';
import Lottie from './Lottie';

let serverErrorData;
if (selectClient() == 'Partner' || selectClient() == 'Lider') serverErrorData = PartnerServerErrorData;
else serverErrorData = MikraServerErrorData;

const ServerError = ({ error }) => {
  return (
    <Container>
      <Lottie animationData={serverErrorData} animate={true} />
      <Typography variant="h5">Došlo je do greške!</Typography>
      <FormHelperText>{error?.response?.data?.error?.message || error?.message || 'Application error'}</FormHelperText>
      <Button variant="contained" size="small" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > div {
    max-width: 80% !important;
    margin: auto;
  }

  .MuiFormHelperText-root {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .MuiButton-root {
    min-width: 144px;
  }
`;

export default ServerError;
