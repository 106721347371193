import React from 'react';
import { styled, Box, Grid, Typography, Avatar, Tooltip } from '@mui/material';
import { AiOutlineUser } from 'react-icons/ai';
import { FcSurvey } from 'react-icons/fc';
import { WrapDescription } from './SurveyTitleWrapper';

const AnswersListItem = ({ props, bgcolor, user, avatar }) => {
  return (
    <Wrapper p={5} bgcolor={bgcolor}>
      <Grid container direction="row" alignItems="center">
        <Grid xs={3} item>
          <Box display="flex">
            <AvatarOutline avatar={avatar}>
              {user || !!!props?.survey?.userDependency ? (
                <FcSurvey size={32} />
              ) : (
                <AiOutlineUser size={32} color="#7B78FE" />
              )}
            </AvatarOutline>
          </Box>
        </Grid>
        <Grid xs={7} item>
          <Grid container direction="column" spacing={2}>
            {props?.survey?.userDependency ? (
              <Grid item>
                <Tooltip title={!user ? props?.client?.ime + ' ' + props?.client?.prezime : props?.survey.name}>
                  <WrapDescription variant="subtitle1" align="left">
                    {!user ? props?.client?.ime + ' ' + props?.client?.prezime : props?.survey.name}
                  </WrapDescription>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title={props?.survey?.name}>
                  <WrapDescription variant="subtitle1" align="left">
                    {props?.survey.name}
                  </WrapDescription>
                </Tooltip>
              </Grid>
            )}
            {props?.survey?.userDependency ? (
              <Grid item>
                <Tooltip title={user ? props?.survey.description : props?.survey.name}>
                  <WrapDescription align="left" variant="body2">
                    {user ? props?.survey.description : props?.survey.name}
                  </WrapDescription>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title={props?.survey.description}>
                  <WrapDescription align="left" variant="body2">
                    {props?.survey.description}
                  </WrapDescription>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid xs={2} item>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6" color="secondary">
                {props?.survey.questions.length}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">Pitanja</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.bgcolor};
`;

const AvatarOutline = styled(Avatar)`
  background-color: ${(props) => props.avatar};
  height: 52px;
  width: 52px;
  border-radius: 8px;
`;

export default AnswersListItem;
