import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Select, Input } from '@components/rhf/src/MUI5';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getApplication, getRacuniForSelect, setGenerisanUgovorPolja, statusGenerisanUgovor } from '../api';
import { ActionsWrapper, DatePicker } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { dateFormat, getFirstLine } from '../utils';

function formatDate(date = new Date()) {
  const year = date.toLocaleString('default', {year: 'numeric'});
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', {day: '2-digit'});

  return [year, month, day].join('-');
}

const ApplicationContractGenerated = ({ brojZahtjeva, clientId, previous, next, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { officerSifra: idSluzbenik, officerUsername } = useOfficerProfile();
  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
  });
  const { isSubmitting } = methods.formState;
  const [printingDate, setPrintingDate] = useState(null);
  const [open, setOpen] = useState(false);


  const datumStampeZahtjevaWatch = useWatch({
    control: methods.control,
    name: 'datumStampeZahtjeva',
  });


  let racuniKomitenta = useQuery(['accounts', clientId], () => getRacuniForSelect(clientId), {
    onSuccess: () => {},
  });
  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      setPrintingDate(res.printingRequestDate);
      methods.setValue('account', res.account);
      if (res.printingRequestDate) {
        const date = new Date(res.printingRequestDate);
        methods.setValue('datumStampeZahtjeva', formatDate(date))
      }
    },
  });
  const { mutateAsync: mutateGenerisanUgovorPolja } = useMutation(setGenerisanUgovorPolja, {
    onSuccess: (res) => {
      setOpen(true);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });
  const { mutate: mutateStatusGenerisanUgovor } = useMutation(statusGenerisanUgovor, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste generisali ugovor.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const handleSubmit = (values) => {

    if (!values.account || !datumStampeZahtjevaWatch) {
      enqueueSnackbar('Molimo popunite potrebne podatke.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    values.printingRequestDate = dateFormat(new Date(datumStampeZahtjevaWatch));
    values.idSluzbenik = idSluzbenik;
    values.brojZahtjeva = brojZahtjeva;
    values.modifiedBy = officerUsername;

    mutateGenerisanUgovorPolja(values);
  };
  const statusGenerisanSubmit = () => {
    const values = { brojZahtjeva: brojZahtjeva, modifiedBy: officerUsername };
    mutateStatusGenerisanUgovor(values);
  };
  return (
    <>
      <Form spacing={4} methods={methods} onSubmit={disabled ? next : handleSubmit} inputProps={{ disabled: disabled }}>
        <Select label="Računi" name="account" options={racuniKomitenta.data || []} md={12} />
        {/* <DatePicker disabled={disabled} datum={printingDate} setDatum={setPrintingDate} label="Datum štampe zahtjeva" /> */}

        <Input label="Datum štampe zahtjeva" name="datumStampeZahtjeva" type="date" InputLabelProps={{ shrink: true }} />

        <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
      </Form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Prelazak u status generisan ugovor?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Status generisan ugovor</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={statusGenerisanSubmit}>Da</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationContractGenerated;
