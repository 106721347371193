import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Input } from '@components/rhf/src/MUI5';
import { Box } from '@mui/material';
import { editTroskoviDomacinstva, getTroskoviDomacinstva, insertTroskoviDomacinstva } from '../api';
import { ActionsWrapper, ServerError, Spinner } from '../components';
import { getFirstLine } from '../utils';

const HouseholdBalance = ({ brojZahtjeva, previous, next, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      realestate: '',
      car: '',
      land: '',
      cash: '',
      stocks: '',
      commercial: '',
      other: '',
      householdDebt: '',
      obligations: '',
    },
  });

  const { isSubmitting } = methods.formState;
  const { mutateAsync: mutateAdd } = useMutation(insertTroskoviDomacinstva, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno unesen bilans domaćinstva.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateEdit } = useMutation(editTroskoviDomacinstva, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno sačuvane promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const troskoviDomacinstva = useQuery(
    ['getTroskoviDomacinstva', brojZahtjeva],
    () => getTroskoviDomacinstva(brojZahtjeva),
    {
      enabled: !!brojZahtjeva,
      onSuccess: (res) => {
        _.each(res, (val, key) => {
          if (val == null) _.set(res, key, '');
        });
        methods.reset(res);
      },
    }
  );

  const handleSubmit = (values) => {
    if (!_.isEqual(troskoviDomacinstva.data, values)) {
      values.brojZahtjeva = brojZahtjeva;
      if (!troskoviDomacinstva.data) {
        return mutateAdd(values);
      } else return mutateEdit(values);
    }
    next();
  };

  return (
    <Box>
      {troskoviDomacinstva.error && <ServerError error={troskoviDomacinstva.error} />}
      {troskoviDomacinstva.isLoading && <Spinner />}
      {!troskoviDomacinstva.error && !troskoviDomacinstva.isLoading && (
        <Form
          spacing={4}
          methods={methods}
          onSubmit={disabled ? next : handleSubmit}
          inputProps={{ disabled: disabled }}
        >
          <Input label="Stan, kuća" name="realestate" type="number" />
          <Input label="Automobil" name="car" type="number" />
          <Input label="Zemljište" name="land" type="number" />
          <Input label="Gotovina" name="cash" type="number" />
          <Input label="Dionice" name="stocks" type="number" />
          <Input label="Poslovni prostor" name="commercial" type="number" />
          <Input label="Ostala pokretna imovina" name="other" type="number" />
          <Input
            label="Ostatak duga po svim aktivnim kreditima zajedničkog domaćinstva"
            name="householdDebt"
            type="number"
          />
          <Input label="Ostale obaveze (dobavljači, porez, pozajmice)" name="obligations" type="number" />
          <Box height="16px"></Box>
          <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
        </Form>
      )}
    </Box>
  );
};

export default HouseholdBalance;
