import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Select } from '@components/rhf/src/MUI5';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getApplication, getOdborGrupe, setKrdOdborGrupa, statusKompletiran, statusUToku } from '../api';
import { ActionsWrapper } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { getFirstLine } from '../utils';

const ApplicationCompleted = ({ brojZahtjeva, previous, next, disabled, setKompletiran }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;
  const { officerUsername } = useOfficerProfile();

  const { mutate: mutateStatusKompletiran } = useMutation(statusKompletiran, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste kompletirali zahtjev.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });
  const { mutate: mutateStatusUToku } = useMutation(statusUToku, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste vratili zahtjev u status "U toku".', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const { mutateAsync: mutateKrdOdborGrupa } = useMutation(setKrdOdborGrupa, {
    onSuccess: (res) => {
      setOpen(true);
    },
  });

  const odborGrupe = useQuery('odborGrupe', getOdborGrupe);
  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      methods.setValue('boardGroup', res.boardGroup || '');
    },
  });

  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    values.modifiedBy = officerUsername;

    return mutateKrdOdborGrupa(values);
  };

  if (disabled) setKompletiran(true);

  const statusKompletiranSubmit = () => {
    const values = { brojZahtjeva: brojZahtjeva, modifiedBy: officerUsername };
    setKompletiran(true);
    mutateStatusKompletiran(values);
  };
  const revertStatus = () => {
    const values = { brojZahtjeva: brojZahtjeva, modifiedBy: officerUsername };
    setKompletiran(false);
    mutateStatusUToku(values);
  };

  return (
    <>
      {disabled && (
        <Box display="flex" width="100%" mb={6}>
          <Button variant="outlined" color="error" sx={{ width: '100%' }} onClick={revertStatus}>
            Vrati u status "U toku"
          </Button>
        </Box>
      )}
      <Form spacing={4} methods={methods} onSubmit={disabled ? next : handleSubmit} inputProps={{ disabled: disabled }}>
        <Select label="Krd odbor grupa" name="boardGroup" options={odborGrupe.data || []} />

        <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
      </Form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Prelazak u status kompletiran?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Prelazak u status kompletiran?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={statusKompletiranSubmit}>Da</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ApplicationCompleted;
