import React from 'react';
import Navbar from './NavBar';
import TopBar from './TopBar';
import { Content, ContentContainer, Root, Wrapper } from './style';

const MainLayout = ({ topBarProps, children, ...rest }) => {
  return (
    <Root {...rest}>
      <TopBar backLink={topBarProps.backLink} forwardLink={topBarProps.forwardLink} text={topBarProps.text} />
      <Wrapper>
        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </Wrapper>
      <Navbar />
    </Root>
  );
};

export default MainLayout;
