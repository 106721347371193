import _, { toInteger } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Input } from '@components/rhf/src/MUI5';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
  editSegmentation,
  getSegmentation,
  insertSegmentation,
  fetchKomitentById,
  editKomitentDatumRodjenja,
} from '../api';
import { ActionsWrapper, ServerError, Spinner } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { getFirstLine } from '../utils';

const Segmentation = ({ IdKomitent, next, brojZahtjeva, setBrZahtjeva, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { officerSifra: idSluzbenik, officerUsername: usernameSluzbenik } = useOfficerProfile();

  const segmentationData = useQuery(['getSegmentation', brojZahtjeva], () => getSegmentation(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      methods.reset(res);
    },
  });
  const komitentInfo = useQuery(['fetchKomitentById', IdKomitent], () => fetchKomitentById(IdKomitent), {
    enabled: !!IdKomitent,
    onSuccess: (res) => {
      if (res[0].datumRodjenja == null) {
        let values = { IdKomitent: res[0].IdKomitent, jmbg: res[0].komitent };
        mutateEditKomitentDatumRodjenja(values);
      }
    },
  });

  const { mutateAsync: mutateAddSegmentation } = useMutation(insertSegmentation, {
    onSuccess: async (a) => {
      enqueueSnackbar('Uspješno unesena segmentacija.', {
        variant: 'success',
        autoHideDuration: 3000,
      });

      setBrZahtjeva(a.data);

      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditSegmentation } = useMutation(editSegmentation, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditKomitentDatumRodjenja } = useMutation(editKomitentDatumRodjenja, {
    onSuccess: () => {},
    onError: (error) => {},
  });

  const methods = useForm({
    mode: 'onBlur',
  });
  const { isSubmitting } = methods.formState;
  const addSegmentation = (values) => {
    values.komitent = IdKomitent;
    values.idSluzbenik = idSluzbenik;
    values.usernameSluzbenik = usernameSluzbenik;
    values.brojZahtjeva = brojZahtjeva;
    mutateAddSegmentation(values);
  };
  const handleSubmit = (values) => {
    //Provjera Edit/Submit/Next
    if (!_.isEqual(segmentationData.data, values)) {
      values.komitent = IdKomitent;
      values.idSluzbenik = idSluzbenik;
      values.usernameSluzbenik = usernameSluzbenik;
      values.brojZahtjeva = brojZahtjeva;
      if (brojZahtjeva == -1) {
        setOpen(true);
      } else {
        mutateEditSegmentation(values);
      }
    } else {
      next();
    }
  };
  return (
    <Box>
      {segmentationData.error && <ServerError error={segmentationData.error} />}
      {segmentationData.isLoading && <Spinner />}
      {!segmentationData.error && !segmentationData.isLoading && (
        <Form
          spacing={4}
          methods={methods}
          onSubmit={disabled ? next : handleSubmit}
          inputProps={{ disabled: disabled }}
        >
          <Input label="Prihodi aplikanta biznis (dokumentovano)" name="documentedAppBusinessIncome" type="number" />
          <Input label="Prihodi aplikanta biznis (izjava)" name="reportedAppBusinessIncome" type="number" />
          <Input label="Prihodi članova domaćinstva" name="householdMembersIncome" type="number" />
          <Input label="Prihodi aplikanta plate (dokumentovano)" name="documentedAppSalaryIncome" type="number" />
          <Input label="Prihodi aplikanta plate (izjava)" name="reportedAppSalaryIncome" type="number" />
          <Input label="Prihodi članova domaćistva plate i sl." name="householdMembersSalary" type="number" />
          <Box height="16px"></Box>
          <ActionsWrapper isSubmitting={isSubmitting} label="Spremi promjene" />
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Kreiranje zahtjeva za kredit'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Potvrdite kreiranje novog zahtjeva za kredit
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => addSegmentation(methods.getValues())}>Da</Button>
              <Button onClick={() => setOpen(false)} autoFocus>
                Ne
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Box>
  );
};

export default Segmentation;
