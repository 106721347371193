import { erpAxios } from '..';

const searchKomitenti = async (text, uName, checked, officerUsername) => {
  const { data } = await erpAxios('/komitenti/search/', {
    params: { text: text, uName: uName, checked: checked, officerUsername: officerUsername },
  });
  return data;
};

export default searchKomitenti;
