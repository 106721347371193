import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Form } from '@components/rhf/src/MUI5';
import { Box } from '@mui/material';
import { getLoanByZahtjevId } from '../api';
import documents1 from '../images/documents1.svg';
import documents2 from '../images/documents2.svg';
import documents3 from '../images/documents3.svg';
import documents4 from '../images/documents4.svg';
import { ActionsWrapper, DocumentButton } from '.';

const DocumentationPartner = ({ brojZahtjeva, previous, next }) => {
  const [kreditBr, setKreditBr] = useState(null);

  const methods = useForm();

  const loanDocsBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/ugovor/kreditna_dokumentacija.rdf';
  const loanDocsLink = `${loanDocsBaseLink}&p_broj=${kreditBr}&P_RAZLOG=11`;
  const contractBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/ugovor/ugovor_osnovni.rdf';
  const contractLink = `${contractBaseLink}&p_broj=${kreditBr}&P_RAZLOG=11`;
  const paymentPlanBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/eks_op_krediti.rdf&+&p_godina=0&p_uslov=Za%20klijenta';
  const paymentPlanLink = `${paymentPlanBaseLink}&p_broj=${kreditBr}&P_RAZLOG=11`;
  const codebtorDocsBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/ugovor/obaveze_suduznika.rdf';
  const codebtorDocsLink = `${codebtorDocsBaseLink}&p_broj=${kreditBr}&P_RAZLOG=5&p_user=A824819025352D9F`;

  const loanQuery = useQuery(['loan', getLoanByZahtjevId], () => getLoanByZahtjevId(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      setKreditBr(res.broj);
    },
  });

  return (
    <Box>
      {/* {loanQuery.data && ( */}
        <Form methods={methods} onSubmit={next}>
          <DocumentButton
            link={loanDocsLink}
            name={`Dokumentacija-${kreditBr}`}
            label="Kreditna dokumentacija"
            icon={documents1}
            disabled={loanQuery.data==''}
          />
          <DocumentButton
            link={paymentPlanLink}
            name={`Otplatni plan-${kreditBr}`}
            label="Otplatni plan"
            icon={documents2}
            disabled={loanQuery.data==''}
          />
          <DocumentButton
            link={codebtorDocsLink}
            name={`Dokumentacija sudužnika-${kreditBr}`}
            label="Dokumentacija sudužnik"
            icon={documents3}
            disabled={loanQuery.data==''}
          />
          <DocumentButton link={contractLink} name={`Ugovor-${kreditBr}`} label="Ugovor" icon={documents4} disabled={loanQuery.data==''}/>

          <ActionsWrapper previous={previous} label={'Sljedeći korak'} />
        </Form>
      {/* )} */}
    </Box>
  );
};

export default DocumentationPartner;
