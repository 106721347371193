import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { Checkbox, Form, Input, PhoneInput, Select } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress } from '@mui/material';
import {
  addKomitent,
  addRacuni,
  fetchRegistry,
  getIzvorPrihoda,
  getMjesnaZajednicaByOpcina,
  getStatusiZaposlenja,
} from '../api';
import { DatePicker, ServerError } from '../components';
import Accounts from '../components/Accounts';
import useOfficerProfile from '../hooks/useOfficerProfile';
import Layout from '../layout';
import {
  checkJmbg,
  optionalPhone,
  requiredAlphaString,
  requiredPhoneLoose,
  requiredString,
} from '@partner/validation/src/helpers';
import { object } from 'yup';

import { comitentTypeFromJMBG, dateFormat, genderFromJMBG, getFirstLine } from '../utils';

const ClientCreateLider = () => {
  const loanOfficerSchemaLider = object({
    firstName: requiredAlphaString(),
    lastName: requiredAlphaString(),
    jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
    lk: requiredString('Broj LK').test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
    address: requiredString(),
    landlinePhone: optionalPhone(),
    cipsAddress: requiredString(),
    cipsMunicipality: requiredString(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const [accounts, setAccounts] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const { officerUsername } = useOfficerProfile();
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(loanOfficerSchemaLider),
    mode: 'onBlur',
  });
  const { isSubmitting } = methods.formState;

  const equalAddressesWatch = useWatch({
    control: methods.control,
    name: 'equaladdresses',
    defaultValue: '',
  });
  const cityWatch = useWatch({
    control: methods.control,
    name: 'municipality',
    defaultValue: -1,
  });
  const registry = useQuery('registry', fetchRegistry);

  const prostori = useQuery(['prostori', cityWatch], () => getMjesnaZajednicaByOpcina(cityWatch), {
    enabled: !!cityWatch,
  });
  const izvori = useQuery('izvor', getIzvorPrihoda);
  const statusiZaposlenja = useQuery('statusZaposlenja', getStatusiZaposlenja);
  const getOpcinaCipsName = (sifra) => {
    for (let i = 0; i < registry.data.municipalities.length; i++) {
      if (sifra == registry.data.municipalities[i].value) {
        return registry.data.municipalities[i].text;
      }
    }
    return null;
  };

  const { mutateAsync: mutateAdd } = useMutation(addKomitent, {
    onSuccess: async (res) => {
      let newAcc = [...accounts];
      for (let i = 0; i < newAcc.length; i++) {
        newAcc[i].vlasnik = res.data;
      }
      const mutation = await mutateAddAccounts({ newAcc, officerUsername });
      setRedirect(true);
      return mutation;
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateAddAccounts } = useMutation(addRacuni, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno ste kreirali komitenta.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const [datumIsteka, setDatumIsteka] = useState(null);
  if (equalAddressesWatch) {
    methods.setValue('cipsAddress', methods.getValues('address'));
    methods.setValue('cipsMunicipality', methods.getValues('municipality'));
  }
  const handleSubmit = (values) => {
    values.createdBy = officerUsername;
    values.type = comitentTypeFromJMBG(values.jmbg);
    if (values.type == 'F') {
      values.gender = genderFromJMBG(values.jmbg);
    } else values.gender = null;
    values.LKExpirationDate = dateFormat(datumIsteka);
    values.cipsMunicipality = getOpcinaCipsName(values.cipsMunicipality);
    return mutateAdd(values);
  };

  const handleAccountSubmit = (values) => {
    setAccounts(values);
  };

  if (redirect) return <Redirect to="/clients" />;
  return (
    <Layout
      topBarProps={{
        backLink: '/',
        text: 'Novi komitent',
      }}
    >
      {(registry.error || izvori.error || prostori.error || statusiZaposlenja.error) && (
        <ServerError error={registry.error || izvori.error || prostori.error} />
      )}
      {registry.data && izvori.data && (
        <Box pl={4} pr={4}>
          <Box height="8px" />
          <Form spacing={4} methods={methods} onSubmit={handleSubmit} inputProps={{}}>
            <Input label="JMBG / JIB" name="jmbg" type="number" />
            <Input label="Ime" name="firstName" xs={6} />
            <Input label="Prezime" name="lastName" xs={6} />
            <Input label="Ime roditelja" name="fathersName" md={6} />
            <Input label="Broj LK" name="lk" md={6} />
            <DatePicker datum={datumIsteka} setDatum={setDatumIsteka} label="Datum isteka važenja lične karte" />
            <Select label="Bračni status" name="martialStatus" options={registry.data.martialStatus || []} md={6} />
            <Select label="Izvor prihoda" name="income" options={izvori.data || []} md={6} />
            <Select label="Status zaposlenja" name="employmentStatus" options={statusiZaposlenja.data || []} md={6} />
            <Checkbox label="Adresa = Adresa cips" name="equaladdresses" />
            <Input label="Adresa" name="address" md={6} />
            {!equalAddressesWatch ? <Input label="Adresa cips" name="cipsAddress" md={6} /> : <></>}
            <Select label="Grad" name="municipality" options={registry.data.municipalities || []} md={6} />
            {!equalAddressesWatch ? (
              <Select label="Grad cips" name="cipsMunicipality" options={registry.data.municipalities || []} md={6} />
            ) : (
              <></>
            )}
            <Select label="Mjesto stanovanja" name="localCommunity" options={prostori.data || []} md={6} />
            <Select
              label="Općina rođenja"
              name="muncipalityOfBirth"
              options={registry.data.municipalities || []}
              md={6}
            />
            <Select
              label="Zemlja porijekla"
              name="countryOfOrigin"
              options={registry.data.countryOfOrigin || []}
              md={6}
            />
            <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
            <PhoneInput label="Fiksni telefon (opcionalno)" name="landlinePhone" type="tel" md={6} />

            <Input label="Napomena" name="remark" md={12} />
            <Accounts handleChange={handleAccountSubmit} clientId={-1} />
            <Button
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} />}
              variant="contained"
              color="primary"
              type="submit"
            >
              Kreiraj komitenta
            </Button>
          </Form>
        </Box>
      )}
    </Layout>
  );
};

export default ClientCreateLider;
