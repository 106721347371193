import React, { useEffect, useState } from 'react';
import { AiFillBank, AiOutlineUser } from 'react-icons/ai';
import { MdCheck } from 'react-icons/md';
import {
  Avatar,
  Box,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import drawerImage from '../images/drawer.png';

const ApplicationDrawer = ({
  open,
  setOpen,
  stepLabels,
  icons,
  stepNumber,
  setStepNumber,
  stepsCompletedQuery,
  stepsUnlockedQuery,
  client,
}) => {
  const [step, setStep] = useState(stepNumber);
  const [isOpen, setIsOpen] = useState(open);
  const [stepsCompleted, setStepsCompleted] = useState(stepsCompletedQuery);
  const [stepsUnlocked, setStepsUnlocked] = useState(stepsUnlockedQuery);
  const theme = useTheme();
  useEffect(() => {
    setIsOpen(open);
    setStepsCompleted(stepsCompletedQuery);
    setStepsUnlocked(stepsUnlockedQuery);
    setStep(stepNumber);
  }, [open, stepsCompletedQuery, stepsUnlockedQuery, stepNumber]);

  const renderAvatar = (dataRow) => {
    if (dataRow.vrstaKomitenta == 'F' && dataRow.spol == 'Z')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.primary.main} />
        </AvatarOutline>
      );
    if (dataRow.vrstaKomitenta == 'F')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.secondary.main} />
        </AvatarOutline>
      );
    return (
      <AvatarOutline variant="rounded">
        <AiFillBank size={32} color={theme.palette.text.placeholder} />
      </AvatarOutline>
    );
  };
  return (
    <Box>
      {!stepsCompleted.isLoading && !stepsUnlocked.isLoading && (
        <DrawerStyled anchor="left" open={isOpen} onClose={() => setOpen(false)}>
          <Box
            pt={4}
            sx={{
              backgroundImage: `linear-gradient(180deg, #F1F7FE 0%, #FBFDFF 100%)`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
            }}
            display="flex"
            flexDirection="column"
          >
            <HeaderStyled pl={2} mb={4} ml={2} mr={2} pb={2}>
              <ListItem>
                <ListItemAvatar>
                  <div>{renderAvatar(client)}</div>
                </ListItemAvatar>
                <Box pt={1}>
                  <ListItemText
                    primary={`${client.ime} ${client.otac == null ? '' : `(${client.otac})`} ${
                      client.prezime == null ? '' : client.prezime
                    }`}
                    secondary={client.komitent}
                    primaryTypographyProps={{ variant: 'subtitle1' }}
                    secondaryTypographyProps={{ variant: 'body2' }}
                  />
                </Box>
              </ListItem>
            </HeaderStyled>
            <Stepper
              nonLinear
              activeStep={step}
              orientation="vertical"
              sx={{ minHeight: '100vh', zIndex: '2', width: '90%', pl: '16px' }}
            >
              {stepLabels.map((label, index) => {
                let avatarColor;
                let iconColor;
                let borderColor;
                if (index === step) {
                  avatarColor = theme.palette.primary.main;
                  borderColor = theme.palette.primary.main;
                  iconColor = theme.palette.white;
                } else if (stepsCompleted.data[index]) {
                  avatarColor = theme.palette.secondary.main;
                  borderColor = 'transparent';
                  iconColor = theme.palette.white;
                } else {
                  avatarColor = 'transparent';
                  borderColor = theme.palette.gray;
                  iconColor = theme.palette.gray;
                }

                return (
                  <Step key={index} completed={stepsCompleted.data[index]} disabled={!stepsUnlocked.data[index]}>
                    <StepButton
                      onClick={() => {
                        setStepNumber(index);
                        setOpen(false);
                      }}
                    >
                      <StepLabel
                        StepIconProps={{
                          icon: (
                            <DrawerAvatarOut sx={{ border: `2px solid ${borderColor}` }}>
                              <DrawerAvatarIn sx={{ backgroundColor: avatarColor, color: iconColor }}>
                                {stepsCompleted.data[index] ? <MdCheck size={26} /> : icons[index]}
                              </DrawerAvatarIn>
                            </DrawerAvatarOut>
                          ),
                        }}
                      >
                        <ListItemText
                          primary={`KORAK ${index + 1}`}
                          secondary={label}
                          primaryTypographyProps={{ variant: 'caption' }}
                          secondaryTypographyProps={{ variant: 'h6' }}
                        />
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <Box
              position="fixed"
              height="100%"
              width="200px"
              sx={{
                backgroundImage: `url(${drawerImage})`,
                backgroundPosition: 'right bottom',
                backgroundRepeat: 'no-repeat',
                bottom: 0,
                right: 0,
              }}
            />
          </Box>
        </DrawerStyled>
      )}
    </Box>
  );
};
const DrawerAvatarIn = styled(Avatar)`
  height: 36px;
  width: 36px;
`;
const DrawerAvatarOut = styled(Avatar)`
  height: '40px';
  width: '40px';
  background-color: transparent;
`;
const DrawerStyled = styled(Drawer)`
  & .MuiPaper-root {
    width: 100%;
  }
  & .MuiStepConnector-root {
    margin-left: 21px;
    flex: 0;
  }
  & .MuiStepConnector-line {
    min-height: 16px;
  }
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  background-color: ${theme.palette.lightGray} !important;
  float: left;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 7px;
  `}
`;
const HeaderStyled = styled(Box)`
  ${({ theme }) => `
background-color:${theme.palette.primary.main}10;
border-radius: 16px;
`}
`;

export default ApplicationDrawer;
