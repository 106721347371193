import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const InputNum = ({ name, control, label, error, unit = '', step = 1, max = 10000, min = 1, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box display="flex" flexDirection="column">
            <Box m="auto" mb={1}>
              <Typography variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
            </Box>
            <StyledInput
              {...field}
              variant="outlined"
              error={!!error}
              onChange={(e) => {
                const value = e.target.value === '' ? '' : Number(e.target.value);
                field.onChange(value);
              }}
              InputProps={{
                step,
                min,
                max,
                type: 'number',
                endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                disabled: disabled,
              }}
            />
          </Box>
        );
      }}
    />
  );
};

const StyledInput = styled(TextField)`
  font-size: 24px;
  text-align: center;

  .MuiInputBase-root {
    font-size: 24px;
  }

  .MuiInputBase-input {
    padding: 10px 14px;
    text-align: center;
  }

  .MuiInputAdornment-root {
    margin-left: -32px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-left: -24px;
    }
  }
`;

export default InputNum;
