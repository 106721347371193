import MuiButton from './overrides/MuiButton';
import MuiLink from './overrides/MuiLink';
import MuiFormControlLabel from './overrides/MuiFormControlLabel';
import MuiInputLabel from './overrides/MuiInputLabel';
import MuiSelect from './overrides/MuiSelect';
import MuiFormHelperText from './overrides/MuiFormHelperText';
import MuiCheckbox from './overrides/MuiCheckbox';
import MuiOutlinedInput from './overrides/MuiOutlinedInput';
import MuiListItem from './overrides/MuiListItem';
import MuiDivider from './overrides/MuiDivider';

const components = {
  MuiButton,
  MuiLink,
  MuiFormControlLabel,
  MuiInputLabel,
  MuiSelect,
  MuiFormHelperText,
  MuiCheckbox,
  MuiOutlinedInput,
  MuiListItem,
  MuiDivider,
};

export default components;
