import React from 'react';
import { Controller } from 'react-hook-form';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Stepper = ({ name, label, unit, control, max = 60, min = 1, disabled }) => {
  const handleNext = (value) => Math.min(Number.parseInt(value, 10) + 1, max);
  const handleBack = (value) => Math.max(Number.parseInt(value, 10) - 1, min);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box m="auto" mb={1}>
              <Typography variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
            </Box>
            <Container>
              <StepButton size="small" disabled={disabled} onClick={() => field.onChange(handleBack(field.value))}>
                <FiMinus color="#fff" />
              </StepButton>
              <Typography variant="body1">
                {field.value}{' '}
                <Typography variant="subtitle1" color="textSecondary" component="span">
                  {unit}
                </Typography>
              </Typography>
              <StepButton size="small" disabled={disabled} onClick={() => field.onChange(handleNext(field.value))}>
                <FiPlus color="#fff" />
              </StepButton>
            </Container>
          </Box>
        );
      }}
    />
  );
};
const Container = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  padding: 0 16px;

  p {
    font-size: 24px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    p {
      font-size: 20px;
    }
  }

  @media (max-width: 320px) {
    p {
      font-size: 16px;
    }
  }
`;

const StepButton = styled(IconButton)`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin: 0 8px;
  background-color: ${({ theme }) => theme.palette.secondary.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
  :hover {
    transform: scale(1.15);
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.gray};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0 4px;
  }
`;

export default Stepper;
