import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FaStream } from 'react-icons/fa';
import { MdAssignment, MdDescription, MdFactCheck } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  fetchKomitent,
  fetchRegistry,
  statusOdustao,
  statusStorniran,
  stepsCompleted,
  stepsDisabled,
  stepsUnlocked,
} from '../api';
import { ApplicationDocumentsMikra, ApplicationDrawer, CRKMikraApplication, LoanFormMikra, ServerError, Spinner } from '../components';
import ApplicationLayout from '../layout/ApplicationLayout';
import { getFirstLine, scrollToTop } from '../utils';

const LoanApplicationMikra = ({ brojZahtjeva, defaultClient }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [cancelledOpen, setCancelledOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [brZahtjeva, setBrZahtjeva] = useState(brojZahtjeva);
  const [cancelStatusStorniran, setCancelStatusStorniran] = useState(true);
  const icons = [<MdFactCheck size={26} />, <MdDescription size={26} />, <MdAssignment size={26} />];

  const stepLabels = ['Osnovni podaci', 'Dokumenti', 'CRK'];
  const registry = useQuery('registry', fetchRegistry);

  const clientInfo = useQuery(['fetchKomitent', defaultClient.komitent], () => fetchKomitent(defaultClient.komitent), {
    enabled: !!defaultClient.komitent,
  });

  const stepsDisabledQuery = useQuery(['stepsDisabled', brZahtjeva], () => stepsDisabled(brZahtjeva));
  const stepsCompletedQuery = useQuery(['stepsCompleted', brZahtjeva], () => stepsCompleted(brZahtjeva));
  const stepsUnlockedQuery = useQuery(['stepsUnlocked', brZahtjeva], () => stepsUnlocked(brZahtjeva));

  const { mutate: mutateStatusStorniran } = useMutation(statusStorniran, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste promijenili status zahtjeva.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const { mutate: mutateStatusOdustao } = useMutation(statusOdustao, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste promijenili status zahtjeva.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const changeStep = (step) => {
    if (step != 0 && !stepsUnlockedQuery.data[step] && stepsDisabledQuery.data[step - 1]) return;
    scrollToTop();
    queryClient.invalidateQueries(['stepsCompleted', brZahtjeva]);
    queryClient.invalidateQueries(['stepsDisabled', brZahtjeva]);
    queryClient.invalidateQueries(['stepsUnlocked', brZahtjeva]);
    setStepNumber(step);
  };

  const handleCancel = () => {
    if (cancelStatusStorniran) mutateStatusStorniran({ brojZahtjeva: brZahtjeva });
    else mutateStatusOdustao({ brojZahtjeva: brZahtjeva });
    setCancelledOpen(false);
  };

  const errorGroup =
    registry.error ||
    stepsCompletedQuery.error ||
    stepsDisabledQuery.error ||
    stepsUnlockedQuery.error ||
    clientInfo.error;

  const loadingGroup =
    registry.isLoading ||
    stepsCompletedQuery.isLoading ||
    stepsDisabledQuery.isLoading ||
    stepsUnlockedQuery.isLoading ||
    clientInfo.isLoading;

  const noError =
    registry.data && stepsCompletedQuery.data && stepsDisabledQuery.data && stepsUnlockedQuery.data && clientInfo.data;

  const Icon = (value) => {
    return (
      <Box
        p={1}
        display="flex"
        alignItems="center"
        sx={{
          border: '1px solid',
          borderColor: value.color,
          borderRadius: '8px',
        }}
      >
        <ListItem button>
          <Box display="flex" width="100%" alignItems="center">
            <ListItemText
              primary={value.label}
              primaryTypographyProps={{ variant: 'subtitle2', color: value.color, align: 'center' }}
            ></ListItemText>
          </Box>
        </ListItem>
      </Box>
    );
  };

  return (
    <ApplicationLayout
      topBarProps={{
        backLink: { pathname: '/loanapplications' },
        text: 'Zahtjev za kredit',
      }}
    >
      <Box mb={5}>
        <LinearProgress
          sx={{ height: '8px' }}
          variant="determinate"
          value={((stepNumber + 1) / stepLabels.length) * 100}
          color="secondary"
        />
      </Box>
      <Box mb={10} pr={4} pl={4}>
        <ListItemStyled onClick={() => setOpen(true)}>
          <AvatarOut>
            <AvatarIn>{icons[stepNumber]}</AvatarIn>
          </AvatarOut>
          <ListItemText
            primary={`KORAK ${stepNumber + 1}/${stepLabels.length}`}
            secondary={stepLabels[stepNumber]}
            primaryTypographyProps={{ variant: 'caption' }}
            secondaryTypographyProps={{ variant: 'h6' }}
          />
          <Box pr={4} sx={{ color: `${theme.palette.primary.main}70` }}>
            <FaStream />
          </Box>
        </ListItemStyled>
        {errorGroup && <ServerError error={errorGroup} />}
        {loadingGroup && <Spinner />}
        {noError && (
          <Box sx={{ overflowX: 'auto' }}>
            {stepNumber === 0 && (
              <LoanFormMikra
                registry={registry.data}
                next={() => changeStep(1)}
                IdKomitent={defaultClient.komitent}
                brojZahtjeva={brZahtjeva}
                setBrZahtjeva={setBrZahtjeva}
                disabled={stepsDisabledQuery.data[0]}
                komitent={clientInfo.data ? clientInfo.data : defaultClient}
              />
            )}
            {stepNumber === 1 && (
              <ApplicationDocumentsMikra
                applicationId={brZahtjeva}
                previous={() => changeStep(0)}
                next={() => changeStep(2)}
                disabled={stepsDisabledQuery.data[1]}
              />
            )}
            {stepNumber === 2 && <CRKMikraApplication user={clientInfo.data ? clientInfo.data : defaultClient} applicationId={brZahtjeva} />}

            <ApplicationDrawer
              open={open}
              setOpen={setOpen}
              stepLabels={stepLabels}
              icons={icons}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
              stepsCompletedQuery={stepsCompletedQuery}
              stepsUnlockedQuery={stepsUnlockedQuery}
              client={clientInfo.data ? clientInfo.data : defaultClient}
            />

            <Dialog open={cancelledOpen} onClose={() => setCancelledOpen(false)}>
              <DialogTitle>{'Otkaži zahtjev'}</DialogTitle>
              <DialogContent>
                <Box mb={5} display="flex" width="100%" justifyContent="center">
                  <Checkbox
                    icon={<Icon label="Storniraj zahtjev" borderColor={theme.palette.gray} />}
                    checkedIcon={
                      <Icon
                        label="Storniraj zahtjev"
                        borderColor={theme.palette.secondary.main}
                        color={theme.palette.secondary.main}
                      />
                    }
                    checked={cancelStatusStorniran}
                    onChange={() => setCancelStatusStorniran(true)}
                  />
                  <Checkbox
                    icon={<Icon label="Komitent odustao" borderColor={theme.palette.gray} />}
                    checkedIcon={
                      <Icon
                        label="Komitent odustao"
                        borderColor={theme.palette.secondary.main}
                        color={theme.palette.secondary.main}
                      />
                    }
                    checked={!cancelStatusStorniran}
                    onChange={() => setCancelStatusStorniran(false)}
                  />
                </Box>
                <DialogContentText>Postaviti zahtjev u status storniran/odustao?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>Da</Button>
                <Button onClick={() => setCancelledOpen(false)} autoFocus>
                  Ne
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>
    </ApplicationLayout>
  );
};
const ListItemStyled = styled(ListItem)`
  ${({ theme }) => `
  background-color: ${theme.palette.background.secondary};
  margin-bottom: 30px;
  padding-left: 20px;
  padding-bottom:16px;
  padding-top:16px;
  border-radius: 10px;
  `}
`;
const AvatarIn = styled(Avatar)`
  ${({ theme }) => `
  background-color: ${theme.palette.primary.main};
  height: 36px;
  width: 36px;
  `}
`;
const AvatarOut = styled(Avatar)`
  ${({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  height: '40px';
  width: '40px';
  background-color: transparent;
  margin-right:16px;
  `}
`;
export default LoanApplicationMikra;
