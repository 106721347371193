import JsFileDownloader from 'js-file-downloader';
import React from 'react';
import { Box, ListItem, ListItemAvatar, ListItemText, useTheme } from '@mui/material';
import download from '../images/download.svg';

const DocumentButton = ({ link, label, icon, name }) => {
  const theme = useTheme();
  // const downloadPdf = (URL, fileName) => {
  //   new JsFileDownloader({
  //     url: URL,
  //     filename: fileName,
  //   });
  // };
  const openPdfInNewTab = (URL) => {
    window.open(URL, '_blank');
  };

  return (
    <Box p={1} mb={3} sx={{ border: '1px solid', borderColor: theme.palette.gray, borderRadius: '8px' }}>
      <ListItem
        button
        onClick={() => {
          // downloadPdf(link, name);
          openPdfInNewTab(link);
        }}
      >
        <Box display="flex" width="100%" alignItems="center">
          <Box ml={4} mr={4}>
            <ListItemAvatar sx={{ height: '35px' }}>
              <img src={icon} alt="" />
            </ListItemAvatar>
          </Box>
          <ListItemText primary={label} primaryTypographyProps={{ variant: 'subtitle1' }}></ListItemText>
          <Box display="flex" justifyContent="center">
            <ListItemAvatar sx={{ height: '30px', minWidth: '40px' }}>
              <img src={download} alt="" />
            </ListItemAvatar>
          </Box>
        </Box>
      </ListItem>
    </Box>
  );
};
export default DocumentButton;
