import React from 'react';
import HomeTopBar from './HomeTopBar';
import NavBar from './NavBar';
import { Content, ContentContainer, Root, Wrapper } from './style';

const Layout = ({ children, ...rest }) => {
  return (
    <Root {...rest}>
      <HomeTopBar />
      <Wrapper>
        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </Wrapper>
      <NavBar />
    </Root>
  );
};

export default Layout;
