const MuiSelect = {
  styleOverrides: {
    root: {
      '&&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
};

export default MuiSelect;
