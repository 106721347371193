import { useEffect, useState } from 'react';

const useLocationGrant = () => {
  const [isLocationGranted, setIsLocationGranted] = useState(false);

  useEffect(() => {
    const checkLocationGrant = async () => {
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then((permission) =>
              // is geolocation granted?
              (permission.state === 'granted' || permission.state === 'prompt') && navigator.geolocation
                ? setIsLocationGranted(true)
                : setIsLocationGranted(false)
            )
            .catch(() => setIsLocationGranted(false))
        : // Permission API was not implemented
          setIsLocationGranted(false);
    };
    checkLocationGrant();
  }, []);

  return isLocationGranted;
};

export default useLocationGrant;
