import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { FaMoneyBill } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Form, Input, Select } from '@components/rhf/src/MUI5';
import { Divider } from '@material-ui/core';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LoanDates,
  deleteReplacementLoan,
  existingReplacementLoans,
  getApplication,
  getCosts,
  getDates,
  getLoanByZahtjevId,
  getRacuniForSelect,
  insertCosts,
  loanReleaseAnalytics,
  replacementLoans,
} from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { dateFormat, getFirstLine } from '../utils';
import { DatePicker, ServerError, Spinner } from '.';

function formatDate(date = new Date()) {
  const year = date.toLocaleString('default', {year: 'numeric'});
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', {day: '2-digit'});

  return [year, month, day].join('-');
}


const LoanRelease = ({ brojZahtjeva, previous, next, disabled, clientId }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [openCostsDrawer, setOpenCostsDrawer] = useState(false);
  const [openPayoutDrawer, setOpenPayoutDrawer] = useState(false);
  const [loanPaymentDate, setLoanPaymentDate] = useState(null);
  const [firstInstallmentDate, setFirstInstallmentDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState();
  const [kreditBr, setKreditBr] = useState(null);
  const [loanAmount, setLoanAmount] = useState(null);
  const { officerUsername } = useOfficerProfile();
  const [supplier, setSupplier] = useState();
  const [loanData, setLoanData] = useState();

  const dodatniTroskoviOptions = [
    { value: '1', text: 'Mjenica' },
    { value: '3', text: 'Ovjera izjave' },
    { value: '2', text: 'Ovjera dokumenata' },
    { value: '5', text: 'Nema dodatnih troškova' },
  ];

  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
  });
  const control = useForm({
    defaultValues: {},
    mode: 'onBlur',
  });
  const releaseTypes = [
    { value: 'R', text: 'Zamjenski' },
    { value: 'Z', text: 'Dobavljač' },
  ];

  const releaseTypesWatch = useWatch({
    control: control.control,
    name: 'releaseType',
    defaultValue: 'A',
  });

  useQuery(['loan', getLoanByZahtjevId], () => getLoanByZahtjevId(brojZahtjeva), {
    onSuccess: (res) => {
      setKreditBr(res.broj);
      setLoanData(res);
    },
  });
  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    onSuccess: (res) => {
      setSupplier(res.dobavljac);
      setLoanAmount(res.amount);
    },
  });
  let costs = useQuery(['costs', kreditBr], () => getCosts(kreditBr), {
    enabled: !!kreditBr,
  });

  useQuery(['dates', getDates], () => getDates(kreditBr), {
    enabled: !!kreditBr,
    onSuccess: (data) => {
      if (data) {
        setLoanPaymentDate(data.LoanPaymentDate);
        setFirstInstallmentDate(data.FirstInstallment);
        setDefaultDate(data.LoanPaymentDate);

        if (data.FirstInstallment) {
          const date = new Date(data.FirstInstallment);
          methods.setValue('datumPrveRate', formatDate(date))
        }
        if (data.LoanPaymentDate) {
          const date = new Date(data.LoanPaymentDate);
          methods.setValue('datumIsplateKredita', formatDate(date))
        }
      }
    },
  });

  let supplierAccounts = useQuery(['accounts', supplier], () => getRacuniForSelect(supplier), {
    enabled: !!supplier,
  });
  let replacementLoansData = useQuery(['replacementLoans', clientId], () => replacementLoans(clientId), {
    enabled: !!clientId,
  });

  let existingLoans = useQuery(['existingReplacementLoans', kreditBr], () => existingReplacementLoans(kreditBr), {
    enabled: !!kreditBr,
  });

  const { mutate: mutateLoanDates } = useMutation(LoanDates, {
    onSuccess: (res) => {
      //Nakon što se unesu datumi i odabere puštanje kredita, dodaje se puštanje kredita u tabelu krd_pustanja_kredita_analitika
      let amount = loanData.iznos;
      for (let i = 0; i < existingLoans.data.length; i++) {
        if (existingLoans.data[i].tipPustanja == 'T') amount -= existingLoans.data[i].iznos;
      }
      let values = {
        broj: kreditBr,
        releaseType: 'T',
        iznos: amount,
        year: loanData.godina,
        createdBy: officerUsername,
        modifiedBy: officerUsername,
      };
      mutateLoanRelease(values);
      next();
      enqueueSnackbar('Uspješno puštanje kredita.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutatedeleteReplacementLoan } = useMutation(deleteReplacementLoan, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['existingReplacementLoans', kreditBr]);
      enqueueSnackbar('Uspješno obrisana stavka.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateCosts } = useMutation(insertCosts, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['costs', kreditBr]);
      setOpenCostsDrawer(false);
      enqueueSnackbar('Uspješno ste unijeli trošak.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      methods.reset();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateLoanReleaseAnalytics } = useMutation(loanReleaseAnalytics, {
    onSuccess: (res) => {
      setOpenPayoutDrawer(false);
      queryClient.invalidateQueries(['existingReplacementLoans', kreditBr]);
      enqueueSnackbar('Uspješno ste unijeli vrstu isplate.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateLoanRelease } = useMutation(loanReleaseAnalytics, {
    onSuccess: (res) => {},
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const dodatniTroskoviNaziv = (sifra) => {
    for (let i = 0; i < dodatniTroskoviOptions.length; i++) {
      if (sifra == dodatniTroskoviOptions[i].value) return dodatniTroskoviOptions[i].text;
    }
    return null;
  };
  const handleSubmit = () => {
    let values = methods.getValues();
    values.broj = kreditBr;
    values.createdBy = officerUsername;
    values.modifiedBy = officerUsername;
    mutateCosts(values);
  };
  const submitPayoutType = () => {
    if (releaseTypesWatch == 'R') submitPayoutTypeReplace();
    else if (releaseTypesWatch == 'Z') submitPayoutTypeSupplier();
    else return;
  };
  const submitPayoutTypeSupplier = () => {
    let values = control.getValues();
    values.broj = kreditBr;
    values.createdBy = officerUsername;
    values.modifiedBy = officerUsername;
    values.year = loanData.godina;
    mutateLoanReleaseAnalytics(values);
  };

  const submitPayoutTypeReplace = () => {
    let values = control.getValues();
    if (replacementLoansData.data && values.contractNumber) {
      for (let i = 0; i < replacementLoansData.data.length; i++) {
        if (replacementLoansData.data[i].value == values.contractNumber) {
          values.amount = replacementLoansData.data[i].SALDO;
          values.broj = kreditBr;
          values.year = replacementLoansData.data[i].GODINA;
          //Na koji način očistiti sva input polja nakon promjene u selectu?
          values.supplierAccount = null;
          values.invoice = null;
          values.brojK = replacementLoansData.data[i].BROJ;
          values.brojUgovoraK = replacementLoansData.data[i].value;
          values.createdBy = officerUsername;
          values.modifiedBy = officerUsername;
        }
      }
    } else {
      enqueueSnackbar('Molimo odaberite broj ugovora.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    mutateLoanReleaseAnalytics(values);
  };
  
  const datumPrveRateWatch = useWatch({
    control: methods.control,
    name: 'datumPrveRate',
  });

  const datumIsplateKreditaWatch = useWatch({
    control: methods.control,
    name: 'datumIsplateKredita',
  });

  const LoanReleaseSubmit = () => {
    const datumPrveRate = dateFormat(new Date(datumPrveRateWatch))
    if (defaultDate == datumPrveRate) next();
    else {
      let values = {
        LoanPaymentDate: dateFormat(new Date(datumIsplateKreditaWatch)),
        FirstInstallment: datumPrveRate,
        broj: kreditBr,
        iznos: loanAmount,
        createdBy: officerUsername,
        modifiedBy: officerUsername,
      };
      mutateLoanDates(values);
    }
  };

  const deleteLoan = (loan) => {
    mutatedeleteReplacementLoan(loan.id);
  };

  return (
    <Box>
      {(costs.error || existingLoans.error) && <ServerError error={costs.error || existingLoans.error} />}
      {(costs.isLoading || existingLoans.isLoading) && <Spinner />}
      {!existingLoans.error && !existingLoans.isLoading && (
        <Box mb={20}>
          <Box pl={4} pr={4}>
            <Box display="flex" flexDirection="row">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">Vrsta isplate</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                {!disabled && (
                  <AddPayoutButton onClick={() => setOpenPayoutDrawer(!openPayoutDrawer)}>
                    <AiOutlinePlus size={30} />
                  </AddPayoutButton>
                )}
              </Box>
            </Box>
            {existingLoans.data &&
              existingLoans.data.map((loan) => {
                return (
                  <Box display="flex" flexDirection="row" key={loan.brojK}>
                    <Box flexGrow={1}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: '#F1F7FE' }}>
                            <Typography variant="body2" color="secondary">
                              {(loan.tipPustanja == 'R' && 'Z') ||
                                (loan.tipPustanja == 'Z' && 'D') ||
                                (loan.tipPustanja == 'T' && 'P')}
                            </Typography>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            (loan.tipPustanja == 'R' && `Ugovor: ${loan.brojUgovoraK}`) ||
                            (loan.tipPustanja == 'Z' && `${loan.ime}`) ||
                            (loan.tipPustanja == 'T' && `Puštanje kredita`)
                          }
                          secondary={
                            <Box mt={2}>
                              <Typography variant="body2">{`Iznos: ${
                                Math.round(loan.iznos * 100 + Number.EPSILON) / 100
                              } KM`}</Typography>
                            </Box>
                          }
                          primaryTypographyProps={{ variant: 'h6' }}
                          secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
                        />
                      </ListItem>
                    </Box>
                    <Box display="flex" alignItems="center">
                      {!disabled && (
                        <Button onClick={() => deleteLoan(loan)}>
                          <AvatarOutline>
                            <AiOutlineDelete size={16} />
                          </AvatarOutline>
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
            <Box p={4} pl={8} pr={8} mb={3} ml={-8} mr={-8}>
              <Typography variant="subtitle1">Dodatni troškovi</Typography>
              <List>
                {!!costs.data &&
                  costs.data.map((cost, index) => {
                    return (
                      <Box key={index}>
                        <ListItem>
                          <Box display="flex" width="100%">
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: theme.palette.secondary.main,
                                  bgcolor: theme.palette.background.secondary,
                                }}
                              >
                                <FaMoneyBill></FaMoneyBill>
                              </Avatar>
                            </ListItemAvatar>
                            <Box flexGrow={1}>
                              <ListItemText
                                primary={dodatniTroskoviNaziv(cost.additionalCostValue)}
                                primaryTypographyProps={{ variant: 'subtitle1' }}
                              />
                            </Box>
                            <Box>
                              <ListItemText
                                primary={`${cost.amount} KM`}
                                primaryTypographyProps={{ variant: 'subtitle1' }}
                              />
                            </Box>
                          </Box>
                        </ListItem>
                        <Divider />
                      </Box>
                    );
                  })}
              </List>
              <AddButton variant="contained" disabled={disabled} onClick={() => setOpenCostsDrawer(!openCostsDrawer)}>
                + Dodaj trošak
              </AddButton>
            </Box>
            <Form spacing={4} methods={methods}>
            <Input label="Datum isplate kredita" name="datumIsplateKredita" type="date" InputLabelProps={{ shrink: true }} disabled={disabled} />
            <Input label="Datum prve rate" name="datumPrveRate" type="date" InputLabelProps={{ shrink: true }} disabled={disabled} />
            </Form>
          </Box>
          <ActionsWrapper>
            <Box
              sx={{ backgroundColor: 'white' }}
              pt={6}
              pb={6}
              display="flex"
              alignItems="center"
              flexDirection="row"
              width={'100%'}
              pl={6}
              pr={6}
            >
              {!!previous && (
                <Box flexGrow={1} mr={2}>
                  <ArrowButton variant="contained" color="inherit" onClick={previous} disableElevation disableRipple>
                    <Box display="flex" alignItems="center">
                      <IoIosArrowBack size={22} />
                    </Box>
                  </ArrowButton>
                </Box>
              )}
              <Button
                variant="contained"
                onClick={disabled ?  next : LoanReleaseSubmit}
                id="loanRelease"
                //onClick={next}
                color="primary"
                disableElevation
                disableRipple
              >
                Puštanje kredita
              </Button>
            </Box>
          </ActionsWrapper>
          <DrawerStyled onClose={() => setOpenCostsDrawer(false)} open={openCostsDrawer} anchor="bottom">
            <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
              <Box width="100px" />
              <Box display="flex" justifyContent="center" width="100%">
                <Typography color="white">Novi trošak</Typography>
              </Box>
              <HeaderIcon onClick={() => setOpenCostsDrawer(false)}>
                <AiOutlineClose size={20} />
              </HeaderIcon>
            </BoxHeader>
            <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <Box>
                <Form spacing={4} methods={methods}>
                  <Select label="Dodatni troškovi" name="additionalCostValue" options={dodatniTroskoviOptions || []} />
                  <Input label="Iznos" name="amount" type="number" />
                </Form>
              </Box>
              <Box>
                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                  Dodaj
                </Button>
              </Box>
            </Box>
          </DrawerStyled>
          <DrawerStyled onClose={() => setOpenPayoutDrawer(false)} open={openPayoutDrawer} anchor="bottom">
            <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
              <Box width="100px" />
              <Box display="flex" justifyContent="center" width="100%">
                <Typography color="white">Vrsta isplate</Typography>
              </Box>
              <HeaderIcon onClick={() => setOpenPayoutDrawer(false)}>
                <AiOutlineClose size={20} />
              </HeaderIcon>
            </BoxHeader>
            <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <Form spacing={4} methods={control}>
                <Select label="Tip puštanja" name="releaseType" options={releaseTypes || []} />
                {releaseTypesWatch == 'R' ? (
                  <Select label="Broj ugovora" name="contractNumber" options={replacementLoansData.data || []} />
                ) : (
                  <></>
                )}
                {releaseTypesWatch == 'Z' ? (
                  <Box display="flex" flexDirection="column">
                    <Input label="Iznos" name="amount" type="number" />
                    <Box height="16px"></Box>
                    <Input label="Broj fakture" name="invoice" type="number" />
                    <Box height="16px"></Box>
                    <Select label="Računi dobavljača" name="supplierAccount" options={supplierAccounts.data || []} />
                  </Box>
                ) : (
                  <></>
                )}
              </Form>
              <Box>
                <Button variant="contained" color="secondary" onClick={submitPayoutType}>
                  Dodaj
                </Button>
              </Box>
            </Box>
          </DrawerStyled>
        </Box>
      )}
    </Box>
  );
};
const AddButton = styled(Button)`
  ${({ theme }) => `
  background-color:white;
  color:${theme.palette.secondary.main};
  border: 0.5px solid ${theme.palette.secondary.main};

  `}
`;
const AddPayoutButton = styled(Button)`
  ${({ theme }) => `
  background-color:${theme.palette.secondary.main}30;
  color:${theme.palette.secondary.main};
  height: 64px;
  border-radius: 100%;
  transform: scale(0.6, 0.6);
  margin-top:-20px;
  `}
`;
const BoxHeader = styled(Box)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main}95;
  border-radius: 40px 40px 0px 0px;
  `}
`;
const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    height: 100%;
  }
`;
const HeaderIcon = styled(Button)`
  padding: 0;
`;
const ActionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
`;
const ArrowButton = styled(Button)`
  ${({ theme }) => `
  min-width: 40px;
  height: 44px;
  background-color: white;
  border: 0.5px solid ${theme.palette.primary.main};
  color:${theme.palette.primary.main};
  `}
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid ${theme.palette.gray};
  color: ${theme.palette.darkGray};
  `}
`;
export default LoanRelease;
