import { saveAs } from 'file-saver';
import columns from '../../excel/answersSpecificColumns';
import { surveyInstance } from '..';

const createAnswersExcelFile = async (survey, surveyId, setIsCreating) => {
  setIsCreating(true);

  const newColumns = [...columns];

  survey?.questions?.forEach(({ name }, i) => {
    newColumns.push({ header: `${i + 1}. ${name}`, key: name });
  });

  await surveyInstance
    .post('/survey/generateExcelAnswers', { columns: newColumns, id: surveyId }, { timeout: 0, responseType: 'blob' })
    .then((response) => {
      setIsCreating(false);
      saveAs(response.data, `Anketa_${survey._id}.xlsx`);
    });
};

export default createAnswersExcelFile;
