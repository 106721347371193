import { Form, Input, RadioButton, Select } from '@components/rhf/src/MUI5';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AiFillBank, AiOutlineClose, AiOutlinePlus, AiOutlineUser } from 'react-icons/ai';
import { useMutation, useQuery } from 'react-query';
import { CRKRequest, fetchCRKRegistry, getCRK, getCRKDocuments, getCRKDocumentsData, getKomitentSrodstvo } from '../api';
import { CRKMikraDetails, ServerError, Spinner } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { comitentTypeFromJMBG, getFirstLine } from '../utils';

const CRKMikraApplication = ({ user, applicationId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { officerUsername } = useOfficerProfile();
  const theme = useTheme();

  let comitentType;
  const [crkSelected, setCrkSelected] = useState();
  const [openRequestDrawer, setOpenRequestDrawer] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const [disableCrkDocument, setDisableCrkDocument] = useState(true);
  const [crkDocuments, setCrkDocuments] = useState([]);

  const { data: CRKdata, error: CRKerror, isLoading: CRKisLoading } = useQuery(['getCRK', user.komitent], () =>
    getCRK(user.komitent)
  );

  const { data: registriData, error: registriError, isLoading: registriIsLoading } = useQuery(
    'CRKregistry',
    fetchCRKRegistry
  );

  const clientAffinity = useQuery('komitentSrodstvo', getKomitentSrodstvo);

  const methods = useForm({
    defaultValues: {
      clientName: user.ime + ' ' + user.prezime,
      crkForJMB: user.komitent,
      crkDocument: null,
      affinity: null,
    },
    mode: 'onBlur',
  });

  const crkDocumentWatch = useWatch({
    control: methods.control,
    name: 'crkDocument',
    defaultValue: null,
  });
  
  const typeWatch = useWatch({
    control: methods.control,
    name: 'type',
    defaultValue: null,
  });

  const crkForJMBWatch = useWatch({
    control: methods.control,
    name: 'crkForJMB',
    defaultValue: user.komitent,
  });

  const { data: CRKDocumentsData, error: CRKDocumentsError, isLoading: CRKDokucmentsIsLoading } = useQuery({
    queryKey: [`getCRKDocuments ${crkForJMBWatch} ${typeWatch}`],
    queryFn: () => getCRKDocuments(crkForJMBWatch, typeWatch),
    enabled: !!crkForJMBWatch && !!typeWatch,
    onSuccess: (data) => {
      setCrkDocuments(data)
      if (data.length === 0) setDisableCrkDocument(true)
    },
  });


  const { data: CRKData, error: CRKDataError, isLoading: CRKDataIsLoading } = useQuery({
    queryKey: [`getCRKDocumentsData ${crkDocumentWatch} `],
    queryFn: () => getCRKDocumentsData(crkDocumentWatch),
    enabled: !disableCrkDocument && !!CRKDocumentsData && CRKDocumentsData.length !== 0 && !!crkDocumentWatch,
  });

  useEffect(() => {
    if (!!crkDocumentWatch && !!CRKData) {
      methods.setValue('clientName', CRKData[0].clientName);
      methods.setValue('crkForJMB', CRKData[0].crkForJMB);
      methods.setValue('type', CRKData[0].type);
      setDisableInputs(true);
    }
  }, [crkDocumentWatch, CRKData]);

  useEffect(() => {
    if (!!typeWatch && !!crkForJMBWatch) {
      setDisableCrkDocument(false);
    }
  }, [typeWatch, crkForJMBWatch]);

  const { mutate } = useMutation(CRKRequest, {
    onSuccess: () => {
      setOpenRequestDrawer(false);
      enqueueSnackbar('Uspješno poslan zahtjev za CRK izvještaj.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const getZaduzenjeZirant = (dataRow) => {
    for (let i = 0; i < registriData.zaduzenjeZirant.length; i++) {
      if (registriData.zaduzenjeZirant[i].value == dataRow.zaduzenjeZirant) return registriData.zaduzenjeZirant[i].text;
    }
    return null;
  };
  const getPodtipZaduzenja = (dataRow) => {
    for (let i = 0; i < registriData.podtipZaduzenja.length; i++) {
      if (registriData.podtipZaduzenja[i].value == dataRow.podtipZaduzenja) return registriData.podtipZaduzenja[i].text;
    }
    return null;
  };
  const renderAvatar = (dataRow) => {
    if (dataRow.vrstaKomitenta == 'F' && dataRow.spol == 'Z')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.primary.main} />
        </AvatarOutline>
      );
    if (dataRow.vrstaKomitenta == 'F')
      return (
        <AvatarOutline variant="rounded">
          <AiOutlineUser size={32} color={theme.palette.secondary.main} />
        </AvatarOutline>
      );
    return (
      <AvatarOutline variant="rounded">
        <AiFillBank size={32} color={theme.palette.text.placeholder} />
      </AvatarOutline>
    );
  };
  const renderDelikvent = (dataRow) => {
    if (dataRow.delikvent == 'D' || dataRow.delikvent == 1) return <AvatarDelikvent>D</AvatarDelikvent>;
  };
  if (user) {
    comitentType = comitentTypeFromJMBG(user.komitent);
    if (comitentType == 'P') comitentType = 'BP';
    else comitentType = 'BF';
  }
  const handleSubmit = () => {
    let values = methods.getValues();
    mutate({...values, brZahtjeva: applicationId, officerUsername });
    methods.reset();
    setDisableInputs(false);
    setDisableCrkDocument(true);
  };
  return (
    <Container>
      <Box display="flex" flexDirection="row">
        <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
          <Typography variant="h5">Podaci iz CRK registra</Typography>
        </Box>
        <Box>
          <AddButton onClick={() => setOpenRequestDrawer(true)}>
            <AiOutlinePlus size={30} />
          </AddButton>
        </Box>
      </Box>
      {(CRKisLoading || registriIsLoading) && <Spinner />}
      {(CRKerror || registriError) && <ServerError error={CRKerror || registriError} />}
      {CRKdata &&
        registriData &&
        CRKdata.map((crkdata) => (
          <>
            <Box ml={2}>
              <ListItem
                button
                key={crkdata.id}
                onClick={() => {
                  setOpenDetailsDrawer(true);
                  setCrkSelected(crkdata);
                }}
              >
                <ListItemAvatar>
                  <div>
                    {renderAvatar(user)}
                    {renderDelikvent(user)}
                  </div>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.ime} ${user.otac == null ? '' : `(${user.otac})`} ${
                    user.prezime == null ? '' : user.prezime
                  }`}
                  secondary={`Datum provjere: ${new Date(crkdata.datumProvjere).toLocaleDateString('de-DE')}`}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
              {crkdata.zaduzenjeZirant && (
                <StyledChip size="small" label={getZaduzenjeZirant(crkdata)} color="primary"></StyledChip>
              )}
              {crkdata.podtipZaduzenja && (
                <StyledChip size="small" label={getPodtipZaduzenja(crkdata)} color="secondary"></StyledChip>
              )}
            </Box>
            <Box display="flex" flexDirection="column" m={2} mt={4} mb={0}>
              <Typography variant="body2">Naziv institucije</Typography>
              <BotText variant="subtitle1">{crkdata.sifraInstitucije || '-'}</BotText>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="100%" display="flex" flexDirection="column" m={2} ml={2} mt={0} bgcolor="background.paper">
                <Typography variant="body2">Datum odobrenja</Typography>
                <BotText variant="subtitle1">{new Date(crkdata.datumOdobrenja).toLocaleDateString('de-DE')}</BotText>
                <Typography variant="body2">Dozvoljeni limit</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.dozvoljeniLimit * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Iznos rate</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.iznosRate * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Tekuća klasifikacija</Typography>
                <BotText variant="subtitle1">{crkdata.tekucaKlasifikacija || '-'}</BotText>
                <Typography variant="body2">Iznos</Typography>
                <BotText variant="subtitle1" color={theme.palette.primary.main}>
                  {Math.round(crkdata.iznos * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" m={2} ml={2} mt={0} bgcolor="background.paper">
                <Typography variant="body2">Kraj otplate</Typography>
                <BotText variant="subtitle1">{new Date(crkdata.krajOtplate).toLocaleDateString('de-DE')}</BotText>
                <Typography variant="body2">Neiskorišteni limit</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.neiskoristeniLimit * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Glavnica u dugu</Typography>
                <BotText variant="subtitle1">
                  {Math.round(crkdata.glavnica * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
                <Typography variant="body2">Razlog gašenja</Typography>
                <BotText variant="subtitle1">{crkdata.razlogGasenja || '-'} </BotText>
                <Typography variant="body2">Ostatak duga</Typography>
                <BotText variant="subtitle1" color={theme.palette.darkGray}>
                  {Math.round(crkdata.ostatakDuga * 100 + Number.EPSILON) / 100 || '-'}
                </BotText>
              </Box>
            </Box>
            <DividerStyle />
          </>
        ))}
      {_.isEmpty(CRKdata) && <Typography variant="body2">Odabrani komitent nema CRK izvještaj</Typography>}
      <DrawerStyled onClose={() => setOpenRequestDrawer(false)} open={openRequestDrawer} anchor="bottom">
        <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
          <Box width="100px" />
          <Box display="flex" justifyContent="center" width="100%">
            <Typography color="white">Zahtjev za CRK</Typography>
          </Box>
          <HeaderIcon onClick={() => setOpenRequestDrawer(false)}>
            <AiOutlineClose size={20} />
          </HeaderIcon>
        </BoxHeader>
        <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Box>

            <Form spacing={4} methods={methods} inputProps={{}} onSubmit={handleSubmit}>
              <Input label="Korisnik" name="clientName" disabled={disableInputs} />
              <Input label="CRK za JMB" name="crkForJMB" type="number" disabled={disableInputs} />
              <Select label="Srodstvo" name="affinity" options={clientAffinity.data || []} md={12} />
              <RadioButton
                title="Tip izvještaja"
                name="type"
                control={methods}
                options={[
                  { value: '1', icon: comitentType + '1' },
                  { value: '2', icon: comitentType + '2' },
                ]}
                disabled={disableInputs}
              ></RadioButton>              
              <Select label="CRK dokument" name="crkDocument" options={crkDocuments || []} md={12} disabled={!!CRKData ? disableInputs : disableCrkDocument} />

            </Form>
            
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Pošalji zahtjev
            </Button>
          </Box>
        </Box>
      </DrawerStyled>
      <DrawerStyled onClose={() => setOpenDetailsDrawer(false)} open={openDetailsDrawer} anchor="bottom">
        <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
          <Box width="100px" />
          <Box display="flex" justifyContent="center" width="100%">
            <Typography color="white">CRK Detalji</Typography>
          </Box>
          <HeaderIcon onClick={() => setOpenDetailsDrawer(false)}>
            <AiOutlineClose size={20} />
          </HeaderIcon>
        </BoxHeader>
        <CRKMikraDetails user={user} crkdata={crkSelected} registry={registriData} />
      </DrawerStyled>
    </Container>
  );
};
const Container = styled('div')`
  max-width: 500px;
  margin: auto;
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.lightGray};
    float:left;
    height:50px;
    width:50px;
    border-radius:8px;
    margin-right:10px;
    margin-top:7px;
    `}
`;
const AvatarDelikvent = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.primary.main};
    position:relative;
    float:left;
    margin-left:-30px;
    top:35px;
    height:22px;
    width:22px;
    z-index:2;
    font-size:1rem;
    `}
`;
const BotText = styled(Typography)`
  margin-bottom: 15px;
`;
const DividerStyle = styled(Divider)`
  ${({ theme }) => `
  margin:8px;
  margin-top:16px;
  background-color:${theme.palette.lightGray};
`}
`;
const StyledChip = styled(Chip)`
  margin-right: 4px;
  margin-top: -16px;
  font-size: 0.75rem;
  height: 16px;
`;
const AddButton = styled(Button)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main};
  color: white;
  border-radius: 100%;
  height:40px;
  width:40px;
  padding:0;
  min-width:20px;
  `}
`;
const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    height: 100%;
  }
`;
const BoxHeader = styled(Box)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main};
  border-radius: 40px 40px 0px 0px;
  `}
`;
const HeaderIcon = styled(Button)`
  color: white;
  padding: 0;
`;
export default CRKMikraApplication;
