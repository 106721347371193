import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getKreditniSluzbenici } from '../api';

const EmployeeSearchBar = ({ employeeSelect, employee, placeholder }) => {
  const { data, error, isLoading } = useQuery('empoloyeesList', getKreditniSluzbenici);

  return (
    <AutocompleteBox>
      {error || isLoading ? (
        <Skeleton width="100%" height="76px" />
      ) : (
        <Autocomplete
          popupIcon={<AiOutlineSearch size={20} />}
          options={data || []}
          //onClose={() => employeeSelect(null)}
          defaultValue={data?.find((item) => item.value === employee) || null}
          getOptionLabel={(option) => option?.text}
          renderInput={(params) => (
            <TextField
              sx={{ bgcolor: 'white' }}
              {...params}
              placeholder={placeholder}
              margin="normal"
              variant="outlined"
            />
          )}
          onChange={(event, s) => employeeSelect(s?.value)}
          clearOnEscape={true}
        />
      )}
    </AutocompleteBox>
  );
};
const AutocompleteBox = styled(Box)`
  & .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
`;
export default EmployeeSearchBar;
