import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { Form, Input, PhoneInput, RadioButton, Select } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { loanOfficerSchema } from '@mikra/validation';
import { Box, Button, CircularProgress } from '@mui/material';
import { addKomitent, fetchRegistry } from '../api';
import { ServerError } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import Layout from '../layout';
import { comitentTypeFromJMBG, genderFromJMBG, getFirstLine } from '../utils';

const ClientCreateMikra = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirect, setRedirect] = useState(false);
  const { officerSifra } = useOfficerProfile();

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(loanOfficerSchema),
    mode: 'onBlur',
  });
  const { isSubmitting } = methods.formState;

  const registry = useQuery('registry', fetchRegistry);

  const { mutateAsync: mutateAdd } = useMutation(addKomitent, {
    onSuccess: (res) => {
      enqueueSnackbar('Uspješno ste kreirali komitenta.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRedirect(true);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const handleSubmit = (values) => {
    values.createdBy = officerSifra;
    values.type = comitentTypeFromJMBG(values.jmbg);
    if (values.type == 'F') {
      values.gender = genderFromJMBG(values.jmbg);
    } else values.gender = null;
    return mutateAdd(values);
  };

  const control = useForm({});
  if (redirect) return <Redirect to="/clients" />;
  return (
    <Layout
      topBarProps={{
        backLink: '/',
        text: 'Novi komitent',
      }}
    >
      {registry.error && <ServerError error={registry.error} />}

      {registry.data && (
        <Box pl={4} pr={4}>
          <Box height="8px" />
          <Form spacing={4} methods={methods} onSubmit={handleSubmit} inputProps={{}}>
            <Input label="JMBG / JIB" name="jmbg" type="number" />
            <Input label="Ime" name="firstName" xs={6} />
            <Input label="Prezime" name="lastName" xs={6} />
            <Input label="Ime roditelja" name="fathersName" md={6} />
            <Input label="Broj LK" name="lk" md={6} />
            <Input label="Adresa" name="address" md={6} />
            <Select label="Grad" name="municipality" options={registry.data.cities || []} md={6} />
            <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
            <PhoneInput label="Fiksni telefon (opcionalno)" name="landlinePhone" type="tel" md={6} />
            <RadioButton
              title="Biznis registrovan"
              name="biznis"
              control={control}
              options={[
                { value: '1', icon: 'Da' },
                { value: '2', icon: 'Ne' },
                { value: '3', icon: 'Bez biznisa' },
              ]}
            ></RadioButton>
            <Input label="Napomena" name="remark" md={12} />
            <Button
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} />}
              variant="contained"
              color="primary"
              type="submit"
            >
              Kreiraj komitenta
            </Button>
          </Form>
        </Box>
      )}
    </Layout>
  );
};

export default ClientCreateMikra;
