import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Checkbox, Form, Input, PhoneInput, Select } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress } from '@mui/material';
import { loanOfficerSchemaLoose } from '@partner/validation';
import { getMjesnaZajednicaByOpcina } from '../api';
import Accounts from './Accounts';
import { DatePicker } from '.';

const ClientEditFormPartner = ({
  handleSubmit,
  registry,
  client,
  izvori,
  datumIsteka,
  setDatumIsteka,
  handleAccountSubmit,
}) => {
  let grad;
  grad = client.opstina;

  const statusiZaposlenja = [
    { value: 'Neodređeno', text: 'Neodređeno' },
    { value: 'Nezaposlen', text: 'Nezaposlen' },
    { value: 'Određeno', text: 'Određeno' },
    { value: 'Povremeno', text: 'Povremeno' },
    { value: 'Sezonski', text: 'Sezonski' },
  ];
  const getOpcinaCipsValue = (sifra) => {
    for (let i = 0; i < registry.municipalities.length; i++) {
      if (sifra == registry.municipalities[i].text) {
        return registry.municipalities[i].value;
      }
    }
    return null;
  };
  const methods = useForm({
    defaultValues: {
      jmbg: client.komitent,
      firstName: client.ime,
      lastName: client.prezime,
      fathersName: client.otac,
      lk: client.lk,
      address: client.adresa,
      municipality: grad || '',
      mobilePhone: client.telefon,
      landlinePhone: client.telefonFix,
      remark: client.napomena,
      biznis: client.biznisRegistrovan,
      income: client.izvorPrihoda || '',
      employmentStatus: client.statusZaposlenja || '',
      martialStatus: client.statusKomitenta || '',
      cipsAddress: client.adresaCips,
      cipsMunicipality: getOpcinaCipsValue(client.opcinaCips),
      localCommunity: client.mjestoStanovanja || '',
      equaladdresses:
        grad == getOpcinaCipsValue(client.opcinaCips) && client.adresa == client.adresaCips ? true : false,
    },
    resolver: yupResolver(loanOfficerSchemaLoose),
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const equalAddressesWatch = useWatch({
    control: methods.control,
    name: 'equaladdresses',
    defaultValue: getOpcinaCipsValue(client.opcinaCips) && client.adresa == client.adresaCips ? true : false,
  });

  const cityWatch = useWatch({
    control: methods.control,
    name: 'municipality',
    defaultValue: grad || -1,
  });
  const prostori = useQuery(['prostori', cityWatch], () => getMjesnaZajednicaByOpcina(cityWatch), {
    enabled: !!cityWatch,
  });
    if (equalAddressesWatch &&  methods.getValues('address') != methods.getValues("cipsAddress") ) {
    methods.setValue('cipsAddress', methods.getValues('address'));
    methods.setValue('cipsMunicipality', methods.getValues('municipality'));
  }
  return (
    <Form spacing={4} methods={methods} onSubmit={handleSubmit} inputProps={{}}>
      <Input label="JMBG / JIB" name="jmbg" type="number" />
      <Input label="Ime" name="firstName" xs={6} />
      <Input label="Prezime" name="lastName" xs={6} />
      <Input label="Ime roditelja" name="fathersName" md={6} />
      <Input label="Broj LK" name="lk" md={6} />
      <DatePicker datum={datumIsteka} setDatum={setDatumIsteka} label="Datum isteka važenja lične karte" />
      <Select label="Bračni status" name="martialStatus" options={registry ? registry.martialStatus : []} md={6} />
      <Select label="Izvor prihoda" name="income" options={izvori || []} md={12} />
      <Select label="Status zaposlenja" name="employmentStatus" options={statusiZaposlenja} md={12} />
      <Checkbox label="Adresa = Adresa cips" name="equaladdresses" />
      <Input label="Adresa" name="address" md={6} />
      {!equalAddressesWatch ? <Input label="Adresa cips" name="cipsAddress" md={6} /> : <></>}
      <Select label="Grad" name="municipality" options={registry ? registry.municipalities : []} md={12} />
      {!equalAddressesWatch ? (
        <Select label="Grad cips" name="cipsMunicipality" options={registry ? registry.municipalities : []} md={6} />
      ) : (
        <></>
      )}
      <Select label="Mjesto stanovanja" name="localCommunity" options={prostori.data || []} md={6} />
      <PhoneInput label="Mobilni telefon" name="mobilePhone" type="tel" md={6} />
      <PhoneInput label="Fiksni telefon (opcionalno)" name="landlinePhone" type="tel" md={6} />
      <Input label="Napomena" name="remark" md={12} />
      <Accounts handleChange={handleAccountSubmit} clientId={client.IdKomitent} />
      <Button
        disabled={isSubmitting}
        startIcon={isSubmitting && <CircularProgress size={20} />}
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
      >
        Spremi promjene
      </Button>
    </Form>
  );
};
export default ClientEditFormPartner;
