import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, InputAdornment, Slider as MuiSlider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const InputSlider = ({ name, control, label, error, unit = '', step = 1, max = 10000, min = 1, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box display="flex" flexDirection="column">
            <Box m="auto" mb={1}>
              <Typography variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
            </Box>
            <StyledInput
              {...field}
              variant="outlined"
              error={!!error}
              onChange={(e) => {
                const value = e.target.value === '' ? '' : Number(e.target.value);
                field.onChange(value);
              }}
              InputProps={{
                step,
                min,
                max,
                type: 'number',
                endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                disabled: disabled,
              }}
            />
            <StyledSlider
              {...field}
              value={typeof field.value === 'number' ? field.value : 0}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              valueLabelDisplay="off"
              min={min}
              max={max}
              step={step}
              disabled={disabled}
            />
          </Box>
        );
      }}
    />
  );
};
const StyledSlider = styled(MuiSlider)`
  ${({ theme }) => `
  width: 600px;
  ${theme.breakpoints.down('sm')} {
      width: 85vw;
  }
  position: relative;
  margin-top: 24px;
  margin-bottom: 8px;

  .MuiSlider-root {
    color: ${theme.palette.primary.main};
    height: 8px;
  }
  .MuiSlider-thumb {
    height: 28px;
    width: 28px;
    margin-top: -2px;
    margin-left: 0px;
    background-color: ${theme.palette.secondary.main};
    &:focus, &:hover, &:active {
      box-shadow: inherit;
    }
  }
  .Mui-disabled{
    background-color:${theme.palette.gray};
  }
  .MuiSlider-track {
    height: 6px;
    borderRadius: 5px;
  }
  .MuiSlider-rail {
    height: 6px;
    borderRadius: 5px;
    background-color:  ${theme.palette.gray};
  }
   `}
`;

const StyledInput = styled(TextField)`
  font-size: 24px;
  text-align: center;

  .MuiInputBase-root {
    font-size: 24px;
  }

  .MuiInputBase-input {
    padding: 10px 14px;
    text-align: center;
  }

  .MuiInputAdornment-root {
    margin-left: -32px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-left: -24px;
    }
  }
`;

export default InputSlider;
