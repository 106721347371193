import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FaStream } from 'react-icons/fa';
import {
  MdAssignment,
  MdAssignmentInd,
  MdBook,
  MdDescription,
  MdFactCheck
} from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  fetchKomitentById,
  fetchRegistry,
  statusOdustao,
  statusStorniran,
  stepsCompleted,
  stepsDisabled,
  stepsUnlocked,
} from '../api';
import {
  AnalysisLider,
  ApplicationDocumentsLider,
  ApplicationDrawer,
  CodebtorsLider,
  LoanFormLider,
  ServerError,
  Spinner,
} from '../components';
import Segmentation from '../components/Segmentation';
import useOfficerProfile from '../hooks/useOfficerProfile';
import ApplicationLayout from '../layout/ApplicationLayout';
import { getFirstLine, scrollToTop } from '../utils';

const LoanApplicationLider = ({ brojZahtjeva, defaultClient }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [cancelledOpen, setCancelledOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [brZahtjeva, setBrZahtjeva] = useState(brojZahtjeva);
  const [cancelStatusStorniran, setCancelStatusStorniran] = useState(true);
  const icons = [
    <MdBook size={26} />,
    <MdFactCheck size={26} />,
    <MdDescription size={26} />,
    <MdAssignmentInd size={26} />,
    <MdAssignment size={26} />,
  ];

  const stepLabels = [
    'Segmentacija', 
    'Osnovni podaci',
    'Dokumenti',
    'Sudužnici',
    'Analiza',
  ];
  const registry = useQuery('registry', fetchRegistry);
  const clientInfo = useQuery(
    ['fetchKomitentById', defaultClient.IdKomitent],
    () => fetchKomitentById(defaultClient.IdKomitent),
    {
      enabled: !!defaultClient,
    }
  );
  const stepsDisabledQuery = useQuery(['stepsDisabled', brZahtjeva], () => stepsDisabled(brZahtjeva));
  const stepsCompletedQuery = useQuery(['stepsCompleted', brZahtjeva], () => stepsCompleted(brZahtjeva));
  const stepsUnlockedQuery = useQuery(['stepsUnlocked', brZahtjeva], () => stepsUnlocked(brZahtjeva));
  //Ako se otvori novi klijent, vec su proslijedjeni podaci, u suprotnom uzmi iz querija
  // console.log(clientInfo.data);
  let client;
  if (clientInfo.data) {
    client = clientInfo.data[0];
  } else {
    client = defaultClient;
  }
  const { officerUsername } = useOfficerProfile();

  const { mutate: mutateStatusStorniran } = useMutation(statusStorniran, {
    onSuccess: (res) => {
      queryInvalidation();
      enqueueSnackbar('Uspješno ste promijenili status zahtjeva.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const { mutate: mutateStatusOdustao } = useMutation(statusOdustao, {
    onSuccess: (res) => {
      queryInvalidation();
      enqueueSnackbar('Uspješno ste promijenili status zahtjeva.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
  });

  const changeStep = (step) => {
    if (step != 0 && !stepsUnlockedQuery.data[step] && stepsDisabledQuery.data[step - 1]) return;
    scrollToTop();
    queryInvalidation();
    setStepNumber(step);
  };

  const queryInvalidation = () => {
    setOpen(false);
    queryClient.invalidateQueries(['stepsCompleted', brZahtjeva]);
    queryClient.invalidateQueries(['stepsDisabled', brZahtjeva]);
    queryClient.invalidateQueries(['stepsUnlocked', brZahtjeva]);
  };

  const handleCancel = () => {
    if (cancelStatusStorniran) mutateStatusStorniran({ brojZahtjeva: brZahtjeva, modifiedBy: officerUsername });
    else mutateStatusOdustao({ brojZahtjeva: brZahtjeva, modifiedBy: officerUsername });
    setCancelledOpen(false);
  };

  const errorGroup =
    registry.error ||
    clientInfo.error ||
    stepsCompletedQuery.error ||
    stepsDisabledQuery.error ||
    stepsUnlockedQuery.error;
  const loadingGroup =
    registry.isLoading ||
    stepsCompletedQuery.isLoading ||
    stepsDisabledQuery.isLoading ||
    stepsUnlockedQuery.isLoading ||
    clientInfo.isLoading;
  const noError =
    registry.data && clientInfo.data && stepsCompletedQuery.data && stepsDisabledQuery.data && stepsUnlockedQuery.data;

  const Icon = (value) => {
    return (
      <Box
        p={1}
        display="flex"
        alignItems="center"
        sx={{
          border: '1px solid',
          borderColor: value.color,
          borderRadius: '8px',
        }}
      >
        <ListItem button>
          <Box display="flex" width="100%" alignItems="center">
            <ListItemText
              primary={value.label}
              primaryTypographyProps={{ variant: 'subtitle2', color: value.color, align: 'center' }}
            ></ListItemText>
          </Box>
        </ListItem>
      </Box>
    );
  };

  return (
    <ApplicationLayout
      topBarProps={{
        backLink: { pathname: '/' },
        text: 'Zahtjev za kredit',
        setOpen: setCancelledOpen,
      }}
    >
      <Box mb={5}>
        <LinearProgress
          sx={{ height: '8px' }}
          variant="determinate"
          value={((stepNumber + 1) / stepLabels.length) * 100}
          color="secondary"
        />
      </Box>
      <Box mb={10} pr={4} pl={4}>
        <ListItemStyled onClick={() => setOpen(true)} id="stepHeader">
          <AvatarOut>
            <AvatarIn>{icons[stepNumber]}</AvatarIn>
          </AvatarOut>
          <ListItemText
            primary={`KORAK ${stepNumber + 1}/${stepLabels.length}`}
            secondary={stepLabels[stepNumber]}
            primaryTypographyProps={{ variant: 'caption' }}
            secondaryTypographyProps={{ variant: 'h6' }}
          />
          <Box pr={4} sx={{ color: `${theme.palette.primary.main}70` }}>
            <FaStream />
          </Box>
        </ListItemStyled>
        {errorGroup && <ServerError error={errorGroup} />}
        {loadingGroup && <Spinner />}
        {noError && (
          <Box>    
            {stepNumber === 0 && (
            <Segmentation
              registry={registry.data}
              IdKomitent={defaultClient.IdKomitent}
              next={() => changeStep(1)}
              brojZahtjeva={brZahtjeva}
              setBrZahtjeva={setBrZahtjeva}
              disabled={stepsDisabledQuery.data[0]}
            />
          )}
            {stepNumber === 1 && (
              <LoanFormLider
                registry={registry.data}
                IdKomitent={defaultClient.IdKomitent}
                previous={() => changeStep(0)}
                next={() => changeStep(2)}
                brojZahtjeva={brZahtjeva}
                setBrZahtjeva={setBrZahtjeva}
                disabled={stepsDisabledQuery.data[0]}
              />
            )}
            {stepNumber === 2 && (
              <ApplicationDocumentsLider
                applicationId={brZahtjeva}
                previous={() => changeStep(1)}
                next={() => changeStep(3)}
                disabled={stepsDisabledQuery.data[1]}
              />
            )}
            {stepNumber === 3 && (
              <CodebtorsLider
                brojZahtjeva={brZahtjeva}
                previous={() => changeStep(2)}
                next={() => changeStep(4)}
                disabled={stepsDisabledQuery.data[2]}
              />
            )}
            {stepNumber === 4 && (
              <AnalysisLider
                brojZahtjeva={brZahtjeva}
                komitentId={defaultClient.IdKomitent}
                previous={() => changeStep(3)}
                next={() => changeStep(5)}
                disabled={stepsDisabledQuery.data[3]}
              />
            )}

            <ApplicationDrawer
              open={open}
              setOpen={setOpen}
              stepLabels={stepLabels}
              icons={icons}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
              stepsCompletedQuery={stepsCompletedQuery}
              stepsUnlockedQuery={stepsUnlockedQuery}
              client={client}
            />

            <Dialog open={cancelledOpen} onClose={() => setCancelledOpen(false)}>
              <DialogTitle>{'Otkaži zahtjev'}</DialogTitle>
              <DialogContent>
                <Box mb={5} display="flex" width="100%" justifyContent="center">
                  <Checkbox
                    icon={<Icon label="Storniraj zahtjev" borderColor={theme.palette.gray} />}
                    checkedIcon={
                      <Icon
                        label="Storniraj zahtjev"
                        borderColor={theme.palette.secondary.main}
                        color={theme.palette.secondary.main}
                      />
                    }
                    checked={cancelStatusStorniran}
                    onChange={() => setCancelStatusStorniran(true)}
                  />
                  <Checkbox
                    icon={<Icon label="Komitent odustao" borderColor={theme.palette.gray} />}
                    checkedIcon={
                      <Icon
                        label="Komitent odustao"
                        borderColor={theme.palette.secondary.main}
                        color={theme.palette.secondary.main}
                      />
                    }
                    checked={!cancelStatusStorniran}
                    onChange={() => setCancelStatusStorniran(false)}
                  />
                </Box>
                <DialogContentText>Postaviti zahtjev u status storniran/odustao?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>Da</Button>
                <Button onClick={() => setCancelledOpen(false)} autoFocus>
                  Ne
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>
    </ApplicationLayout>
  );
};
const ListItemStyled = styled(ListItem)`
  ${({ theme }) => `
  background-color: ${theme.palette.background.secondary};
  margin-bottom: 30px;
  padding-left: 20px;
  padding-bottom:16px;
  padding-top:16px;
  border-radius: 10px;
  `}
`;
const AvatarIn = styled(Avatar)`
  ${({ theme }) => `
  background-color: ${theme.palette.primary.main};
  height: 36px;
  width: 36px;
  `}
`;
const AvatarOut = styled(Avatar)`
  ${({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  height: '40px';
  width: '40px';
  background-color: transparent;
  margin-right:16px;
  `}
`;
export default LoanApplicationLider;
