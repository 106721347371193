import React, { useState } from 'react';
import { Tooltip, Typography, Box, styled } from '@mui/material';
import './Marker.css';

const Marker = (props) => {
  const { color, item, type } = props;
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen((o) => !o);
  };

  return (
    <Wrapper>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        key={toString(item?.id) + 'tt'}
        title={
          <React.Fragment>
            <CustomPopper>
              <Typography noWrap variant="h6" align="center">
                {type}
              </Typography>
              <Typography>
                {item?.firstName} {item?.lastName}
              </Typography>
              <Typography>{item?.time}</Typography>
            </CustomPopper>
          </React.Fragment>
        }
      >
        <div onClick={handleTooltipOpen} key={toString(item?.id) + 'fr'}>
          <div
            className="pin bounce"
            style={{ backgroundColor: color, cursor: 'pointer' }}
            key={toString(item?.id) + 'th'}
          ></div>
          <div className="pulse" />
        </div>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  & .MuiTooltip-tooltip {
    padding: 0;
  }
`;

const CustomPopper = styled(Box)`
  ${({ theme }) => `
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: 2px solid ${theme.palette.text.link};
  border-radius: 6px;
  padding: 4px;
  `}
`;

export default Marker;
