import React, { useState,useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from './Routes';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Normalize } from 'styled-normalize';
import moment from 'moment';
import localization from 'moment/locale/bs';
import theme from './theme';
import 'react-image-gallery/styles/css/image-gallery.css';
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/open-sans';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import NavContext from './hooks/NavContext';
import keycloak from './keycloak';
import Spinner from './components/Spinner';
import LocationTracker from './components/LocationTracker';

//
moment.updateLocale('bs', localization);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});


const App = () => {
  const [select, changeSelect] = useState(0);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  },[]);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      LoadingComponent={<Spinner />}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <NavContext.Provider value={{ select, changeSelect }}>
              <Normalize />
              <LocationTracker />
              <SnackbarProvider maxSnack={3} autoHideDuration={10000}>
                <Router>
                  <Routes />
                </Router>
              </SnackbarProvider>
            </NavContext.Provider>
          </StylesProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
