import { surveyInstance } from '..';

const getAllAnswers = async (isAdmin, officerId, params) => {
  const { data } = isAdmin
    ? await surveyInstance('/answers/all', { params })
    : await surveyInstance(`/answers/user/${officerId}`, { params });
  return data;
};

export default getAllAnswers;
