import selectClient from '../../hooks/selectClient';
import liderPalette from '../liderPalette';
import mikraPalette from '../mikraPalette';
import partnerPalette from '../partnerPalette';

let palette;
if (selectClient() == 'Partner') {
  palette = partnerPalette;
} else if (selectClient() == 'Mikra') {
  palette = mikraPalette;
} else if (selectClient() == 'Lider') {
  palette = liderPalette;
}

const MuiDivider = {
  styleOverrides: {
    root: {
      backgroundColor: palette.lightGray,
      borderColor: palette.lightGray,
      height: '1px',
    },
  },
};

export default MuiDivider;
