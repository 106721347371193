import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Input, RadioButton } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { loanOfficerSchemaAnalysis } from '@partner/validation';
import { editAnalysis, getAnalysis, insertAnalysis } from '../api';
import { getFirstLine } from '../utils';
import { ActionsWrapper, ServerError, Spinner } from '.';

const AnalysisPartner = ({ brojZahtjeva, komitentId, previous, next, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: mutateAdd } = useMutation(insertAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno unesena analiza.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateEdit } = useMutation(editAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno sačuvane promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const methods = useForm({
    resolver: yupResolver(loanOfficerSchemaAnalysis),
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const analysis = useQuery(['getAnaliza', brojZahtjeva], () => getAnalysis(brojZahtjeva), {
    enabled: !!brojZahtjeva,
    onSuccess: (res) => {
      methods.reset(res);
    },
  });

  const handleSubmit = (values) => {
    if (!values.clientLoan || !values.householdLoan) {
      enqueueSnackbar('Molimo odaberite potrebne opcije.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    //Provjera Edit/Submit/Next
    if (!_.isEqual(analysis.data, values)) {
      values.brojZahtjeva = brojZahtjeva;
      values.komitentId = komitentId;
      if (!analysis.data) return mutateAdd(values);
      else return mutateEdit(values);
    }
    next();
  };

  return (
    <Box>
      {analysis.error && <ServerError error={analysis.error} />}
      {analysis.isLoading && <Spinner />}
      {!analysis.error && !analysis.isLoading && (
        <Form
          spacing={4}
          methods={methods}
          onSubmit={disabled ? next : handleSubmit}
          inputProps={{ disabled: disabled }}
        >
          <RadioButton
            title="Klijent ima kredit u banci"
            name="clientLoan"
            control={methods}
            options={[
              { value: 'D', icon: 'Da' },
              { value: 'N', icon: 'Ne' },
            ]}
          ></RadioButton>
          <RadioButton
            title="Domaćinstvo ima kredit u banci"
            name="householdLoan"
            control={methods}
            options={[
              { value: 'D', icon: 'Da' },
              { value: 'N', icon: 'Ne' },
            ]}
          ></RadioButton>
          <Input label="Iznos dokazivih prihoda korisnika" name="provableClientIncome" md={6} type="number" />
          <Input label="Iznos nedokazivih prihoda korisnika" name="unprovableClientIncome" md={6} type="number" />
          <Input label="Iznos dokazivih prihoda domaćinstva" name="provableHouseholdIncome" md={6} type="number" />
          <Input label="Iznos nedokazivih prihoda domaćinstva" name="unprovableHouseholdIncome" md={6} type="number" />
          <Input label="Ukupan prihod domaćinstva" name="totalHouseholdIncome" md={6} type="number" />
          <Input label="Ukupni troškovi domaćinstva" name="totalHouseholdCosts" md={6} type="number" />
          <Input label="Ukupne kreditne obaveze domaćinstva" name="totalHouseholdLoan" md={6} type="number" />
          <Box height="16px"></Box>
          <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label="Sljedeći korak" />
        </Form>
      )}
    </Box>
  );
};

export default AnalysisPartner;
