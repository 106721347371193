import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Input } from '@components/rhf/src/MUI5';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  styled,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IoTrashOutline } from 'react-icons/io5';
import { useMutation, useQueryClient } from 'react-query';
import { getFirstLine } from '../utils';
import { deleteAnalysis } from '../api';

const AnalysisCodebtorPersonal = ({ analysis, suduznik, brojZahtjeva, next, mutateEdit, mutateAdd }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const methodsFizickoLiceSuduznik = useForm({
    defaultValues: analysis,
  });
  const queryClient = useQueryClient();

  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    values.komitent = suduznik.suduznik;
    //Neophodno porediti polje po polje?
    if (
      !(
        values.description == analysis.description &&
        values.otherLoanInstallments == analysis.otherLoanInstallments &&
        values.value == analysis.value &&
        values.documentedIncome == analysis.documentedIncome &&
        values.statementIncome == analysis.statementIncome
      )
    ) {
      return mutateEdit(values);
    } else {
      enqueueSnackbar('Nije došlo do promjena u analizi.', {
        variant: 'warning',
        autoHideDuration: 1000,
      });
    }
  };

  const { mutate: mutateDelete } = useMutation(deleteAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno obrisana stavka analize.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
    },
  });
  const addNewAnalysis = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: suduznik.suduznik };
    mutateAdd(values);
  };
  const deleteAnalysisF = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: suduznik.suduznik };
    mutateDelete(values);
  };
  const { isSubmitting } = methodsFizickoLiceSuduznik.formState;
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography mb={3} variant="subtitle1" color="primary">
            Sudužnik: {(suduznik.name || '-') + ' (' + (suduznik.parent || '-') + ') ' + (suduznik.surname || '-')}
          </Typography>
        </Grid>
        {analysis && (
          <Grid item xs={2}>
            <IconButton size="large" onClick={() => setDeleteOpen(true)}>
              <IoTrashOutline size={20} id="trashIcon" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {!analysis ? (
        <Button
          //disabled={isSubmitting}
          //startIcon={isSubmitting && <CircularProgress size={20} />}
          onClick={() => addNewAnalysis()}
          variant="contained"
          color="primary"
          type="submit"
        >
          Dodaj analizu
        </Button>
      ) : (
        <Box>
          <Form spacing={3} methods={methodsFizickoLiceSuduznik} onSubmit={handleSubmit}>
            <Typography mb={3} variant="h6" color="primary">
              Ocjena kreditne sposobnosti sudužnika
            </Typography>
            <Grid container mb={3}>
              <Grid item xs={10}>
                <Typography mt={1} variant="body2">
                  Rata traženog kredita
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <BotText variant="subtitle1">
                  {Math.round(analysis.loanInstallment * 1000 + Number.EPSILON) / 1000 || '0'}
                </BotText>
              </Grid>
            </Grid>
            <Input label="Prihodi dokumentovano" name="documentedIncome" />
            <Input label="Prihodi izjava" name="statementIncome" />
            <Grid container mb={3}>
              <Grid item xs={10}>
                <Typography variant="body2">Prihodi ukupno</Typography>
              </Grid>

              <Grid item xs={2}>
                <BotText variant="subtitle1">
                  {Math.round(analysis.sumIncome * 1000 + Number.EPSILON) / 1000 || '0'}
                </BotText>
              </Grid>
            </Grid>
            <Input label="Ostale rate kredita" name="otherLoanInstallments" type="number" />
            <Grid container mb={3}>
              <Grid item xs={10}>
                <Typography variant="body2">Pokazatelj kreditne sposobnosti &ge; 1</Typography>
              </Grid>
              <Grid item xs={2}>
                <BotText variant="subtitle1">
                  {Math.round(analysis.creditworthiness * 1000 + Number.EPSILON) / 1000 || '0'}
                </BotText>
              </Grid>
            </Grid>
            <DividerStyle />

            <Typography mb={3} variant="h6" color="primary">
              Podaci o zalogu pokretne imovine
            </Typography>
            <Input label="Opis" multiline name="description" />
            <Input label="Vrijednost" name="value" />
            <Grid container mb={3}>
              <Grid item xs={10}>
                <Typography variant="body2">Pokazatelj pokrivenosti rate {'>'} 1.5</Typography>
              </Grid>
              <Grid item xs={2}>
                <BotText variant="subtitle1">
                  {Math.round(analysis.rateCoverageIndicator * 1000 + Number.EPSILON) / 1000 || '0'}
                </BotText>
              </Grid>
            </Grid>
            <Button onClick={() => handleSubmit} variant="contained" color="primary" type="submit">
              Spremi promjene
            </Button>
            <Box height="16px"></Box>
          </Form>
          <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
            <DialogTitle>{'Brisanje analize'}</DialogTitle>
            <DialogContent>
              <DialogContentText>Obrisati odabranu analizu?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteAnalysisF}>Da</Button>
              <Button onClick={() => setDeleteOpen(false)} autoFocus>
                Ne
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};
const BotText = styled(Typography)`
  margin-bottom: 12px;
`;
const DividerStyle = styled(Divider)`
  ${({ theme }) => `
  margin-bottom:12px;
  background-color:${theme.palette.darkGray};
`}
`;
export default AnalysisCodebtorPersonal;
