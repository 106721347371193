import { Form, Input, Select } from '@components/rhf/src/MUI5';
import { Box, ListItemText, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiFillIdcard } from 'react-icons/ai';
import { useMutation, useQuery } from 'react-query';
import { addLoanApplication, editLoanApplication, getApplication, getOrganizacijaRadnik, insertEvent } from '../api';
import { ActionsWrapper, InputNum, InputSlider, MatrixBadgeMikra } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { getFirstLine } from '../utils';
import getCoords from '../utils/getCoords';

const _ = require('lodash');

const LoanFormMikra = ({ next, registry, IdKomitent, brojZahtjeva, setBrZahtjeva, disabled, komitent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    officerSifra: idSluzbenik,
    officerUsername: usernameSluzbenik,
    officerOrganizacija,
    officerId,
  } = useOfficerProfile();
  const [preostaleOpcije, setPreostaleOpcije] = useState(true);
  const [rata, setRata] = useState(0);
  const [organizacija, setOrganizacija] = useState(null);
  let [promotions, setPromotions] = useState([]);
  const [application, setApplication] = useState({
    brojzahtjeva: -1,
    amount: 1000,
    gracePeriod: 0,
    repaymentPeriod: 10,
    loanProductType: '',
    loanProducts: '',
    remark: '',
    loanProduct: '',
    level2: '',
    level3: '',
    level4: '',
    level5: '',
    level6: '',
    level7: '',
    recommendedLoanPurpose: '',
    loanType: '',
    payoutType: '',
    action: '',
    interestRate: '',
  });

  const methods = useForm({
    defaultValues: application,
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;
  const theme = useTheme();

  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      if (!application.komitent) methods.reset(res);
      setApplication(res);
    },
  });

  useQuery(['getOrganizacijaRadnik', usernameSluzbenik], () => getOrganizacijaRadnik(usernameSluzbenik), {
    enabled: !!usernameSluzbenik,
    onSuccess: (res) => {
      setOrganizacija(res.organizacija);
    },
  });

  const { mutateAsync: mutateAddApplication } = useMutation(addLoanApplication, {
    onSuccess: async (a) => {
      enqueueSnackbar('Uspješno kreiran zahtjev', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      await getCoords().then((coords) => {
        insertEvent({
          lat: coords ? coords.latitude : null,
          lng: coords ? coords.longitude : null,
          alt: coords ? coords.altitude : null,
          docId: a?.data ? parseInt(a?.data) : -1,
          docType: 2, // 2 - ZAHTEV
          emp: officerId,
        });
      });

      setBrZahtjeva(a.data);
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditApplication } = useMutation(editLoanApplication, {
    onSuccess: async (a) => {
      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const interestRate = (interest) => {
    methods.setValue('interestRate', interest);
  };

  const handleSubmit = (values) => {
    if (!preostaleOpcije) {
      enqueueSnackbar('Podaci o zahtjevu nisu ispravni.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    let changes = !_.isEqual(values, application);

    values.organization = organizacija;
    values.komitent = IdKomitent;
    values.brojzahtjeva = brojZahtjeva;
    values.idSluzbenik = idSluzbenik;
    values.usernameSluzbenik = usernameSluzbenik;
    if (application.brojzahtjeva == -1) {
      return mutateAddApplication(values);
    } else if (changes) {
      return mutateEditApplication(values);
    } else next();
  };

  let clientName = `${komitent.ime} ${komitent.otac == null ? '' : `(${komitent.otac})`}
     ${komitent.prezime == null ? '' : komitent.prezime}`;

  return (
    <div>
      <Box mb={1}> 
        <ListItemText
          primary={clientName}
          secondary={
            <Box display="flex" flex-direction="row" mt={1}>
              <Box display="flex" justifyContent="center" mr={1}>
                <AiFillIdcard color={theme.palette.gray} size={18} />
              </Box>
              <Typography variant="body2">Broj zahtjeva: {brojZahtjeva}</Typography>
            </Box>
              }
            primaryTypographyProps={{ variant: 'subtitle1', noWrap: true }}
            secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
            />
        </Box>

        <Box mt={5}> {/* Add margin-top to create space between ListItemText and Form */}
          <Form spacing={4} methods={methods} onSubmit={disabled ? next : handleSubmit} inputProps={{ disabled: disabled }}>
            <InputSlider label="Iznos" unit="KM" name="amount" max={10000} min={500} step={100} xs={6} sm={6} md={6} lg={6} />
            <InputNum
              label="Broj rata"
              unit="mj."
              name="repaymentPeriod"
              max={360}
              min={1}
              step={1}
              xs={6}
              sm={6}
              md={6}
              lg={6}
            />
            <Box display="flex" alignItems="baseline" justifyContent="center" mb={8}>
              <Typography variant="body2" color="primary">
                Predložena rata kredita:&nbsp;
              </Typography>
              <Typography variant="h2" color="primary">
                {rata} KM
              </Typography>
            </Box>
            <Input label="Grace period" name="gracePeriod" md={6} type="number" />
            <Input label="Kamatna stopa" name="interestRate" type="number" />
            <Select label="Tip kreditnog proizvoda" name="loanProducts" options={registry ? registry.loanProducts : []} />
            <Select
              label="Djelatnost kredita"
              name="recommendedLoanPurpose"
              options={registry ? registry.loanPurposeActivities : []}
            />
            <Select label="Namjena korištenja" name="loanProduct" options={registry ? registry.loanPurposes : []} />
            <Select label="Vrsta kredita" name="loanType" options={registry ? registry.loanType : []} md={6} />
            <Select label="Vrsta isplate" name="payoutType" options={registry ? registry.payoutType : []} md={6} />
            <Select label="Akcija" name="action" options={promotions || []} />
            <Input label="Zabilješka" name="remark" md={12} />
            <Box height="16px" />
            <ActionsWrapper isSubmitting={isSubmitting} label="Sljedeći korak" />
            <MatrixBadgeMikra
              postojiOpcija={setPreostaleOpcije}
              methods={methods}
              setKamata={interestRate}
              setRata={setRata}
              komitent={komitent}
              promotions={promotions}
              setPromotions={setPromotions}
            ></MatrixBadgeMikra>
        </Form>
      </Box>
    </div>
  );
};
export default LoanFormMikra;
