import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Autocomplete, Box, CircularProgress, Skeleton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchKomitentById, searchKomitenti } from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';

const ClientSearchBarPartner = ({ checked, clientSelect, placeholder, idKomitent }) => {
  const { officerSifra, officerUsername } = useOfficerProfile();
  const [searchString, setSearchString] = useState('');
  const clients = useQuery(
    ['searchKomitenti', searchString.substring(0, 3), officerSifra, checked, officerUsername],
    () => searchKomitenti(searchString.substring(0, 3), officerSifra, checked, officerUsername),
    {
      enabled: searchString.length > 2,
    }
  );
  const defaultClient = useQuery(['fetchKomitentById', idKomitent], () => fetchKomitentById(idKomitent), {
    enabled: !!idKomitent,
  });
  return (
    <AutocompleteBox>
      {clients.error || defaultClient.error || defaultClient.isLoading ? (
        <Skeleton width="100%" height="76px" />
      ) : (
        <Autocomplete
          loading={clients.isLoading}
          loadingText="Pretražujem komitente..."
          onInputChange={(event, value) => setSearchString(value)}
          onClose={() => setSearchString('')}
          popupIcon={clients.isLoading ? <CircularProgress color="primary" size={20} /> : <AiOutlineSearch size={20} />}
          options={clients.data || []}
          defaultValue={defaultClient.data ? defaultClient.data[0] : null}
          getOptionLabel={(option) => `${option.ime} ${option.prezime} ${option.komitent} ${option.IdKomitent}`}
          renderInput={(params) => (
            <TextField
              sx={{ bgcolor: 'white' }}
              {...params}
              placeholder={placeholder}
              margin="normal"
              variant="outlined"
            />
          )}
          onChange={(event, value) => clientSelect(value)}
          clearOnEscape={true}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Typography>
                {option.ime} ({option.otac}) {option.prezime}
              </Typography>
            </Box>
          )}
        />
      )}
    </AutocompleteBox>
  );
};
const AutocompleteBox = styled(Box)`
  & .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
`;
export default ClientSearchBarPartner;
