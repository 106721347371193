import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Lottie from '../components/Lottie';
import selectClient from '../hooks/selectClient';
import Layout from '../layout';
import MikraNotFoundData from '../lotties/Mikra/notFound.json';
import PartnerNotFoundData from '../lotties/Partner/notFound.json';

let notFoundData;
if (selectClient()=='Partner' || selectClient()=='Lider') notFoundData = PartnerNotFoundData;
else notFoundData = MikraNotFoundData;

const NotFound = () => {
  return (
    <Layout
      topBarProps={{
        backLink: '/',
        text: '',
      }}
    >
      <Container>
        <Lottie animationData={notFoundData} animate={true} />
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Stranica nije pronađena
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Stranica na koju ste došli ne postoji ili se nalazi na drugoj putanji
          </Typography>
          <Box mt={2} display="flex" justifyContent="center">
            <Box width="100px">
              <Button variant="contained" component={Link} to="/">
                Nazad
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > div {
    margin: auto;
    min-width: 38vw;
    height: 100%;
    width: 100%;
  }
`;

export default NotFound;
