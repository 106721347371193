import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const DatePicker = ({ datum, setDatum, label, disabled }) => {
  return (
    <DatePickerWide>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          value={datum}
          renderInput={(params) => <TextField {...params} />}
          onChange={(newValue) => {
            setDatum(newValue);
          }}
        />
      </LocalizationProvider>
    </DatePickerWide>
  );
};
const DatePickerWide = styled(Box)`
  & .MuiTextField-root {
    width: 100%;
  }
`;
export default DatePicker;
