import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { MdSignalWifiConnectedNoInternet4, MdSignalWifiStatusbarConnectedNoInternet1 } from 'react-icons/md';
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import selectClient from '../hooks/selectClient';
import liderLogo from '../images/liderLogo.png';
import mikraLogo from '../images/mikraLogo.svg';
import partnerLogo from '../images/partnerLogo.svg';

const HomeTopBar = () => {
  const theme = useTheme();
  const Vrijeme = () => {
    if (localStorage.getItem('vrijeme')) {
      let vrijeme = localStorage.getItem('vrijeme');
      if (vrijeme > 0 && vrijeme < 5000) {
        return <MdSignalWifiStatusbarConnectedNoInternet1 size={26} color="white" />;
      } else if (vrijeme >= 5000 && vrijeme < 15000) {
        return (
          <Tooltip arrow title="Slaba konekcija" enterTouchDelay={0} placement="top">
            <IconButton size="small">
              <MdSignalWifiStatusbarConnectedNoInternet1 size={26} color={theme.palette.warning.main} />
            </IconButton>
          </Tooltip>
        );
      } else if (vrijeme >= 15000 || vrijeme == -1) {
        return (
          <Tooltip arrow title="Nemate internet konekciju" enterTouchDelay={0} placement="top">
            <IconButton size="small">
              <MdSignalWifiConnectedNoInternet4 size={26} color={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { keycloak } = useKeycloak();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledBox position="absolute" width="100%" height="50px" display="flex">
      {(selectClient() == 'Partner' || selectClient() == 'Mikra')  && (
        <Box ml={5} mt={2} display="flex" justifyContent="center">
          {Vrijeme()}
        </Box>
      )}

      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        {selectClient() == 'Partner' && <img height="50px" src={partnerLogo} alt="" />}
        {selectClient() == 'Mikra' && <img height="30px" src={mikraLogo} alt="" />}
        {selectClient() == 'Lider' && <img height="30px" src={liderLogo} alt="" />}
      </Box>
      <Box display="flex" justifyContent="center">
        <HeaderIcon onClick={handleClick}>
          <AiOutlineUser size={20} />
        </HeaderIcon>
        {
          <Menu
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
          >
            <StyledMenuItem onClick={keycloak.logout}>Odjavite se</StyledMenuItem>
          </Menu>
        }
      </Box>
    </StyledBox>
  );
};

const HeaderIcon = styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.darkGray};
    padding:0;
    `}
`;
const StyledBox = styled(Box)`
  max-width: 750px;
  margin: auto;
`;
const StyledMenuItem = styled(MenuItem)`
  padding-left: 8px;
  padding-right: 8px;
`;
export default HomeTopBar;
