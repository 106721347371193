import React from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchRegistry } from '../api';
import ServerError from './ServerError';
import Spinner from './Spinner';

const ClientDetailsMikra = ({ client }) => {
  const registry = useQuery('registry', fetchRegistry);
  const nazivGrada = () => {
    for (let i = 0; i < registry?.data?.cities?.length; i++) {
      if (registry.data.cities[i].value === client.grad) {
        return registry.data.cities[i].text;
      }
    }
  };
  let biznisRegistrovan = '-';
  if (client.biznisRegistrovan == 1) biznisRegistrovan = 'Da';
  if (client.biznisRegistrovan == 2) biznisRegistrovan = 'Ne';

  return (
    <Box>
      <Box textAlign="center" m={4} pt={2} pb={2} mt={2}>
        <Typography variant="h6">
          {client.ime} {client.otac == null ? '' : `(${client.otac})`} {client.prezime}
        </Typography>
      </Box>
      {registry.error && <ServerError error={registry.error} />}
      {registry.isLoading && <Spinner />}
      {registry.data && (
        <Grid container>
          <Grid item xs={7}>
            <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0} ml={1}>
              <Typography variant="body2">Tip</Typography>
              <BotText variant="subtitle1">
                {client.vrstaKomitenta == 'F' && 'Fizičko lice'}
                {client.vrstaKomitenta == 'P' && 'Pravno lice'}
              </BotText>
              <Typography variant="body2">Broj mobitela</Typography>
              <BotText variant="subtitle1">{client.telefon || '-'}</BotText>
              <Typography variant="body2">Adresa</Typography>
              <BotText variant="subtitle1">{client.adresa || '-'}</BotText>
              <Typography variant="body2">Broj LK</Typography>
              <BotText variant="subtitle1">{client.lk || '-'}</BotText>
              <Typography variant="body2">Biznis registrovan</Typography>
              <BotText variant="subtitle1">{biznisRegistrovan}</BotText>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0}>
              <Typography variant="body2">JMBG</Typography>
              <BotText variant="subtitle1">{client.komitent || '-'}</BotText>
              <Typography variant="body2">Broj fiksnog</Typography>
              <BotText variant="subtitle1">{client.telefonFix || '-'}</BotText>
              <Typography variant="body2">Grad</Typography>
              <BotText noWrap variant="subtitle1">
                {nazivGrada()}
              </BotText>
              <Typography variant="body2">Spol</Typography>
              <BotText variant="subtitle1">
                {client.spol == registry.data.genders[0].value && registry.data.genders[0].text}
                {client.spol == registry.data.genders[1].value && registry.data.genders[1].text}
                {client.spol != registry.data.genders[0].value && client.spol != registry.data.genders[1].value && '-'}
              </BotText>
              <Typography variant="body2">Adresa CIPS</Typography>
              <BotText variant="subtitle1">{client.adresaCips == null ? '-' : client.adresaCips}</BotText>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" p={1} pt={0} m={1} mt={0}>
              <Typography variant="body2">Napomena</Typography>
              <BotText variant="subtitle1">{client.napomena || '-'}</BotText>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
const BotText = styled(Typography)`
  margin-bottom: 24px;
`;
export default ClientDetailsMikra;
