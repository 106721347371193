import { styled } from '@mui/material/styles';

export const Root = styled('div')`
  background: ${({ theme }) => theme.palette.background.default};
  display: flex;
  overflow: hidden;
  max-width: 750px;
  margin: auto;
  height: 100%;
  min-height: 100vh;
`;

//padding-top: ${theme.topBarHeight}px;
export const Wrapper = styled('div')`
  ${({ theme }) => `
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  margin-bottom:10px;
  margin-top:40px;
  `}
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const Content = styled('div')`
  flex: 1 1 auto;
  height: 100%;
  padding: 24px 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 12px;
  }
`;
export const ContentApplication = styled('div')`
  flex: 1 1 auto;
  height: 100%;
  padding: 24px 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0px;
  }
`;
