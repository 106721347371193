import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { AiOutlineExclamation } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Box, Fab, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { loanMatrix } from '../api';

const MatrixBadgePartner = ({ methods, postojiOpcija, setKamata, setRata }) => {
  const iznos = useWatch({ control: methods.control, name: 'amount' });
  const brojRata = useWatch({ control: methods.control, name: 'repaymentPeriod' });
  const grace = useWatch({ control: methods.control, name: 'gracePeriod' });
  const tipKreditnogProizvoda = useWatch({ control: methods.control, name: 'loanProducts' });

  const matrixData = {
    iznos: iznos,
    brojRata: brojRata,
    grace: grace,
    tipKreditnogProizvoda: tipKreditnogProizvoda,
  };
  const { data } = useQuery(['loanmatrix', matrixData], () => loanMatrix(matrixData));
  useEffect(() => {
    if (data) checkMatrix();
  });

  const checkMatrix = () => {
    postojiOpcija(data.isValid && data.brojOpcija > 0);
    if (data.brojOpcija == 1) {
      setKamata(Math.round(data.kamata * 100 + Number.EPSILON) / 100);
      setRata(data.rata);
    } else {
      setKamata('');
      setRata(0);
    }
  };

  return (
    <>
      {data && data.isValid && data.brojOpcija == 0 && (
        <Add>
          <StyledTooltip
            arrow
            title="Odabrane opcije ne ispunjavaju uslove nijednog kreditnog proizvoda"
            enterTouchDelay={0}
            placement="top"
          >
            <Fab>
              <AiOutlineExclamation size={40} />
            </Fab>
          </StyledTooltip>
        </Add>
      )}
    </>
  );
};
const Add = styled(Box)`
  ${({ theme }) => `
  position: fixed;
  bottom: 70px;
  right: 20px;
  size:20;
  z-index:1000;
  
  @media (min-width: 1100px) {
    right:30%
  }
  & .MuiFab-root {
    border-radius: 50% !important;
    background-color: ${theme.palette.error.main};
    color: white;
  }
  `}
`;
const StyledTooltip = withStyles({
  tooltip: {
    top: '16px',
  },
})(Tooltip);
export default MatrixBadgePartner;
