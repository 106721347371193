import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useMutation, useQuery } from 'react-query';
import { FixedSizeList as List } from 'react-window';
import { editNotifikacija, getAllNotifikacije, notifUnreadCount, notifikacijeCount } from '../api';
import { NotificationAvatar, ServerError } from '../components';
import NotifSearchBarPartner from '../components/NotifSearchBarPartner';
import selectClient from '../hooks/selectClient';
import useOfficerProfile from '../hooks/useOfficerProfile';
import HomeLayout from '../layout/HomeLayout';
import { getFirstLine, scrollToTop } from '../utils';
  
  const Notifications = () => {  
    const { officerSifra } = useOfficerProfile();
    const [skip, setSkip] = useState(0); 
    const [curretnPage, setCurrentPage] = useState(0);
    const [notificationData, setNotificationData] = useState([]);
    const [checked, setChecked] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const take = 10;

    const { data, error, isLoading, isPreviousData, refetch } = useQuery({
      queryKey: [`getAllNotifikacije ${officerSifra} ${skip} ${take}`],
      queryFn: () => getAllNotifikacije(officerSifra, skip, take),
      enabled: !!officerSifra,
      keepPreviousData: true,
      onSuccess: (data) => {
        setNotificationData(data)
      }
    }, [skip]);
    
    const res = useQuery(
      ['notifikacijeCount', officerSifra],
      () => notifikacijeCount(officerSifra),
      {
        enabled: !!officerSifra,
      }
    );

    const { refetch: refetchUnreadNotif } = useQuery({
        queryKey: [`notifUnreadCount ${officerSifra}`],
        queryFn:() => notifUnreadCount(officerSifra), 
        enabled: !!officerSifra,
        onSuccess: (data) => {
          localStorage.setItem('numOfUnreadNotif', data.totalCount)
        }
      }
    );

    const { mutateAsync: mutateEdit } = useMutation(editNotifikacija, {
      onSuccess: () => {
        refetchUnreadNotif();
        refetch();
      },
      onError: (error) => {
        enqueueSnackbar(getFirstLine(error.response.data.error.message), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      },
    });

    const handleEditAction = (values) => {
      mutateEdit(values)
    };
  
    const listItemClick = (dataRow) => {
      if (!dataRow) return; 

      const dataWithCertainBrojKredita = data.filter(item => item.brojKredita === dataRow.brojKredita);
      const remainingData = data.filter(item => item.brojKredita !== dataRow.brojKredita);

      setNotificationData([...dataWithCertainBrojKredita, ...remainingData])
      scrollToTop();
    };
    const searchAllNeprocitaneNotifikacije = (event) => {
      setChecked(event.target.checked);
    };
  
    const handlePageChange = (event) => {
        const newSkip = event.selected  * take;
        setSkip(newSkip);
        setCurrentPage(event.selected)
    }
  
    const Row = ({ index, style }) => {
      let notifRow = notificationData[index];
      return (
        <Box style={style}>
          <ListItem
            key={notifRow.brojKredita}
            id={index}
            button
            onClick={() => handleEditAction(notifRow)}
          >
            <ListItemAvatar sx={{ mr: 2 }}>
              <NotificationAvatar pregledana={notifRow.pregledana} />
            </ListItemAvatar>
            <ListItemText
              primary={`50 dana od isplate: ${notifRow.brojKredita}`}
              secondary={
                <Box display="flex" flex-direction="row" mt={1}>
                  <Box display="flex" justifyContent="center" mr={1}>
                    <Typography variant="body2">{'Ime i prezime:'}</Typography>
                  </Box>      
                  <Typography variant="body2">{notifRow.nazivKomitenta}</Typography>
                  <Box display="flex" justifyContent="center" mr={1} ml={1}>
                    <Typography variant="body2">{'| Iznos:'}</Typography>
                  </Box>      
                  <Typography variant="body2">{notifRow.iznos}</Typography>
                  <Box display="flex" justifyContent="center" mr={1} ml={1}>
                    <Typography variant="body2">{'| Rok:'}</Typography>
                  </Box>      
                  <Typography variant="body2">{notifRow.brojAnuiteta}</Typography>
                </Box>
              }
              primaryTypographyProps={{ variant: 'subtitle1', noWrap: true }}
              secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
            />
          </ListItem>
  
          <Divider />
        </Box>
      );
    };
  
    return (
      <HomeLayout>
        <Box pl={2} pr={2} pt={4}>
          <Typography variant="h3">Notifikacije</Typography>
          {selectClient() === 'Partner' && (
          <Box>
            <NotifSearchBarPartner checked={checked} clientSelect={listItemClick} placeholder="Pretraži notifikacije" refetch={refetch}/>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={searchAllNeprocitaneNotifikacije} />}
                label="Pretražuj sve nepročitane"
              />
            </FormGroup>
          </Box>
          )}

          {error && <ServerError error={error} />}
          {!error && (
            <>
              {!isPreviousData && !isLoading && !!notificationData ? (
  
                <Box>
                  <List itemCount={notificationData.length} height={600} itemSize={80}>
                    {Row}
                  </List>
  
                  <div style={{ textAlign: 'center', marginTop: '10%', marginBottom: '30%' }}>
  
                  <style>
                    {`
                      .pagination {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        list-style: none;
                      }
  
                      .page-link {
                        position: relative;
                        display: block;
                        color: #0d6efd;
                        text-decoration: none;
                        background-color: #fff;
                        border: 1px solid #dee2e6;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        font-family: Helvetica;
                        border-radius: 0.45rem; 
                        margin-right: 5px;
                      }
  
                      @media (prefers-reduced-motion: reduce) {
                        .page-link {
                          transition: none;
                        }
                      }
  
                      .page-link:hover {
                        z-index: 2;
                        color: #0a58ca;
                        background-color: #e9ecef;
                        border-color: #dee2e6;
                      }
  
                      .page-link:focus {
                        z-index: 3;
                        color: #0a58ca;
                        background-color: #e9ecef;
                        outline: 0;
                        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                      }
  
                      .page-item:not(:first-child) .page-link {
                        margin-left: -1px;
                      }
  
                      .page-item.active .page-link {
                        z-index: 3;
                        color: #fff;
                        background-color: #0d6efd;
                        border-color: #0d6efd;
                      }
  
                      .page-item.disabled .page-link {
                        color: #6c757d;
                        pointer-events: none;
                        background-color: #fff;
                        border-color: #dee2e6;
                      }
  
                      .page-link {
                        padding: 0.375rem 0.75rem;
                      }
  
                      .page-item:first-child .page-link {
                        border-top-left-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                      }
  
                      .page-item:last-child .page-link {
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                      }
  
                      .pagination-lg .page-link {
                        padding: 0.75rem 1.5rem;
                        font-size: 1.25rem;
                      }
  
                      .pagination-lg .page-item:first-child .page-link {
                        border-top-left-radius: 0.3rem;
                        border-bottom-left-radius: 0.3rem;
                      }
  
                      .pagination-lg .page-item:last-child .page-link {
                        border-top-right-radius: 0.3rem;
                        border-bottom-right-radius: 0.3rem;
                      }
  
                      .pagination-sm .page-link {
                        padding: 0.25rem 0.5rem;
                        font-size: 0.875rem;
                      }
  
                      .pagination-sm .page-item:first-child .page-link {
                        border-top-left-radius: 0.2rem;
                        border-bottom-left-radius: 0.2rem;
                      }
  
                      .pagination-sm .page-item:last-child .page-link {
                        border-top-right-radius: 0.2rem;
                        border-bottom-right-radius: 0.2rem;
                      }
                    `}
                  </style>
  
                    <ReactPaginate
                      pageCount={Math.ceil(res?.data ? (res.data.totalCount / take) : (15 / take))}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      previousLabel={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                      nextLabel={<ArrowForwardIosIcon style={{ fontSize: 12 }} />}
                      forcePage={curretnPage}
                    />
                  </div>
  
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height={600}>
                <CircularProgress />
              </Box>
              )}
            </>
          )}
        </Box>
      </HomeLayout>
    );
  };
  
  export default Notifications;