import { Autocomplete, Box, CircularProgress, Skeleton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { searchNotifikacije } from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';

const NotifSearchBarPartner = ({ checked, clientSelect, placeholder, refetch }) => {
  const { officerSifra } = useOfficerProfile();
  const [searchString, setSearchString] = useState('');

  const notifications = useQuery(
    ['searchNotifikacije', searchString.substring(0, 3), officerSifra, checked],
    () => searchNotifikacije(searchString.substring(0, 3), officerSifra, checked),
    {
      enabled: searchString.length > 2,
    }
  );

  return (
    <AutocompleteBox>
      {notifications.error ? (
        <Skeleton width="100%" height="76px" />
      ) : (
        <Autocomplete
          loading={notifications.isLoading}
          loadingText="Pretražujem notifikacije..."
          onInputChange={(event, value) => setSearchString(value)}
          onClose={() => setSearchString('')}
          popupIcon={notifications.isLoading ? <CircularProgress color="primary" size={20} /> : <AiOutlineSearch size={20} />}
          options={notifications.data || []}
          getOptionLabel={(option) => `${option.naslov}`}
          renderInput={(params) => (
            <TextField
              sx={{ bgcolor: 'white' }}
              {...params}
              placeholder={placeholder}
              margin="normal"
              variant="outlined"
            />
          )}
          onChange={(event, value, reason) => {
            if (reason === 'selectOption')
              clientSelect(value);
            if (reason === 'clear') 
              refetch();
          }}
          clearOnEscape={true}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Typography>
                {option.naslov}
              </Typography>
            </Box>
          )}
        />
      )}
    </AutocompleteBox>
  );
};
const AutocompleteBox = styled(Box)`
  & .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
`;
export default NotifSearchBarPartner;
