import moment from 'moment';
import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import RobotoBold from '../../assets/Roboto-Bold.ttf';
import Roboto from '../../assets/Roboto-Regular.ttf';
import selectClient from '../../hooks/selectClient';
import faq from '../../images/faq.png';
import list from '../../images/information.png';
import partnerSurvey from '../../images/logo.png';
import mikraSurvey from '../../images/old-mikraLogo.png';
import liderSurvey from '../../images/liderLogo.png';
import payday from '../../images/rating.png';

Font.register({
  family: 'Roboto',
  fonts: [{ src: Roboto }, { src: RobotoBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: 80,
    paddingRight: 80,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
  },
  dataWrapper: {
    marginBottom: 30,
  },
  leftSection: {
    paddingBottom: 5,
    flexBasis: '45%',
    marginRight: 20,
  },
  rightSection: {
    paddingBottom: 5,
    flexBasis: '35%',
  },
  title: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 700,
  },
  info: {
    fontSize: 10,
    color: 'grey',
  },
  dataInfo: {
    fontSize: 12,
  },
  logo: {
    width: '200px',
    marginBottom: 50,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: '14px',
    flexBasis: '5%',
    marginRight: 5,
  },
  pitanje: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 20,
  },
  odgovor: {
    fontSize: 12,
    marginTop: '35px',
    textAlign: 'justify',
  },
  faqWrapper: {
    marginTop: 10,
  },
  leftSectionQ: {
    paddingBottom: 5,
    flexBasis: '100%',
  },
  rightSectionQ: {
    paddingBottom: 5,
    flexBasis: '60%',
  },
  footer: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  footerDown: {
    display: 'flex',
    flexDirection: 'row',
  },
  potpisKlijenta: {
    flexBasis: '35%',
  },
  potpisRadnika: {
    flexBasis: '35%',
  },
  danasnjiDatum: {
    flexBasis: '30%',
  },
  radio: {
    display: 'flex',
    marginTop: '5px',
    marginBottom: '5px',
    flexDirection: 'column',
    gap: '10px',
  },
  odgovorlista: {},
  prazno: {
    marginTop: '50px',
    marginBottom: '50px',
  },
});

const SurveyDownload = ({ props }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.dataWrapper}>
          <View>
            <Image
              src={selectClient() == 'Partner' ? partnerSurvey : selectClient() == 'Mikra' ? mikraSurvey : liderSurvey}
              style={styles.logo}
            />
          </View>
          <View style={styles.title}>
            <View style={styles.titleWrapper}>
              <Image src={list} style={styles.icon} />
              <Text>Osnovni podaci</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.leftSection}>
              <Text style={styles.info}>Izvještaj</Text>
            </View>
            <View style={styles.rightSection}>
              <Text style={styles.info}>Opis</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.leftSection}>
              <Text style={styles.dataInfo}>{props?.name}</Text>
            </View>
            <View style={styles.rightSection}>
              <Text style={styles.dataInfo}>{props?.description}</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.dataWrapper}>
            <View style={styles.title}>
              <View style={styles.titleWrapper}>
                <Image src={faq} style={styles.icon} />
                <Text>Pitanja</Text>
              </View>
            </View>

            {props?.questions?.map((question, i) => (
              <View key={i}>
                <View style={styles.section}>
                  <View style={styles.leftSectionQ}>
                    <Text style={styles.pitanje}>
                      {i + 1}. {question.name}
                    </Text>
                  </View>
                </View>
                <View style={styles.section}>
                  <View style={styles.odgovor}>
                    {question?.type === 'radio' &&
                      question?.choices.map((choice, index) => (
                        <View style={styles.radio} key={index}>
                          <Text style={styles.odgovorlista}>
                            {' '}
                            [{'  '}] {choice.text}
                          </Text>
                        </View>
                      ))}
                    {question?.type !== 'radio' && <View style={styles.prazno}></View>}
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.potpisKlijenta}>
            <Text style={styles.odgovor}>Potpis klijenta:</Text>
          </View>
          <View style={styles.potpisRadnika}>
            <Text style={styles.odgovor}>Potpis radnika:</Text>
          </View>
          <View style={styles.danasnjiDatum}>
            <Text style={styles.odgovor}>Današnji datum:</Text>
          </View>
        </View>
        <View style={styles.footerDown}>
          <View style={styles.potpisKlijenta}>
            <Text style={styles.odgovor}>_________________________</Text>
          </View>
          <View style={styles.potpisRadnika}>
            <Text style={styles.odgovor}>_________________________</Text>
          </View>
          <View style={styles.danasnjiDatum}>
            <Text style={styles.odgovor}>{moment().format('DD.MM.YYYY.')}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SurveyDownload;
