import { transparentize } from 'polished';
import React from 'react';
import { FaFile } from 'react-icons/fa';
import { Avatar, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const ApplicationAvatar = ({ status }) => {
  //kompletiran, odobren, generisan ugovor = zeleno
  //u toku = zuto
  //odbijen, storniran, odustao = crveno
  const theme = useTheme();
  let statusColorBackground;
  let statusColorFile;
  if (status == '1' || status == '5' || status == '6') {
    statusColorBackground = '#EDFAF4';
    statusColorFile = '#56B884';
  } else if (status == '3') {
    statusColorBackground = '#FFFBEB';
    statusColorFile = '#F59E0B';
  } else if (status == '0' || status == '2' || status == '4') {
    statusColorBackground = transparentize(0.9, theme.palette.error.main);
    statusColorFile = theme.palette.error.main;
  }

  return (
    <AvatarOutline statuscolorbackground={statusColorBackground} statuscolorfile={statusColorFile} variant="rounded">
      <FaFile size={24} />
    </AvatarOutline>
  );
};

const AvatarOutline = styled(Avatar)`
  background-color: ${({ statuscolorbackground }) => statuscolorbackground || '#E6E6E6'};
  color: ${({ statuscolorfile }) => statuscolorfile || '#E6E6E6'};
  height: 52px;
  width: 52px;
  border-radius: 8px;
`;
export default ApplicationAvatar;
