import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useKeycloak } from '@react-keycloak/web';
import { getOfficerData } from '../api';
import selectClient from './selectClient';

const useOfficerProfile = () => {
  const [officerData, setOfficerData] = useState({
    officerId: null,
    officerSifra: null,
    officerIme: null,
    officerPrezime: null,
    officerUsername: null,
  });
  const { keycloak } = useKeycloak();
  const officerDataLogin = useQuery(['officerData'], () => getOfficerData(keycloak.profile.email.split('@')[0]), {
    enabled: !!keycloak && selectClient() === 'Partner',
  });

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted

    const getOfficerData = async () => {
      let userProfile = await keycloak.loadUserProfile();
      let id, sifra, user;
      if (officerDataLogin.data) {
        id = officerDataLogin.data ? officerDataLogin.data.id : -1;
        sifra = officerDataLogin.data ? officerDataLogin.data.sifra : -1;
        user = userProfile.email.split('@')[0];
      } else if (selectClient() === 'Mikra') {
        id = userProfile.username;
        sifra = userProfile.username;
        user = userProfile.username;
      } else {
        id = userProfile.attributes.officerId ? userProfile.attributes.officerId[0] : -1;
        sifra = userProfile.attributes.officerSifra ? userProfile.attributes.officerSifra[0] : -1;
        user = userProfile.email.split('@')[0];
      }
      let org = userProfile.attributes.officerOrganizacija ? userProfile.attributes.officerOrganizacija[0] : -1;

      // Check if the component is still mounted before updating state
      if (isMounted) {
        setOfficerData({
          officerId: id,
          officerSifra: sifra,
          officerIme: userProfile.firstName,
          officerPrezime: userProfile.lastName,
          officerUsername: user,
          officerOrganizacija: org,
        });
      }
    };

    getOfficerData();

    // Cleanup function to run when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [officerDataLogin.data, keycloak]);

  return officerData;
};

export default useOfficerProfile;
