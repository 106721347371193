import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Form, RadioButton, Select } from '@components/rhf/src/MUI5';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  addKomitent,
  addSuduznik,
  deleteSuduznik,
  editSuduznik,
  fetchRegistry,
  getApplication,
  getSrodstva,
  getSuduznici,
  setGarancija,
} from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';
import Guarantee from '../images/Guarantee.svg';
import documents2 from '../images/documents2.svg';
import documents4 from '../images/documents4.svg';
import noGuarantee from '../images/noGuarantee.svg';
import { comitentTypeFromJMBG, dateFormat, genderFromJMBG, getFirstLine } from '../utils';
import { CheckboxWrapper, ClientSearchBar, CodebtorsClientCreate, DocumentButton, ServerError, Spinner } from '.';

const CodebtorsPartner = ({ brojZahtjeva, previous, next, disabled }) => {
  const { officerUsername } = useOfficerProfile();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [lkExpirationDate, setLkExpirationDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [defaultGuarantee, setDefaultGuarantee] = useState(false);
  const [guarantee, setGuarantee] = useState(false);
  const [newCodebtorId, setNewCodebtorId] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [codebtorTemp, setCodebtorTemp] = useState({ id: null });
  const [newComitent, setNewComitent] = useState(false);

  const CRKBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_prod_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/crk/krd_crk_suduznika.rdf';
  const CRKLink = `${CRKBaseLink}&p_suduznik=${codebtorTemp.id}&p_zahtjevbr=${brojZahtjeva}`;
  const KontrolaBaseLink =
    'https://10.0.0.16:7963/reports/rwservlet?prcld_prod_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/zahtjevi/kontrola_suduznika.rdf';
  const kontrolaLink = `${KontrolaBaseLink}&p_suduznik=${codebtorTemp.id}&p_zahtjevbr=${brojZahtjeva}`;

  const controlAdd = useForm({});
  const controlEdit = useForm({});

  useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      let garancija = res.guarantee != 'D' ? true : false;
      setDefaultGuarantee(res.guarantee);
      setGuarantee(garancija);
    },
  });
  const registry = useQuery('registry', fetchRegistry);
  const srodstvo = useQuery('srodstvo', getSrodstva);
  let suduznici = useQuery(['suduznici', brojZahtjeva], () => getSuduznici(brojZahtjeva));

  const { mutate: mutateAddComitent } = useMutation(addKomitent, {
    onSuccess: (res) => {
      //uzmi id komitenta
      setCodebtorTemp({
        ime: codebtorTemp.ime,
        prezime: codebtorTemp.prezime,
        jmbg: codebtorTemp.jmbg,
        suduznik: res.data,
      });
      setNewCodebtorId(res.data);
      setNewComitent(false);
      enqueueSnackbar('Uspješno kreiran novi komitent', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateAddSuduznik } = useMutation(addSuduznik, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['suduznici', brojZahtjeva]);
      setOpenDrawer(false);
      setGuarantee(true);
      enqueueSnackbar('Uspješno ste dodali sudužnika.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      controlAdd.reset();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateDeleteSuduznik } = useMutation(deleteSuduznik, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['suduznici', brojZahtjeva]);
      enqueueSnackbar('Uspješno ste obrisali sudužnika.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateEditSuduznik } = useMutation(editSuduznik, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['suduznici', brojZahtjeva]);
      enqueueSnackbar('Uspješno ste uredili sudužnika.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutate: mutateSetGarancija } = useMutation(setGarancija, {
    onSuccess: (res) => {
      enqueueSnackbar('Uspješno ste spremili promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const getSrodstvoName = (sifra) => {
    for (let i = 0; i < srodstvo.data.length; i++) {
      if (sifra == srodstvo.data[i].value) return srodstvo.data[i].text;
    }
    return null;
  };

  //DODAVANJE NOVOG KOMITENTA IZ MODALA

  //BIRANJE POSTOJEĆEG KOMITENTA IZ LISTE
  const listItemClick = (dataRow) => {
    //uzmi id komitenta
    if (dataRow) {
      setCodebtorTemp({
        ime: dataRow.ime,
        prezime: dataRow.prezime,
        jmbg: dataRow.komitent,
        suduznik: dataRow.IdKomitent,
      });
    }
  };

  const handleSubmitKomitent = (values) => {
    //Ovdje se dodaje novi komitent iz modala
    values.type = comitentTypeFromJMBG(values.jmbg);
    if (values.type == 'F') {
      values.gender = genderFromJMBG(values.jmbg);
    } else values.gender = null;
    values.LKExpirationDate = dateFormat(lkExpirationDate);

    setCodebtorTemp({
      ime: values.firstName,
      prezime: values.lastName,
      jmbg: values.jmbg,
    });
    values.createdBy = officerUsername;
    mutateAddComitent(values);
  };
  const handleSubmit = () => {
    //Biranje suduznika iz liste, uz komitentId
    let values = controlAdd.getValues();
    values.komitent = codebtorTemp.suduznik;
    values.brojZahtjeva = brojZahtjeva;
    setCodebtorTemp({
      ime: codebtorTemp.ime,
      prezime: codebtorTemp.prezime,
      jmbg: codebtorTemp.jmbg,
      srodstvo: values.relationship,
      zajednickoDomacinstvo: values.household,
    });
    values.createdBy = officerUsername;
    mutateAddSuduznik(values);
  };

  const deleteCodebtor = (suduznik) => {
    suduznici.data.data.splice();
    mutateDeleteSuduznik(suduznik.id);
  };
  const editCodebtor = () => {
    let suduznik = controlEdit.getValues();
    setEditDrawer(false);
    suduznik.id = codebtorTemp.id;
    suduznik.modifiedBy = officerUsername;

    mutateEditSuduznik(suduznik);
  };

  const handleSubmitGarancijaProvjera = () => {
    if (!_.isEmpty(suduznici.data.data) && guarantee !== true) {
      setOpen(true);
    } else {
      handleSubmitGarancija();
    }
  };
  const handleSubmitGarancija = () => {
    let garancija = guarantee === true ? 'N' : 'D';
    let values = { guarantee: garancija };
    values.brojZahtjeva = brojZahtjeva;
    values.modifiedBy = officerUsername;
    setOpen(false);
    //Ovdje uslov, ako je isti, ne zovi
    if (garancija != defaultGuarantee) {
      mutateSetGarancija(values);
    } else {
      next();
    }
  };

  const noError = registry.data && !suduznici.error && !suduznici.isLoading && srodstvo.data && !!officerUsername;
  return (
    <Box>
      {(registry.error || suduznici.error || srodstvo.error) && (
        <ServerError error={registry.error || suduznici.error || srodstvo.error} />
      )}
      {(registry.isLoading || suduznici.isLoading || srodstvo.isLoading) && <Spinner />}
      {noError && (
        <Box pb={12}>
          <Box mb={5} display="flex" width="100%" justifyContent="center">
            <Checkbox
              icon={<CheckboxWrapper label="Sa garancijom" borderColor={theme.palette.gray} image={Guarantee} />}
              checkedIcon={
                <CheckboxWrapper
                  label="Sa garancijom"
                  borderColor={theme.palette.secondary.main}
                  color={theme.palette.secondary.main}
                  image={Guarantee}
                />
              }
              checked={guarantee}
              onChange={() => setGuarantee(!guarantee)}
              disabled={disabled}
            />
            <Checkbox
              icon={<CheckboxWrapper label="Bez garancije" borderColor={theme.palette.gray} image={noGuarantee} />}
              checkedIcon={
                <CheckboxWrapper
                  label="Bez garancije"
                  borderColor={theme.palette.secondary.main}
                  color={theme.palette.secondary.main}
                  image={noGuarantee}
                />
              }
              checked={!guarantee}
              onChange={() => setGuarantee(!guarantee)}
              disabled={disabled}
            />
          </Box>

          <Typography variant="subtitle1">Dodajte sudužnike</Typography>
          <List>
            {suduznici.data.data &&
              suduznici.data.data.map((suduznik) => {
                const srodstvoLabel = getSrodstvoName(suduznik.relationship);
                return (
                  <Box display="flex" flexDirection="row" key={suduznik.jmbg}>
                    <Box flexGrow={1}>
                      <ListItem>
                        <ListItemAvatar sx={{ minWidth: '50px' }}>
                          <Avatar sx={{ bgcolor: '#F1F7FE' }}>
                            <Typography variant="body2" color="secondary">
                              {suduznik.ime.slice(0, 1)}
                              {suduznik.prezime && suduznik.prezime.slice(0, 1)}
                              
                            </Typography>
                          </Avatar>
                          <Box height="30px" />
                        </ListItemAvatar>
                        <ListItemText
                        
                          primary={`${suduznik.ime} ${suduznik.prezime || ''}`}
                        
                          secondary={
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="body2">
                                Srodstvo - {srodstvoLabel == null ? '/' : `${srodstvoLabel}`}
                              </Typography>
                              <Box mt={1}>
                                <Typography variant="body2">
                                  Zajedničko domaćinstvo -{' '}
                                  {suduznik.household == null ? '/' : `${suduznik.household == 'D' ? 'Da' : 'Ne'}`}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          primaryTypographyProps={{ variant: 'h6' }}
                          secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
                        />
                      </ListItem>
                    </Box>
                    {!disabled && (
                      <Box display="flex" flexDirection="column" pt="8px">
                        <Button onClick={() => deleteCodebtor(suduznik)}>
                          <AvatarOutline>
                            <AiOutlineDelete size={16} />
                          </AvatarOutline>
                        </Button>
                        <Button
                          onClick={() => {
                            setCodebtorTemp(suduznik);
                            controlEdit.reset(suduznik);
                            setEditDrawer(true);
                          }}
                        >
                          <AvatarOutline>
                            <AiOutlineEdit size={16} />
                          </AvatarOutline>
                        </Button>
                      </Box>
                    )}
                  </Box>
                );
              })}
          </List>
          <AddButton variant="contained" color="primary" disabled={disabled} onClick={() => setOpenDrawer(!openDrawer)}>
            + Dodaj sudužnika
          </AddButton>
          <DrawerStyled onClose={() => setOpenDrawer(false)} open={openDrawer} anchor="bottom">
            <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
              <Box width="100px"></Box>
              <Box display="flex" justifyContent="center" width="100%">
                <Typography color="white">Novi sudužnik</Typography>
              </Box>
              <HeaderIcon onClick={() => setOpenDrawer(false)}>
                <AiOutlineClose size={20} />
              </HeaderIcon>
            </BoxHeader>
            <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <Box mb={4}>
                <Typography variant="h6">Sudužnici</Typography>
                <Box display="flex" flexDirection="row" mb={4} mt={3}>
                  <Box sx={{ flexGrow: 1 }}>
                    <ClientSearchBar
                      clientSelect={listItemClick}
                      idKomitent={newCodebtorId}
                      placeholder="Odaberite sudužnika"
                    />
                  </Box>
                  <Box mt={4} ml={2}>
                    <ButtonAddComitent
                      variant="contained"
                      color="secondary"
                      onClick={() => setNewComitent(!newComitent)}
                    >
                      +
                    </ButtonAddComitent>
                  </Box>
                </Box>
                {newComitent && (
                  <CodebtorsClientCreate
                    handleSubmitKomitent={handleSubmitKomitent}
                    datumIsteka={lkExpirationDate}
                    setDatumIsteka={setLkExpirationDate}
                    registry={registry}
                  />
                )}
                <Form spacing={4} methods={controlAdd}>
                  <Select label="Srodstvo" name="relationship" options={srodstvo.data} md={6} />
                  <Box mb={-4}>
                    <Typography variant="subtitle2">Zajedničko domaćinstvo</Typography>
                  </Box>
                  <RadioButton
                    name="household"
                    control={controlAdd}
                    options={[
                      { value: 'D', icon: 'Da' },
                      { value: 'N', icon: 'Ne' },
                    ]}
                  ></RadioButton>
                </Form>
              </Box>
              <Box>
                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                  Dodaj sudužnika
                </Button>
              </Box>
            </Box>
          </DrawerStyled>
          <DrawerStyled onClose={() => setEditDrawer(false)} open={editDrawer} anchor="bottom">
            <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
              <Box width="100px"></Box>
              <Box display="flex" justifyContent="center" width="100%">
                <Typography color="white">Uredi sudužnika</Typography>
              </Box>
              <HeaderIcon onClick={() => setEditDrawer(false)}>
                <AiOutlineClose size={20} />
              </HeaderIcon>
            </BoxHeader>
            <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <Box>
                <Typography variant="h6">
                  {codebtorTemp.ime} {codebtorTemp.prezime}
                </Typography>
                <Box mt={4} mb={8}>
                  <DocumentButton link={CRKLink} name={`CRK-${brojZahtjeva}`} label="CRK sudužnika" icon={documents4} />
                  <DocumentButton
                    link={kontrolaLink}
                    name={`Kontrola-${brojZahtjeva}`}
                    label="Kontrola sudužnika"
                    icon={documents2}
                  />
                </Box>
                <Form spacing={4} methods={controlEdit} inputProps={{}}>
                  <Select label="Srodstvo" name="relationship" options={srodstvo.data} md={6} />
                  <RadioButton
                    title="Zajedničko domaćinstvo"
                    name="household"
                    control={controlEdit}
                    options={[
                      { value: 'D', icon: 'Da' },
                      { value: 'N', icon: 'Ne' },
                    ]}
                  ></RadioButton>
                </Form>
              </Box>
              <Box>
                <Button variant="contained" color="secondary" onClick={editCodebtor}>
                  Spremi promjene
                </Button>
              </Box>
            </Box>
          </DrawerStyled>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Ukoliko zahtjev nema garanciju, svi postojeći sudužnici će biti uklonjeni'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Zahtjev bez garancije?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmitGarancija}>Da</Button>
              <Button onClick={() => setOpen(false)} autoFocus>
                Ne
              </Button>
            </DialogActions>
          </Dialog>
          <ActionsWrapper>
            <Box
              sx={{ backgroundColor: 'white' }}
              pt={6}
              pb={6}
              display="flex"
              alignItems="center"
              flexDirection="row"
              width={'100%'}
              pl={6}
              pr={6}
            >
              {!!previous && (
                <Box flexGrow={1} mr={2}>
                  <ArrowButton variant="contained" color="inherit" onClick={previous} disableElevation disableRipple>
                    <Box display="flex" alignItems="center">
                      <IoIosArrowBack size={22} />
                    </Box>
                  </ArrowButton>
                </Box>
              )}
              <Button
                variant="contained"
                onClick={disabled ? next : handleSubmitGarancijaProvjera}
                color="primary"
                disableElevation
                disableRipple
              >
                Sljedeći korak
              </Button>
            </Box>
          </ActionsWrapper>
        </Box>
      )}
    </Box>
  );
};

const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    height: 100%;
  }
`;
const ActionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
`;
const ArrowButton = styled(Button)`
  ${({ theme }) => `
  min-width: 40px;
  height: 44px;
  background-color: white;
  border: 0.5px solid ${theme.palette.primary.main};
  color:${theme.palette.primary.main};
  `}
`;

const BoxHeader = styled(Box)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main}95;
  border-radius: 40px 40px 0px 0px;
  `}
`;
const HeaderIcon = styled(Button)`
  color: white;
  padding: 0;
`;
const AddButton = styled(Button)`
  ${({ theme }) => `
  background-color:white;
  color:${theme.palette.secondary.main};
  border: 0.5px solid ${theme.palette.secondary.main};
  `}
`;
const ButtonAddComitent = styled(Button)`
  border-radius: 3px;
  height: 56px;
  min-width: 56px;
  font-size: 35px;
  font-family: inherit;
`;
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid ${theme.palette.gray};
  color: ${theme.palette.darkGray};
  `}
`;

export default CodebtorsPartner;
