import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { addRacuni, editKomitent, editRacuni, fetchRegistry, getIzvorPrihoda } from '../api';
import { ClientEditFormLider, ServerError, Spinner } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import Layout from '../layout';
import { comitentTypeFromJMBG, dateFormat, genderFromJMBG, getFirstLine } from '../utils';
import NotFound from './404';

const ClientEditLider = () => {
  const location = useLocation();
  //const [editedClient, setEditedClient] = useState();
  const [redirect, setRedirect] = useState(false);
  const [datumIsteka, setDatumIsteka] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [accounts, setAccounts] = useState([]);
  const { officerUsername } = useOfficerProfile();

  const { mutateAsync: mutateEdit } = useMutation(editKomitent, {
    onSuccess: () => {
      let oldAcc = accounts.filter((acc) => acc.existing === true);
      return mutateEditAccounts({ oldAcc, officerUsername });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateAddAccounts } = useMutation(addRacuni, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno ste spasili promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditAccounts } = useMutation(editRacuni, {
    onSuccess: async () => {
      let newAcc = accounts.filter((acc) => !acc.existing);
      const mutation = await mutateAddAccounts({ newAcc, officerUsername });
      setRedirect(true);
      return mutation;
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const registry = useQuery('registry', fetchRegistry);
  const izvori = useQuery('izvor', getIzvorPrihoda);

  const getOpcinaCipsName = (sifra) => {
    for (let i = 0; i < registry.data.municipalities.length; i++) {
      if (sifra == registry.data.municipalities[i].value) {
        return registry.data.municipalities[i].text;
      }
    }
    return null;
  };
  let client;
  if (location.state) client = location.state.client;
  else return <NotFound />;

  const handleSubmit = (values) => {
    values.modifiedBy = officerUsername;
    values.type = comitentTypeFromJMBG(values.jmbg);
    if (values.type == 'F') {
      values.gender = genderFromJMBG(values.jmbg);
    } else values.gender = null;
    if (datumIsteka) {
      //Ako datum nije promijenjem, potreban cast za upis u bazu
      if (datumIsteka == client.datumIstekaLK) {
        let datum = new Date(datumIsteka).toLocaleDateString('default', {
          month: 'short',
        });
        datum = datumIsteka.substring(5, 7) + '-' + datum + '-' + datumIsteka.substring(0, 4);
        values.LKExpirationDate = datum;
      } else {
        values.LKExpirationDate = dateFormat(datumIsteka);
      }
    } else {
      values.LKExpirationDate = null;
    }
    values.cipsMunicipality = getOpcinaCipsName(values.cipsMunicipality);
    return mutateEdit(values);
    //setEditedClient(values);
  };

  const handleAccountSubmit = (values) => {
    setAccounts(values);
  };
  if (!datumIsteka && client.datumIstekaLK) {
    setDatumIsteka(client.datumIstekaLK);
  }
  if (redirect) return <Redirect to="/clients" />;
  return (
    <Layout
      topBarProps={{
        backLink: { pathname: '/client', state: { client } },
        text: 'Uredi komitenta',
      }}
    >
      {(registry.error || izvori.error) && <ServerError error={registry.error || izvori.error} />}
      {(registry.isLoading || izvori.isLoading) && <Spinner />}
      {registry.data && izvori.data && (
        <Box>
          <Box height="8px" />
          <ClientEditFormLider
            handleSubmit={handleSubmit}
            registry={registry.data}
            client={client}
            izvori={izvori.data}
            datumIsteka={datumIsteka}
            setDatumIsteka={setDatumIsteka}
            handleAccountSubmit={handleAccountSubmit}
          ></ClientEditFormLider>
        </Box>
      )}
    </Layout>
  );
};
export default ClientEditLider;
