import { Box, Grid, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { WrapDescription, WrapTitle } from './SurveyTitleWrapper';

import React from 'react';

const SpecialListItem = ({ data, background, image, description, client, officer }) => {
  const theme = useTheme();
  return (
    <Container mt={5} pl={5} pr={5} pt={5} pb={5} background={background} image={image}>
      <Grid container direction="row">
        <Grid item xs={7}>
          <Grid container direction="column" spacing={2}>
            {data ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.gray}>
                  {data?.creation ? new Date(data?.creation).toLocaleDateString('de-DE') : '-'}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {data ? (
              <Grid item>
                <Tooltip title={data?.name}>
                  <WrapTitle variant="subtitle1" color={background !== 'white' ? 'white' : null}>
                    {data?.name}
                  </WrapTitle>
                </Tooltip>
              </Grid>
            ) : (
              <></>
            )}
            {client ? (
              <Grid item>
                <Typography variant="body2" color={background !== 'white' ? 'white' : null}>
                  Ime i prezime
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {client ? (
              <Grid item>
                <Tooltip title={client?.name + ' ' + client?.surname}>
                  <WrapDescription variant="subtitle1" color={background !== 'white' ? 'white' : null}>
                    {client?.name} {client?.surname}
                  </WrapDescription>
                </Tooltip>
              </Grid>
            ) : (
              <></>
            )}
            {officer ? (
              <Grid item>
                <Typography variant="body2" color={background !== 'white' ? 'white' : null}>
                  Poslovnik
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {officer ? (
              <Grid item>
                <Tooltip title={officer?.name + ' ' + officer?.surname}>
                  <Typography variant="subtitle1" color={background !== 'white' ? 'white' : null}>
                    {officer?.name} {officer?.surname}
                  </Typography>
                </Tooltip>
              </Grid>
            ) : (
              <></>
            )}
            {description ? (
              <Grid item>
                <Tooltip title={data?.description}>
                  <WrapTitle variant="body2" color={background !== 'white' ? theme.palette.gray : null}>
                    {data?.description}
                  </WrapTitle>
                </Tooltip>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const Container = styled(Box)`
  background-color: ${(props) => props.background};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: bottom right;
  border-radius: 8px;
`;

export default SpecialListItem;
