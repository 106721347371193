import React from 'react';
import { AiFillBank, AiOutlineUser } from 'react-icons/ai';
import { Avatar, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const ClientAvatar = ({ type, gender, delinquent }) => {
  const theme = useTheme();
  let backgroundColor;
  if (type === 'F' && gender === 'M') backgroundColor = `${theme.palette.primary.main}16`;
  else if (type === 'F' && gender !== 'M') backgroundColor = backgroundColor = `${theme.palette.secondary.main}16`;
  else if (type !== 'F') backgroundColor = backgroundColor = `${theme.palette.warning.main}16`;
  return (
    <Box display="flex">
      <AvatarOutline backgroundcolor={backgroundColor}>
        {type === 'F' && gender === 'M' && <AiOutlineUser size={25} color={theme.palette.primary.main} />}
        {type === 'F' && gender !== 'M' && <AiOutlineUser size={25} color={theme.palette.secondary.main} />}
        {type !== 'F' && <AiFillBank size={25} color={theme.palette.warning.main} />}
      </AvatarOutline>
      {(delinquent === 'D' || delinquent === 1) && <AvatarDelinquent>D</AvatarDelinquent>}
    </Box>
  );
};

const AvatarOutline = styled(Avatar)`
  background-color: ${({ backgroundcolor }) => backgroundcolor || '#E6E6E6'};
  height: 52px;
  width: 52px;
  border-radius: 8px;
`;
const AvatarDelinquent = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.warning.main};
    position:relative;
    float:left;
    margin-left:-20px;
    top:35px;
    height:22px;
    width:22px;
    z-index:2;
    font-size:1rem;
    `}
`;

export default ClientAvatar;
