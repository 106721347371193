import { createTheme } from '@mui/material/styles';
import selectClient from '../../hooks/selectClient';
import liderPalette from '../liderPalette';
import mikraPalette from '../mikraPalette';
import partnerPalette from '../partnerPalette';

let palette;
if (selectClient() == 'Partner') {
  palette = partnerPalette;
} else if (selectClient() == 'Mikra') {
  palette = mikraPalette;
} else if (selectClient() == 'Lider') {
  palette = liderPalette;
}

const defaultTheme = createTheme();

const MuiButton = {
  styleOverrides: {
    root: {
      padding: '8px 16px',
      transition: defaultTheme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
        duration: defaultTheme.transitions.duration.short,
      }),
      boxShadow: '0 0 0 0 !important',
      '&.Mui-disabled': {
        backgroundColor: palette.gray,
        color: palette.darkGray,
      },
    },
    sizeSmall: {
      padding: '4px 8px',
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.675rem',
        padding: '4px 8px',
      },
    },
    sizeLarge: {
      minWidth: 64,
      minHeight: 56,
      padding: '8px 24px',
      fontSize: '1rem',
    },
    text: {
      fontWeight: 600,
    },
    startIcon: {
      marginRight: '5px',
    },
    endIcon: {
      marginLeft: '5px',
    },
    contained: {
      width: '100%',
      padding: '10px',
      borderRadius: '8px',
    },
  },
};

export default MuiButton;
