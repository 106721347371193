import React from 'react';
import LocationIcon from '../images/location.svg';
import { Box, Button, Typography, Avatar, styled } from '@mui/material';
import { FiMapPin, FiLock, FiInfo, FiRotateCw } from 'react-icons/fi';

const LoacationNotFoundPage = () => {
  return (
    <>
      <Box p={6} justifyContent="center" display="flex" flexDirection="column">
        <Box pb={6} justifyContent="center" display="flex" flexDirection="column">
          <img alt="geo img" src={LocationIcon} />
          <Typography align="center" variant="h6" color="text.link">
            Vaša lokacija je isključena.
          </Typography>
        </Box>
        <Typography align="center" variant="h2">
          Za nastavak korištenja aplikacije, uključite lokaciju.
        </Typography>
      </Box>
      <Box p={4} backgroundColor="#f2faff">
        <InstuctionCart
          no={1}
          title="Otvorite padajući meni"
          description="Prelaskom prsta sa vrha ekrana ka dnu, otvorit će vam se meni. "
        />
        <InstuctionCart
          no={2}
          title={<>Potražite ikonu lokacije {<FiMapPin />}</>}
          description={
            <>
              U meniju potažite ikonu {<FiMapPin />} te osigurajte da opcija sa tom ikonom bude uključena.
              <br /> Ako postavka lokacija nije dostupna u meniju, možete je pronaći na putanji:{' '}
              <strong>Postavke &#8594; Lokacija</strong>.{' '}
            </>
          }
        />
        <InstuctionCart
          no={3}
          title={<>Odobrite korištenje lokacije</>}
          description={
            <>
              Ako prethodno niste odbili korištenje lokacije na ovoj aplikaciji, prilikom otvaranja aplikacije, pojavit
              će vam se dijalog u kojem trebate odobriti korištenje lokacije na ovoj adresi.
              <br /> Ukoliko ste prethodno blokirali korištenje lokacije na ovoj adresi, potrebno je da u pretraživaču
              pored mjesta na kojem piše domena (adresa) ove aplikacije kliknete na ikonu
              <strong>
                {' '}
                <FiLock /> ili <FiInfo />
              </strong>{' '}
              te odobrite korištenje lokacije.
            </>
          }
        />
        <Button
          color="secondary"
          startIcon={<FiRotateCw />}
          variant="contained"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Osvježi
        </Button>
      </Box>
    </>
  );
};

const InstuctionCart = ({ no, title, description }) => {
  return (
    <OutlineCard mb={4}>
      <OrdinalAvatar>{no}</OrdinalAvatar>
      <Box p={4}>
        <Typography variant="h4">{title}</Typography>
        <Box height="8px" />
        <Typography variant="caption" color="text.placeholder">
          {description}
        </Typography>
      </Box>
    </OutlineCard>
  );
};

const OutlineCard = styled(Box)`
  ${({ theme }) => `
  border-radius: 16px;
  border: 3px solid ${theme.palette.text.link};
  display: flex;
  align-items: center;
  background-color: ${theme.palette.background.default};
  
  `}
`;

const OrdinalAvatar = styled(Avatar)`
  ${({ theme }) => `
   left: -17px;
   width: 28px;
   height: 28px;
   font-size: 18px;
   font-weight: 700;
   border: 3px solid ${theme.palette.text.link};
   color:  ${theme.palette.text.link};
   background-color: ${theme.palette.background.default};
   `}
`;

export default LoacationNotFoundPage;
