import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Box, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const ActionWrapper = ({ previous, isSubmitting, label }) => {
  const [isDisabled, setIsDisabled] = useState(isSubmitting);
  useEffect(() => {
    setIsDisabled(isSubmitting);
  }, [isSubmitting]);
  return (
    <ActionsWrapper>
      <Box
        sx={{ backgroundColor: 'white' }}
        pt={6}
        pb={6}
        display="flex"
        alignItems="center"
        flexDirection="row"
        width={'100%'}
        pl={6}
        pr={6}
      >
        {!!previous && (
          <Box flexGrow={1} mr={2}>
            <ArrowButton
              variant="contained"
              color="inherit"
              onClick={previous}
              disabled={isDisabled}
              disableElevation
              disableRipple
            >
              <Box display="flex" alignItems="center">
                <IoIosArrowBack size={22} />
              </Box>
            </ArrowButton>
          </Box>
        )}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={isDisabled}
          startIcon={isDisabled && <CircularProgress size={20} />}
          disableElevation
          disableRipple
        >
          {label}
        </Button>
      </Box>
    </ActionsWrapper>
  );
};
const ActionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
`;
const ArrowButton = styled(Button)`
  ${({ theme }) => `
  min-width: 40px;
  height: 44px;
  background-color: white;
  border: 0.5px solid ${theme.palette.primary.main};
  color:${theme.palette.primary.main};
  `}
`;
export default ActionWrapper;
