import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useQuery } from 'react-query';
import { styled, Box, Fab, Grid, Typography, Button, CircularProgress } from '@mui/material';
import { AiOutlineDownload, AiOutlineFileExcel } from 'react-icons/ai';
import { fetchAnswers, createAnswersSpecificExcelFile } from '../api';
import Spinner from '../components/Spinner';
import Layout from '../layout';
import SurveyMeta from '../images/SurveyMeta.svg';
import OfficerMeta from '../images/OfficerMeta.svg';
import ClientMeta from '../images/ClientMeta.svg';
import SpecialListItem from '../components/Survey/SpecialListItem';
import PDFGenerator from '../components/Survey/PDF';

const Answers = () => {
  let { id } = useParams();
  const location = useLocation();

  const [back, setBack] = useState();
  const [ready, setReady] = useState(false);
  const [client, setClient] = useState();
  const [creatingExcelFile, setCreatingExcelFile] = useState(false);

  const { isLoading, error, data } = useQuery(['fetchAnswers', id], () => fetchAnswers(id));
  useEffect(() => {
    setBack(location?.state?.back);
    setClient(location?.state?.client);
  }, [location?.state?.client, location?.state?.back]);

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      if (isMounted) setReady(true);
    }, 3000);

    //cleanup
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout
      topBarProps={{
        backLink: { pathname: `/${back}`, state: { client } },
        text: 'Anketa',
      }}
    >
      <FormContainer pl={5} pr={5} sx={{ overflow: 'hidden' }}>
        {isLoading && <Spinner />}
        {!error && !isLoading && (
          <Box p={5}>
            <Box mb={5} mt={5}>
              <Typography variant="h4">Osnovni podaci</Typography>
            </Box>
            <SpecialListItem
              data={data?.survey}
              background="white"
              image={SurveyMeta}
              description={data?.survey.description}
            />
            {data?.survey?.userDependency && (
              <SpecialListItem
                background="white"
                image={ClientMeta}
                client={{ name: data?.client?.ime, surname: data?.client?.prezime }}
              />
            )}
            <SpecialListItem
              background="white"
              image={OfficerMeta}
              officer={{ name: data?.officer?.officerIme, surname: data?.officer?.officerPrezime }}
            />
            <Box mb={5} mt={10}>
              <Typography variant="h4">Odgovori na anketu</Typography>
            </Box>
            {data?.answers.map((answers, i) => (
              <FormQuestion pl={5} pr={5} pt={8} pb={8} mb={5} key={i}>
                <Box mb={5}>
                  <Grid container direction="row" alignItems="center" spacing={5}>
                    <Grid item xs={2} md={1}>
                      <QuestionNumber size="small">{i + 1}</QuestionNumber>
                    </Grid>
                    <Grid item xs={10} md={11}>
                      <Typography variant="subtitle1" align="justify">
                        {answers?.pitanje}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="body2" align="justify">
                  {answers?.odgovor}
                </Typography>
              </FormQuestion>
            ))}
            {data?.survey?.userDependency && (
              <Box>
                {!ready && <PDFSpinner />}
                {ready && (
                  <PDFDownloadLink
                    document={<PDFGenerator props={data} />}
                    fileName={`${data._id}.pdf`}
                    style={{ textDecoration: 'none' }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <PDFSpinner />
                      ) : (
                        <Button variant="outlined" color="secondary" fullWidth>
                          <Grid container direction="row" alignItems="center" spacing={6}>
                            <Grid xs={6} align="right" alignItems="bottom" item>
                              <Box mt={1.5}>
                                <AiOutlineDownload color="secondary" size={26} />
                              </Box>
                            </Grid>
                            <Grid xs={6} align="left" item>
                              <Typography variant="h6" color="secondary">
                                PDF
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                )}
              </Box>
            )}
            <Box mt={5}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => createAnswersSpecificExcelFile(data, setCreatingExcelFile, id)}
                disabled={creatingExcelFile}
              >
                <Grid container direction="row" alignItems="center" spacing={6}>
                  <Grid xs={6} align="right" alignItems="bottom" item>
                    <Box mt={1.5}>
                      <AiOutlineFileExcel size={26} />
                    </Box>
                  </Grid>
                  <Grid xs={6} align="left" item>
                    <Typography variant="h6" color="primary">
                      Excel
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Box>
          </Box>
        )}
      </FormContainer>
    </Layout>
  );
};

const PDFSpinner = () => {
  return (
    <Button variant="outlined" color="secondary" fullWidth disabled sx={{ py: 3 }}>
      <CircularProgress color="secondary" />
    </Button>
  );
};

const FormContainer = styled(Box)`
  ${({ theme }) => `
  min-width: 100%;
  height: 100%;
  margin-left: -6%;
  margin-right: -6%;
  background-color: ${theme.palette.background.block};
`}
`;

const QuestionNumber = styled(Fab)`
  ${({ theme }) => `
  background-color: #7B78FE;
  color: white;
  min-height:0;
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px;
  `}
`;

const FormQuestion = styled(Box)`
  border-radius: 5px;
  background-color: white;
`;

export default Answers;
