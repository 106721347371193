import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { addSurvey } from '../api';
import InputRadio from '../components/Survey/InputRadio';
import InputText from '../components/Survey/InputText';
import SurveyForma from '../components/Survey/SurveyForm';
import selectClient from '../hooks/selectClient';
import Layout from '../layout';

const SurveyCreate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const messageSuccess = 'Uspješno ste kreirali anketu.';
  const messageError = 'Desila se greška prilikom kreiranja ankete. Pokušajte ponovo.';

  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [survey, setSurvey] = useState({ questions: [] });
  const [loandependency, updateLoandependency] = useState(false);
  const [clientdependency, updateClientdependency] = useState(false);

  const { register, watch, handleSubmit } = useForm();
  const onSubmit = (data) => handleSurvey();
  const toggle = () => setOpen((v) => !v);
  const updateSurvey = (newQuestion) => setSurvey({ ...survey, questions: [...survey.questions, newQuestion] });

  const { mutate } = useMutation(addSurvey, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllSurveys');
      setRedirect(true);
      enqueueSnackbar(messageSuccess, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(messageError, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const handleSurvey = () => {
    mutate({
      name: watch('naziv'),
      description: watch('opis'),
      creation: Date.now(),
      questions: survey.questions,
      loanDependency: !!watch('potrebanKredit'),
      userDependency: !!watch('potrebanKredit') || !!watch('potrebanKomitent'),
    });
  };

  const handleClientToggle = () => {
    if (loandependency && clientdependency)
      enqueueSnackbar('Ukoliko je anketa vezana za kredit mora biti vezana i za komitenta!', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    else updateClientdependency((v) => !v);
  };

  useEffect(() => {
    if (loandependency) updateClientdependency(true);
  }, [loandependency]);

  if (redirect) return <Redirect to="/survey" />;
  return (
    <Layout
      topBarProps={{
        backLink: '/survey',
        text: 'Anketa',
      }}
    >
      <FormContainer>
        <Box pt={5} pl={10} pr={10}>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormMetaData p={5} mb={5}>
                {survey.questions.length === 0 ? (
                  <Box>
                    <Typography variant="h6">Forma za kreiranje ankete</Typography>
                    <Typography variant={'body2'} gutterBottom>
                      Anketa trenutno ne sadrži niti jedno polje. Dodajte ga klikom na dugme ispod.
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Box mb={5}>
                      <Typography variant="h6">Forma za kreiranje ankete</Typography>
                      <Typography variant={'body2'} gutterBottom>
                        Popunite osnovne informacije vezane za anketu.
                      </Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Grid container direction={'column'} spacing={4}>
                        <Grid item>
                          <TextField label="Naziv ankete" {...register('naziv')} size="small" required fullWidth />
                        </Grid>
                        <Grid item>
                          <TextField label="Opis ankete" {...register('opis')} size="small" required fullWidth />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch onClick={() => updateLoandependency((v) => !v)} checked={loandependency} />
                            }
                            label="Vezanost za kredit"
                            {...register('potrebanKredit')}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={<Switch onClick={handleClientToggle} checked={clientdependency} />}
                            label="Vezanost za komitenta"
                            {...register('potrebanKomitent')}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Box>
                )}
              </FormMetaData>
              {survey.questions?.map((field, i) =>
                field.type === 'text' ? (
                  <FormQuestion pl={5} pr={5} pt={8} pb={8} mb={5}>
                    <InputText key={i} props={field} position={i + 1} />
                  </FormQuestion>
                ) : (
                  <FormQuestion pl={5} pr={5} pt={8} pb={8} mb={5}>
                    <InputRadio key={i} props={field} position={i + 1} />
                  </FormQuestion>
                )
              )}
              {survey.questions.length > 0 ? (
                <Button variant="contained" color="secondary" type="submit">
                  Spasi anketu
                </Button>
              ) : null}
            </form>
          </Box>
        </Box>
        <SurveyModal open={open} onClose={toggle}>
          <SurveyModalWrapper>
            <Box mb={5}>
              <Typography variant={'h6'} gutterBottom>
                Izaberite koje polje želite dodati u anketu
              </Typography>
            </Box>
            <SurveyForma updateSurvey={updateSurvey} />
          </SurveyModalWrapper>
        </SurveyModal>
        <Add>
          <Fab onClick={toggle} size="medium" color="primary">
            <AiOutlinePlus size={40} />
          </Fab>
        </Add>
      </FormContainer>
    </Layout>
  );
};

const FormContainer = styled(Box)`
  ${({ theme }) => `
  min-width: 100%;
  height: 100%;
  margin-left: -6%;
  margin-right: -6%;
  background-color: ${theme.palette.background.block};
`}
`;

const FormMetaData = styled(Box)`
  ${({ theme }) => `
  border-left: 6px solid ${theme.palette.secondary.main};
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  `}
`;

const FormQuestion = styled(Box)`
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const SurveyModal = styled(Modal)`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(3)}
`}
`;

const SurveyModalWrapper = styled(Box)`
  ${({ theme }) => `
  border-left: 6px solid ${theme.palette.primary.main};
  border-radius: 5px;
  background-color: white;
  border-radius: 6px;
  padding: 25px;
  `}
`;

const Add = styled(Box)`
  position: fixed;
  bottom: 70px;
  right: 20px;
  size: 20;

  @media (min-width: 1100px) {
    right: 30%;
  }
  & .MuiFab-root {
    border-radius: 50% !important;
    color: white;
  }
`;

export default SurveyCreate;
