import React from 'react';
import { Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

const CheckboxWrapper = ({ color, image, label }) => {
  return (
    <Box
      p={1}
      display="flex"
      alignItems="center"
      sx={{
        border: '1px solid',
        borderColor: color,
        borderRadius: '8px',
        maxHeight: '64px',
      }}
    >
      <ListItem button>
        <Box display="flex" width="100%" alignItems="center">
          <Box>
            <ListItemAvatar sx={{ mr: '8px' }}>
              <img src={image} alt="" />
            </ListItemAvatar>
          </Box>
          <ListItemText primary={label} primaryTypographyProps={{ variant: 'subtitle2', color: color }}></ListItemText>
        </Box>
      </ListItem>
    </Box>
  );
};

export default CheckboxWrapper;
