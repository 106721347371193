import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useMutation, useQuery } from 'react-query';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getLoanByZahtjevId, setDatumPotpisaUgovora } from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { dateFormat, getFirstLine } from '../utils';
import { useHistory } from 'react-router-dom';

const ContractSigned = ({ brojZahtjeva, previous, disabled, signatureDate, setSignatureDate  }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  // const [signatureDate, setSignatureDate] = useState(null);
  const [kreditBr, setKreditBr] = useState(null);
  const { officerUsername } = useOfficerProfile();
  const history = useHistory();

  useQuery(['loan', getLoanByZahtjevId], () => getLoanByZahtjevId(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      setKreditBr(res.broj);
      if (res) setSignatureDate(res.datumPotpisa);
    },
  });
  const { mutate: mutateDatumPotpisaUgovora } = useMutation(setDatumPotpisaUgovora, {
    onSuccess: (res) => {
      setOpen(false);
      enqueueSnackbar('Uspješno ste unijeli datum potpisa ugovora.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const handleSubmit = () => {
    let values = {
      broj: kreditBr,
    };
    values.ContractSignedDate = dateFormat(new Date());
    values.modifiedBy = officerUsername;
    mutateDatumPotpisaUgovora(values);
    history.push('/');
  };

  return (
    <Box>

      <ActionsWrapper>
        <Box
          sx={{ backgroundColor: 'white' }}
          pt={6}
          pb={6}
          display="flex"
          alignItems="center"
          flexDirection="row"
          width={'100%'}
          pl={6}
          pr={6}
        >
          {!!previous && (
            <Box flexGrow={1} mr={2}>
              <ArrowButton variant="contained" color="inherit" onClick={previous} disableElevation disableRipple>
                <Box display="flex" alignItems="center">
                  <IoIosArrowBack size={22} />
                </Box>
              </ArrowButton>
            </Box>
          )}
          <Button
            variant="contained"
            onClick={() => {
              // if (signatureDate) 
              setOpen(true);
              setSignatureDate(new Date())
            }}
            color="primary"
            disableElevation
            disableRipple
          >
            Potvrdi
          </Button>
        </Box>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Potvrđujete da je ugovor potpisan?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Potpis ugovora</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} /*onClick={next}*/>Da</Button>
            <Button onClick={() => setOpen(false)} autoFocus>
              Ne
            </Button>
          </DialogActions>
        </Dialog>
      </ActionsWrapper>
    </Box>
  );
};
const ActionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
`;
const ArrowButton = styled(Button)`
  ${({ theme }) => `
  min-width: 40px;
  height: 44px;
  background-color: white;
  border: 0.5px solid ${theme.palette.primary.main};
  color:${theme.palette.primary.main};
  `}
`;
export default ContractSigned;
