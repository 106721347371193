import React from 'react';
import TopBar from './TopBar';
import { ContentApplication, ContentContainer, Root, Wrapper } from './style';

const ApplicationLayout = ({ topBarProps, children, ...rest }) => {
  return (
    <Root {...rest}>
      <TopBar
        backLink={topBarProps.backLink}
        forwardLink={topBarProps.forwardLink}
        text={topBarProps.text}
        setOpen={topBarProps.setOpen}
      />
      <Wrapper>
        <ContentContainer>
          <ContentApplication>{children}</ContentApplication>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
};

export default ApplicationLayout;
