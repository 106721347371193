import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AiOutlineClose, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Form, Input, RadioButton } from '@components/rhf/src/MUI5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Box, Button, Drawer, List, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { officerAccountSchema } from '@partner/validation';
import { getBanke, getRacuni } from '../api';
import creditCard from '../images/creditCard.png';
import defaultCard from '../images/defaultCard.png';
import ServerError from './ServerError';
import Spinner from './Spinner';

const Accounts = ({ handleChange, clientId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const controlAdd = useForm({
    resolver: yupResolver(officerAccountSchema),
    mode: 'onBlur',
  });
  const { isValid: isAddValid } = controlAdd.formState;

  const controlEdit = useForm({
    mode: 'onBlur',
  });
  const { isValid: isEditValid } = controlEdit.formState;

  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState({ account: '-' });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);

  const accountNumber = useWatch({ control: controlAdd.control, name: 'account' });
  let existingAccounts = useQuery(['accounts', clientId], () => getRacuni(clientId), {
    onSuccess: (data) => {
      if (accounts.length == 0) handleChange(data);
      for (let i = 0; i < data.length; i++) {
        data[i].existing = true;
      }
      accountSort(data);
    },
    enabled: accounts.length === 0,
  });
  const banks = useQuery('banks', getBanke);

  const accountSort = (accList) => {
    if (!accList) return;
    for (let i = 0; i < accList.length; i++) {
      if (accList[i].isDefault === 'D') {
        let temp = accList[0];
        accList[0] = accList[i];
        accList[i] = temp;
        break;
      }
    }
    setAccounts(accList);
  };

  const handleAccountSubmit = () => {
    if (!isAddValid) {
      return;
    }
    let values = controlAdd.getValues();
    let tempAcc = [...accounts];

    if (!values.type || !values.isDefault) {
      enqueueSnackbar('Molimo odaberite potrebne opcije.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    if (!bankId(bankTitle)) {
      enqueueSnackbar('Molimo odaberite ispravan broj računa.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    if (values.isDefault === 'D') {
      for (let i = 0; i < tempAcc.length; i++) {
        tempAcc[i].isDefault = 'N';
      }
    }
    tempAcc.push({
      account: values.account,
      bank: bankId(bankTitle),
      country: '977', //hardkodirano
      isDefault: values.isDefault,
      type: values.type,
      party: values.party,
      vlasnik: clientId,
    });
    accountSort(tempAcc);
    handleChange(tempAcc);
    setOpenDrawer(false);
    controlAdd.reset();
  };
  const handleAccountEdit = () => {
    if (!isEditValid) {
      return;
    }
    let values = controlEdit.getValues();
    let tempAcc = [...accounts];
    for (let i = 0; i < tempAcc.length; i++) {
      if (tempAcc[i].id === currentAccount.id) {
        tempAcc[i].type = values.type;
        tempAcc[i].party = values.party;
        tempAcc[i].isDefault = values.isDefault;
      } else if (values.isDefault == 'D') {
        tempAcc[i].isDefault = 'N';
      }
    }
    accountSort(tempAcc);
    handleChange(tempAcc);
    setEditDrawer(false);
  };
  const removeAccount = (account) => {
    let tempAcc = [...accounts];
    for (let i = 0; i < tempAcc.length; i++) {
      if (tempAcc[i] === account) {
        tempAcc.splice(i, 1);
      }
    }
    accountSort(tempAcc);
    handleChange(tempAcc);
  };

  const editForm = (account) => {
    if (!account.existing) {
      removeAccount(account);
      return;
    }
    controlEdit.reset(account);
    setCurrentAccount(account);
    setEditDrawer(true);
  };

  const bankName = (bankId) => {
    for (let i = 0; i < banks.data.length; i++) {
      if (bankId == banks.data[i].id) {
        return banks.data[i].naziv;
      }
    }
    return '-';
  };

  const bankId = (bankName) => {
    for (let i = 0; i < banks.data.length; i++) {
      if (bankName == banks.data[i].naziv) {
        return banks.data[i].id;
      }
    }
    return null;
  };

  let bankTitle = '-';
  if (editDrawer && !!currentAccount) bankTitle = bankName(currentAccount.bank);
  else if (banks.data && accountNumber && accountNumber.length >= 3) {
    let tempBin = accountNumber.substring(0, 3);
    for (let i = 0; i < banks.data.length; i++) {
      if (tempBin == banks.data[i].bin) {
        bankTitle = banks.data[i].naziv;
        break;
      }
    }
  }
  return (
    <Box>
      {(existingAccounts.error || banks.error) && <ServerError error={existingAccounts.error || banks.error} />}
      {banks.isLoading && existingAccounts.isLoading && <Spinner />}
      {!existingAccounts.error && !existingAccounts.isLoading && banks.data && (
        <Box>
          <Paper
            elevation={0}
            sx={{ borderRadius: '16px', marginLeft: '-12px', marginRight: '-12px', backgroundColor: '#F4F6F8' }}
          >
            <Box pl={4} pr={4} pt={4}>
              <Box display="flex" flexDirection="row">
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h6">Računi</Typography>
                </Box>
                <Box width="50px">
                  <AddButton onClick={() => setOpenDrawer(!openDrawer)}>
                    <AiOutlinePlus size={30} />
                  </AddButton>
                </Box>
              </Box>
              <List>
                {accounts.map((account) => {
                  if (account.isDefault === 'D') {
                    return (
                      <Box display="flex" mb={5} justifyContent="center" key="account.account">
                        <DefaultCreditCard>
                          <Box display="flex">
                            <Box m={6} mr={0} flexGrow={1} display="flex" flexDirection="column">
                              <Box height="40px">
                                <Typography variant="body1" sx={{ color: 'white', height: '40px' }}>
                                  {bankName(account.bank)}
                                </Typography>
                              </Box>
                              <Box mt={11} mb={12}>
                                <Typography variant="h3" sx={{ color: 'white' }}>
                                  {account.account.slice(0, 4)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(4, 8)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(8, 12)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(12, 416)}
                                </Typography>
                              </Box>
                              <Typography variant="caption" sx={{ color: 'white' }}>
                                {account.type == 1246901 ? 'Transakcijski račun' : 'Tekući račun'}
                              </Typography>
                            </Box>
                            <Button sx={{ height: '64px' }} onClick={() => editForm(account)}>
                              <AvatarOutline>
                                {account.existing ? <AiOutlineEdit size={16} /> : <AiOutlineClose size={16} />}
                              </AvatarOutline>
                            </Button>
                          </Box>
                        </DefaultCreditCard>
                      </Box>
                    );
                  } else
                    return (
                      <Box display="flex" mb={5} justifyContent="center">
                        <CreditCard>
                          <Box display="flex">
                            <Box
                              m={3}
                              ml={6}
                              mr={0}
                              flexGrow={1}
                              display="flex"
                              flexDirection="column"
                              overflow="hidden"
                            >
                              <Box height="40px">
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                  {bankName(account.bank)}
                                </Typography>
                              </Box>
                              <Box mb={1}>
                                <Typography variant="h4" sx={{ color: 'white' }}>
                                  {account.account.slice(0, 4)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(4, 8)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(8, 12)}
                                  &nbsp;&nbsp;
                                  {account.account.slice(12, 416)}
                                </Typography>
                              </Box>
                              <Typography variant="caption" sx={{ color: 'white' }}>
                                {account.type == 1246901 ? 'Transakcijski račun' : 'Tekući račun'}
                              </Typography>
                            </Box>
                            <Button sx={{ height: '44px' }} onClick={() => editForm(account)}>
                              <AvatarOutline>
                                {account.existing ? <AiOutlineEdit size={16} /> : <AiOutlineClose size={16} />}
                              </AvatarOutline>
                            </Button>
                          </Box>
                        </CreditCard>
                      </Box>
                    );
                })}
              </List>
              <DrawerStyled onClose={() => setOpenDrawer(false)} open={openDrawer} anchor="bottom">
                <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
                  <Box width="100px" />
                  <Box display="flex" justifyContent="center" width="100%">
                    <Typography color="white">Novi račun</Typography>
                  </Box>
                  <HeaderIcon onClick={() => setOpenDrawer(false)}>
                    <AiOutlineClose size={20} />
                  </HeaderIcon>
                </BoxHeader>
                <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                  <Box>
                    <Form spacing={4} methods={controlAdd} inputProps={{}}>
                      <Input label="Broj računa" name="account"></Input>
                      <Typography variant="caption">
                        Banka
                        <Typography variant="h5">{bankTitle}</Typography>
                      </Typography>
                      <RadioButton
                        title="Vrsta računa"
                        name="type"
                        control={controlAdd}
                        options={[
                          { value: 1246901, icon: 'Transakcijski' },
                          { value: 1246902, icon: 'Tekući' },
                        ]}
                      ></RadioButton>
                      <Input label="Partija" name="party"></Input>
                      <RadioButton
                        title="Default račun"
                        name="isDefault"
                        control={controlAdd}
                        options={[
                          { value: 'D', icon: 'Da' },
                          { value: 'N', icon: 'Ne' },
                        ]}
                      ></RadioButton>
                    </Form>
                  </Box>
                  <Box>
                    <Button variant="contained" color="secondary" onClick={handleAccountSubmit}>
                      Dodaj račun
                    </Button>
                  </Box>
                </Box>
              </DrawerStyled>
              <DrawerStyled onClose={() => setEditDrawer(false)} open={editDrawer} anchor="bottom">
                <BoxHeader flex-direction="row" display="flex" mt={4} pt={5} pb={5}>
                  <Box width="100px" />
                  <Box display="flex" justifyContent="center" width="100%">
                    <Typography color="white">Uredi račun</Typography>
                  </Box>
                  <HeaderIcon onClick={() => setEditDrawer(false)}>
                    <AiOutlineClose size={20} />
                  </HeaderIcon>
                </BoxHeader>
                <Box p={5} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                  <Box>
                    <Form spacing={4} methods={controlEdit} inputProps={{}}>
                      <Typography variant="h5">{bankTitle}</Typography>
                      <Typography variant="h5">{currentAccount.account}</Typography>
                      <RadioButton
                        title="Vrsta računa"
                        name="type"
                        control={controlEdit}
                        options={[
                          { value: 1246901, icon: 'Transakcijski' },
                          { value: 1246902, icon: 'Tekući' },
                        ]}
                      ></RadioButton>
                      <Input label="Partija" name="party"></Input>
                      <RadioButton
                        title="Default račun"
                        name="isDefault"
                        control={controlEdit}
                        options={[
                          { value: 'D', icon: 'Da' },
                          { value: 'N', icon: 'Ne' },
                        ]}
                      ></RadioButton>
                    </Form>
                  </Box>
                  <Box>
                    <Button variant="contained" color="secondary" onClick={handleAccountEdit}>
                      Spremi promjene
                    </Button>
                  </Box>
                </Box>
              </DrawerStyled>
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};
const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid ${theme.palette.gray};
  color: ${theme.palette.darkGray};
  `}
`;
const AddButton = styled(Button)`
  ${({ theme }) => `
  background-color:${theme.palette.secondary.main}30;
  color:${theme.palette.secondary.main};
  height: 64px;
  border-radius: 100%;
  transform: scale(0.6, 0.6);
  margin-top:-20px;
  `}
`;
const BoxHeader = styled(Box)`
  ${({ theme }) => `
  background-color:${theme.palette.primary.main}95;
  border-radius: 40px 40px 0px 0px;
  `}
`;
const HeaderIcon = styled(Button)`
  color: white;
  padding: 0;
`;
const DefaultCreditCard = styled(Paper)`
  background-image: url(${defaultCard}), linear-gradient(115.65deg, #0b1a55 2.71%, #193abc 72.61%);
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  border-radius: 16px;
`;
const CreditCard = styled(Paper)`
  background-image: url(${creditCard}), linear-gradient(275.52deg, #3ba39e 12.69%, #bff4e8 97.97%);
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  border-radius: 16px;
`;
const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    height: 100%;
  }
`;
export default Accounts;
