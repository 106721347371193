import _ from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AiOutlineExclamation } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Box, Fab, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { getMatrica } from '../api';

const MatrixBadgeMikra = ({ methods, postojiOpcija, setKamata, setRata, komitent, promotions, setPromotions }) => {
  const iznos = useWatch({ control: methods.control, name: 'amount' });
  const brojRata = useWatch({ control: methods.control, name: 'repaymentPeriod' });
  const grace = useWatch({ control: methods.control, name: 'gracePeriod' });
  const tipKreditnogProizvoda = useWatch({ control: methods.control, name: 'loanProducts' });
  const djelatnost = useWatch({ control: methods.control, name: 'recommendedLoanPurpose' });
  const namjena = useWatch({ control: methods.control, name: 'loanProduct' });
  const vrstaKredita = useWatch({ control: methods.control, name: 'loanType' });
  const vrstaIsplate = useWatch({ control: methods.control, name: 'payoutType' });
  const akcija = useWatch({ control: methods.control, name: 'action' });

  const loanMatrix = useQuery('getMatrica', getMatrica, {
    onSuccess: () => {},
  });

  if (loanMatrix.data) {
    let matchingRows = [];
    let actions = [];
    for (let i = 0; i < loanMatrix.data.length; i++) {
      let row = loanMatrix.data[i];
      let biznisRegistrovanTemp = !row.biznisRegistrovan ? null : row.biznisRegistrovan === 'D' ? 1 : 2;
      if (
        row.amountFrom <= iznos &&
        row.amountTo >= iznos &&
        row.installmentsMin <= brojRata &&
        row.installmentsMax >= brojRata &&
        row.grace >= grace &&
        (!row.biznisRegistrovan || biznisRegistrovanTemp == komitent.biznisRegistrovan) &&
        (!row.vrstaKomitenta || row.vrstaKomitenta == komitent.vrstaKomitenta) &&
        (!row.spol || row.spol == komitent.spol) &&
        (!row.djelatnost || row.djelatnost == djelatnost) &&
        row.vrstaProizvoda == tipKreditnogProizvoda &&
        (!row.vrstaIsplate || row.vrstaIsplate == vrstaIsplate) &&
        (!row.vrstaKredita || row.vrstaKredita == vrstaKredita) &&
        (!row.namjena || row.namjena == namjena)
      ) {
        if (!!row.akcija)     
          actions.push({ value: row.id, text: row.akcija ? row.akcija : 'Bez akcije' });
        
        if (akcija && row.id == akcija) matchingRows.push(row);
        if (!akcija && !row.pocetakAkcije && !row.krajAkcije) matchingRows.push(row);
      }
    }
    actions.sort(function (a, b) {
      if (!a.text) return -1;
      if (!b.text) return 1;
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
    if (!_.isEqual(promotions, actions)) setPromotions(actions);
    let isValid = iznos !== '' && brojRata !== '' && grace !== '' && tipKreditnogProizvoda !== '';

    postojiOpcija(isValid && matchingRows.length == 1);
    if (matchingRows.length == 1) {
      setKamata(Math.round(matchingRows[0].interest * 100 + Number.EPSILON) / 100);
      let rata;
      if (matchingRows[0].interest != 0) {
        rata =
          (((iznos * (matchingRows[0].interest)) / 100) * (1 + matchingRows[0].interest / 100) ** brojRata) /
            ((1 + matchingRows[0].interest / 100) ** brojRata - 1);
        rata = Math.round(rata * 100) / 100;
      }

      else rata = Math.round(iznos / brojRata, 1);
      setRata(rata);
    } else {
      setKamata('');
      setRata(0);
    }

    return (
      <>
        {loanMatrix.data && isValid && matchingRows.length == 0 && (
          <Add>
            <StyledTooltip
              arrow
              title="Odabrane opcije ne ispunjavaju uslove nijednog kreditnog proizvoda"
              enterTouchDelay={0}
              placement="top"
            >
              <Fab>
                <AiOutlineExclamation size={40} />
              </Fab>
            </StyledTooltip>
          </Add>
        )}
      </>
    );
  } else return <></>;
};
const Add = styled(Box)`
  ${({ theme }) => `
  position: fixed;
  bottom: 70px;
  right: 20px;
  size:20;
  z-index:1000;
  
  @media (min-width: 1100px) {
    right:30%
  }
  & .MuiFab-root {
    border-radius: 50% !important;
    background-color: ${theme.palette.error.main};
    color: white;
  }
  `}
`;
const StyledTooltip = withStyles({
  tooltip: {
    top: '16px',
  },
})(Tooltip);
export default MatrixBadgeMikra;
