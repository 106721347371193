import React from 'react';
import { styled, FormControl, TextField, Typography, Fab, Grid, Box } from '@mui/material';

const InputText = ({ props, position }) => {
  return (
    <FormControl fullWidth>
      <Box mb={5}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={1.5} md={1}>
            <QuestionNumber size="small">{position}</QuestionNumber>
          </Grid>
          <Grid item xs={10.5} md={11}>
            <Typography variant="body1">{props.name}</Typography>
          </Grid>
        </Grid>
      </Box>
      <TextField label="Unesite odgovor" multiline rows={2} disabled />
    </FormControl>
  );
};

const QuestionNumber = styled(Fab)`
  ${({ theme }) => `
  background-color: #7B78FE;
  color: white;
  min-height:0;
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px;
  `}
`;

export default InputText;
