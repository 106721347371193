import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { AiFillIdcard, AiFillPhone, AiOutlinePlus } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { Link, Redirect } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { getAllKomitenti, notifUnreadCount } from '../api';
import komitentCount from '../api/Komitent/komitentCount';
import { ClientAvatar, ClientSearchBar, ServerError } from '../components';
import ClientSearchBarPartner from '../components/ClientSearchBarPartner';
import selectClient from '../hooks/selectClient';
import useOfficerProfile from '../hooks/useOfficerProfile';
import HomeLayout from '../layout/HomeLayout';
import { scrollToTop } from '../utils';

const Clients = () => {
  //partner: UNAME = 3395;
  //mikra: UNAME = 8350;

  const { officerSifra, officerUsername } = useOfficerProfile();
  const [skip, setSkip] = useState(0); 
  const [take, setTake] = useState(10);
  const [curretnPage, setCurrentPage] = useState(0);

  const { data, error, isLoading, isPreviousData, isFetching } = useQuery({
    queryKey: [`getAllKomitenti ${officerSifra} ${officerUsername} ${skip} ${take}`],
    queryFn: () => getAllKomitenti(officerSifra, officerUsername, skip, take),
    enabled: !!officerSifra,
    keepPreviousData: true
  }, [skip]);
  
  const res = useQuery(
    ['komitentCount', officerUsername],
    () => komitentCount(officerUsername),
    {
      enabled: !!officerSifra,
    }
  );

  const { refetch: refetchUnreadNotif } = useQuery({
    queryKey: [`notifUnreadCount ${officerSifra}`],
    queryFn:() => notifUnreadCount(officerSifra), 
    enabled: !!officerSifra && selectClient() == 'Partner',
    onSuccess: (data) => {
      localStorage.setItem('numOfUnreadNotif', data.totalCount)
    }
  }
);

  const [checked, setChecked] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [client, setClient] = useState({});

  const theme = useTheme();
  const listItemClick = (dataRow) => {
    setClient(dataRow);
    setRedirect(true);
    scrollToTop();
  };
  const searchAllKomitentiCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handlePageChange = (event) => {
      const newSkip = event.selected  * take;
      setSkip(newSkip);
      setCurrentPage(event.selected)
  }

  const Row = ({ index, style }) => {
    let clientRow = data[index];
    let clientName = `${clientRow.ime} ${clientRow.otac == null ? '' : `(${clientRow.otac})`}
     ${clientRow.prezime == null ? '' : clientRow.prezime}`;
    return (
      <Box style={style}>
        <ListItem
          key={clientRow.komitent}
          id={index}
          button
          component={Link}
          to={{ pathname: '/client', state: { client: clientRow } }}
        >
          <ListItemAvatar sx={{ mr: 2 }}>
            <ClientAvatar type={clientRow.vrstaKomitenta} gender={clientRow.spol} delinquent={clientRow.delikvent} />
          </ListItemAvatar>
          <ListItemText
            primary={clientName}
            secondary={
              <Box display="flex" flex-direction="row" mt={1}>
                <Box display="flex" justifyContent="center" mr={1}>
                  <AiFillIdcard color={theme.palette.gray} size={18} />
                </Box>
                <Typography variant="body2">{clientRow.komitent}</Typography>
                <Box display="flex" justifyContent="center" ml={2} mr={1}>
                  <AiFillPhone color={theme.palette.gray} size={18} />
                </Box>
                <Typography variant="body2">{clientRow.telefon}</Typography>
              </Box>
            }
            primaryTypographyProps={{ variant: 'subtitle1', noWrap: true }}
            secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
          />
        </ListItem>

        <Divider />
      </Box>
    );
  };
  if (redirect) return <Redirect to={{ pathname: '/client', state: { client } }} />;

  return (
    <HomeLayout>
      <Box pl={2} pr={2} pt={4}>
        <Typography variant="h3">Komitenti</Typography>
        {selectClient() == 'Partner' && (
          <Box>
            <ClientSearchBarPartner checked={checked} clientSelect={listItemClick} placeholder="Pretraži komitente" />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={searchAllKomitentiCheck} />}
                label="Pretražuj sve komitente"
              />
            </FormGroup>
          </Box>
        )}
        {selectClient() == 'Mikra' && <ClientSearchBar clientSelect={listItemClick} placeholder="Pretraži komitente" />}
        {selectClient() == 'Lider' && <ClientSearchBar clientSelect={listItemClick} placeholder="Pretraži komitente" />}

        {error && <ServerError error={error} />}
        {!error && (
          <>
            {!isPreviousData && !isLoading && !!data ? (

              <Box>
                <List itemCount={data.length} height={600} itemSize={80}>
                  {Row}
                </List>

                <div style={{ textAlign: 'center', marginTop: '10%', marginBottom: '30%' }}>


                <style>
                  {`
                    .pagination {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      list-style: none;
                    }

                    .page-link {
                      position: relative;
                      display: block;
                      color: #0d6efd;
                      text-decoration: none;
                      background-color: #fff;
                      border: 1px solid #dee2e6;
                      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                      font-family: Helvetica;
                      border-radius: 0.45rem; 
                      margin-right: 5px;
                    }

                    @media (prefers-reduced-motion: reduce) {
                      .page-link {
                        transition: none;
                      }
                    }

                    .page-link:hover {
                      z-index: 2;
                      color: #0a58ca;
                      background-color: #e9ecef;
                      border-color: #dee2e6;
                    }

                    .page-link:focus {
                      z-index: 3;
                      color: #0a58ca;
                      background-color: #e9ecef;
                      outline: 0;
                      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                    }

                    .page-item:not(:first-child) .page-link {
                      margin-left: -1px;
                    }

                    .page-item.active .page-link {
                      z-index: 3;
                      color: #fff;
                      background-color: #0d6efd;
                      border-color: #0d6efd;
                    }

                    .page-item.disabled .page-link {
                      color: #6c757d;
                      pointer-events: none;
                      background-color: #fff;
                      border-color: #dee2e6;
                    }

                    .page-link {
                      padding: 0.375rem 0.75rem;
                    }

                    .page-item:first-child .page-link {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                    }

                    .page-item:last-child .page-link {
                      border-top-right-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                    }

                    .pagination-lg .page-link {
                      padding: 0.75rem 1.5rem;
                      font-size: 1.25rem;
                    }

                    .pagination-lg .page-item:first-child .page-link {
                      border-top-left-radius: 0.3rem;
                      border-bottom-left-radius: 0.3rem;
                    }

                    .pagination-lg .page-item:last-child .page-link {
                      border-top-right-radius: 0.3rem;
                      border-bottom-right-radius: 0.3rem;
                    }

                    .pagination-sm .page-link {
                      padding: 0.25rem 0.5rem;
                      font-size: 0.875rem;
                    }

                    .pagination-sm .page-item:first-child .page-link {
                      border-top-left-radius: 0.2rem;
                      border-bottom-left-radius: 0.2rem;
                    }

                    .pagination-sm .page-item:last-child .page-link {
                      border-top-right-radius: 0.2rem;
                      border-bottom-right-radius: 0.2rem;
                    }
                  `}
                </style>

                  <ReactPaginate
                    pageCount={Math.ceil(res?.data ? (res.data.totalCount / take) : (1000 / take))}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousLabel={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                    nextLabel={<ArrowForwardIosIcon style={{ fontSize: 12 }} />}
                    forcePage={curretnPage}
                  />
                </div>

              </Box>
            ) : (
              // <Box>
              //   {Array(10)
              //     .fill()
              //     .map((_, index) => {
              //       return (
              //         <Skeleton
              //           key={index}
              //           variant="rectangular"
              //           width="100%"
              //           height={52}
              //           sx={{ mb: '14px', mt: '14px' }}
              //         />
              //       );
              //     })}
              // </Box>
              <Box display="flex" justifyContent="center" alignItems="center" height={600}>
              <CircularProgress />
            </Box>
            )}
          </>
        )}
        <Add id="addButton">
          <Fab component={Link} to="/client/create" size="medium">
            <AiOutlinePlus size={40} />
          </Fab>
        </Add>
      </Box>
    </HomeLayout>
  );
};

const Add = styled(Box)`
  ${({ theme }) => `
  position: fixed;
  bottom: 70px;
  right: 20px;
  size:20;
  @media (min-width: 1100px) {
    right:30%
  }
  & .MuiFab-root {
    border-radius: 50% !important;
    background-color: ${theme.palette.primary.main};
    color: white;
  }
  `}
`;
export default Clients;
