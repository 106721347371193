const dateFormatReverse = (date) => {
  if (date) {
    let datum = new Date(date).toLocaleDateString('de-DE');
    if (datum.substring(1, 2) == '.') {
      datum = '0' + datum.substring(0, 1) + datum.substring(1, 9);
    }
    if (datum.substring(4, 5) == '.') {
      datum = datum.substring(0, 3) + '0' + datum.substring(3, 9);
    }
    return datum;
  } else return null;
};
export default dateFormatReverse;
