import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { IoTrashOutline } from 'react-icons/io5';
import { MdSignalWifiConnectedNoInternet4, MdSignalWifiStatusbarConnectedNoInternet1 } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import selectClient from '../hooks/selectClient';
import { scrollToTop } from '../utils';

const TopBar = ({ backLink, forwardLink, text, setOpen }) => {
  const theme = useTheme();
  const Vrijeme = () => {

    if (localStorage.getItem('vrijeme')) {

      let vrijeme = localStorage.getItem('vrijeme');
      if (vrijeme > 0 && vrijeme < 5000) {
        return <MdSignalWifiStatusbarConnectedNoInternet1 size={26} color="white" />;
      } else if (vrijeme >= 5000 && vrijeme < 15000) {
        return (
          <Tooltip arrow title="Slaba konekcija" enterTouchDelay={0} placement="top">
            <IconButton size="small">
              <MdSignalWifiStatusbarConnectedNoInternet1 size={26} color={theme.palette.warning.main} />
            </IconButton>
          </Tooltip>
        );
      } else if (vrijeme >= 15000 || vrijeme == -1) {
        return (
          <Tooltip arrow title="Nemate internet konekciju" enterTouchDelay={0} placement="top">
            <IconButton size="small">
              <MdSignalWifiConnectedNoInternet4 size={26} color={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };
  return (
    <StyledBox
      position="fixed"
      zIndex={1000}
      width="100%"
      height="50px"
      display="flex"
      bgcolor={theme.palette.background.default}
      color={theme.palette.darkGray}
    >
      <Box display="flex" justifyContent="center">
        <IconButton component={Link} to={backLink} size="large" sx={{ color: 'black' }} onClick={scrollToTop}>
          <BsArrowLeft size={20} id="backIcon" />
        </IconButton>
      </Box>
      {(selectClient() == 'Partner' || selectClient() == 'Mikra') && (
        <Box mt={2} display="flex" justifyContent="center">
          {Vrijeme()}
        </Box>
      )}
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <Typography variant="h6">{text}</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        {forwardLink && (
          <IconButton component={Link} to={forwardLink} size="large" onClick={scrollToTop}>
            <AiOutlineEdit size={20} id="editIcon" />
          </IconButton>
        )}
        {!forwardLink && !setOpen && <Box width="50px" />}
        {setOpen && selectClient() != 'Lider' && (
          <IconButton size="large" onClick={() => setOpen(true)}>
            <IoTrashOutline size={20} id="trashIcon" />
          </IconButton>
        )}
      </Box>
    </StyledBox>
  );
};
const StyledBox = styled(Box)`
  max-width: 750px;
  margin: auto;
`;
export default TopBar;
