import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { editKomitent, fetchRegistry } from '../api';
import { ClientEditFormMikra, ServerError } from '../components';
import useOfficerProfile from '../hooks/useOfficerProfile';
import Layout from '../layout';
import { comitentTypeFromJMBG, genderFromJMBG, getFirstLine } from '../utils';
import NotFound from './404';

const ClientEditMikra = () => {
  const location = useLocation();
  //const [editedClient, setEditedClient] = useState();
  const [redirect, setRedirect] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { officerSifra } = useOfficerProfile();
  const { mutateAsync: mutateEdit } = useMutation(editKomitent, {
    onSuccess: () => {
      setRedirect(true);
      enqueueSnackbar('Uspješno ste spremili promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { data, error } = useQuery('registry', fetchRegistry);

  let client;
  if (location.state) client = location.state.client;
  else return <NotFound />;

  const handleSubmit = (values) => {
    values.modifiedBy = officerSifra;
    values.type = comitentTypeFromJMBG(values.jmbg);
    if (values.type == 'F') {
      values.gender = genderFromJMBG(values.jmbg);
    } else values.gender = null;
    return mutateEdit(values);
    //setEditedClient(values);
  };

  if (redirect) return <Redirect to="/clients" />;
  return (
    <Layout
      topBarProps={{
        backLink: { pathname: '/client', state: { client } },
        text: 'Uredi komitenta',
      }}
    >
      {error && <ServerError error={error} />}
      {data && (
        <Box>
          <Box height="8px" />
          <ClientEditFormMikra handleSubmit={handleSubmit} registry={data} client={client}></ClientEditFormMikra>
        </Box>
      )}
    </Layout>
  );
};
export default ClientEditMikra;
