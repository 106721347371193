import React from 'react';
import { Box, Typography, Fab, Grid, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchSurvey, getSurveyAnalytics } from '../api';
import { useQuery } from 'react-query';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import styled from '@emotion/styled';
import Layout from '../layout';
import Spinner from '../components/Spinner';
import SpecialListItem from '../components/Survey/SpecialListItem';
import AnalyticsIcon from '../images/AnalyticsIcon.svg';

const Analytics = () => {
  const { id } = useParams();
  const theme = useTheme();

  const { isLoading, data } = useQuery(['fetchSurvey', id], () => fetchSurvey(id));
  const { isLoading: isLoadingAnalytics, data: dataAnalytics } = useQuery(['fetchAnalytics', id], () =>
    getSurveyAnalytics(id)
  );

  return (
    <Layout
      topBarProps={{
        backLink: { pathname: '/survey' },
        text: 'Analitika',
      }}
    >
      {(isLoading || isLoadingAnalytics) && <Spinner />}
      {!isLoading && !isLoadingAnalytics && (
        <AnalyticsContainer pl={5} pr={5} sx={{ overflow: 'hidden' }}>
          <Box p={5}>
            <SpecialListItem data={data} image={AnalyticsIcon} background="#7B78FE" />
            {dataAnalytics.map((stats, i) => (
              <Box mt={10}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item xs={1.5} md={1}>
                    <QuestionNumber size="small">{i + 1}</QuestionNumber>
                  </Grid>
                  <Grid item xs={10.5} md={11}>
                    <Typography variant="body1">{stats?.pitanje}</Typography>
                  </Grid>
                </Grid>
                <Box mt={5} width="100%" display="flex" justifyContent="center">
                  <ResponsiveContainer width="85%" height={400}>
                    <ComposedChart
                      width={150}
                      height={40}
                      data={stats?.odgovori}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis />
                      <YAxis tick={{ dx: -15 }} width={40} />
                      <Tooltip labelFormatter={(index) => stats?.odgovori[index]?.odgovor} />
                      <Legend />
                      <Bar
                        dataKey="brojac"
                        barSize={20}
                        name={
                          <Typography variant="body2" display="inline">
                            Broj odgovora
                          </Typography>
                        }
                        fill={theme.palette.primary.main}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            ))}
          </Box>
        </AnalyticsContainer>
      )}
    </Layout>
  );
};

const AnalyticsContainer = styled(Box)`
  ${({ theme }) => `
  min-width: 100%;
  height: 100%;
  margin-left: -6%;
  margin-right: -6%;
  background-color: ${theme.palette.background.survey};
`}
`;

const QuestionNumber = styled(Fab)`
  ${({ theme }) => `
  background-color: #7B78FE;
  color: white;
  min-height:0;
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px;
  `}
`;

export default Analytics;
