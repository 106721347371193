import { Avatar, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transparentize } from 'polished';
import React from 'react';
import { MdNotifications } from "react-icons/md";

const NotificationAvatar = ({ pregledana }) => {
  const theme = useTheme();
  let backgroundColor;
  if (pregledana === 'D') backgroundColor = '#EDFAF4';
  else backgroundColor = transparentize(0.9, theme.palette.error.main);
  return (
    <Box display="flex">
      <AvatarOutline backgroundcolor={backgroundColor}>
        {pregledana === 'D' && <MdNotifications size={25} color={'#56B884'} />}
        {pregledana === 'N' && <MdNotifications size={25} color={theme.palette.error.main} />}
      </AvatarOutline>
    </Box>
  );
};

const AvatarOutline = styled(Avatar)`
  background-color: ${({ backgroundcolor }) => backgroundcolor || '#E6E6E6'};
  height: 52px;
  width: 52px;
  border-radius: 8px;
`;

export default NotificationAvatar;
