import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Input } from '@components/rhf/src/MUI5';
import { getFirstLine } from '../utils';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAnalysis } from '../api';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { IoTrashOutline } from 'react-icons/io5';

const AnalysisCodebtorLegal = ({ analysis, suduznik, brojZahtjeva, next, mutateEdit, mutateAdd }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const methodsPravnoLiceSuduznik = useForm({
    defaultValues: analysis,
  });
  const queryClient = useQueryClient();

  const handleSubmit = (values) => {
    values.brojZahtjeva = brojZahtjeva;
    values.komitent = suduznik.suduznik;
    if (
      !(
        values.description == analysis.description &&
        values.legalEntityIncome == analysis.legalEntityIncome &&
        values.value == analysis.value
      )
    ) {
      return mutateEdit(values);
    } else {
      enqueueSnackbar('Nije došlo do promjena u analizi.', {
        variant: 'warning',
        autoHideDuration: 1000,
      });
    }
  };
  const addNewAnalysis = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: suduznik.suduznik };
    mutateAdd(values);
  };
  const { mutate: mutateDelete } = useMutation(deleteAnalysis, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno obrisana stavka analize.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
      queryClient.invalidateQueries(['getAnaliza', brojZahtjeva]);
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setDeleteOpen(false);
    },
  });
  const deleteAnalysisF = () => {
    let values = { brojZahtjeva: brojZahtjeva, komitent: suduznik.suduznik };
    mutateDelete(values);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography mb={4} variant="subtitle1" color="primary">
            Sudužnik: {suduznik.name || '-'}
          </Typography>
        </Grid>
        {analysis && (
          <Grid item xs={2}>
            <IconButton size="large" onClick={() => setDeleteOpen(true)}>
              <IoTrashOutline size={20} id="trashIcon" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {!analysis ? (
        <Button
          //disabled={isSubmitting}
          //startIcon={isSubmitting && <CircularProgress size={20} />}
          onClick={() => addNewAnalysis()}
          variant="contained"
          color="primary"
          type="submit"
        >
          Dodaj analizu
        </Button>
      ) : (
        <Form spacing={3} methods={methodsPravnoLiceSuduznik} onSubmit={handleSubmit}>
          <Typography mb={3} variant="h6" color="primary">
            Podaci o pravnom licu sudužniku
          </Typography>
          <Grid container mb={3}>
            <Grid item xs={10}>
              <Typography variant="body2">Rata traženog kredita</Typography>
            </Grid>
            <Grid item xs={2}>
              <BotText variant="subtitle1">
                {Math.round(analysis.loanInstallment * 1000 + Number.EPSILON) / 1000 || '0'}
              </BotText>
            </Grid>
          </Grid>
          <Input label="Prihodi pravnog lica" name="legalEntityIncome" type="number" />
          <Grid container mb={3}>
            <Grid item xs={10}>
              <Typography variant="body2">Pokazatelj pokrivenosti rate&ge; 1</Typography>
            </Grid>
            <Grid item xs={2}>
              <BotText variant="subtitle1">
                {Math.round(analysis.rateCoverageIndicatorLegal * 1000 + Number.EPSILON) / 1000 || '0'}
              </BotText>
            </Grid>
          </Grid>
          <DividerStyle />

          <Typography mb={3} variant="h6" color="primary">
            Podaci o zalogu pokretne imovine
          </Typography>
          <Input label="Opis" name="description" multiline />
          <Input label="Vrijednost" name="value" type="number" />
          <Grid container mb={3}>
            <Grid item xs={10}>
              <Typography variant="body2">Pokazatelj pokrivenosti rate {'>'} 1.5</Typography>
            </Grid>
            <Grid item xs={2}>
              <BotText variant="subtitle1">
                {Math.round(analysis.rateCoverageIndicator * 1000 + Number.EPSILON) / 1000 || '0'}
              </BotText>
            </Grid>
          </Grid>

          <Button onClick={() => handleSubmit} variant="contained" color="primary" type="submit">
            Spremi promjene
          </Button>
          <Box height="16px"></Box>
        </Form>
      )}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{'Brisanje analize'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Obrisati odabranu analizu?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAnalysisF}>Da</Button>
          <Button onClick={() => setDeleteOpen(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
const BotText = styled(Typography)`
  margin-bottom: 12px;
`;
const DividerStyle = styled(Divider)`
  ${({ theme }) => `
  margin-bottom:12px;
  background-color:${theme.palette.darkGray};
`}
`;
export default AnalysisCodebtorLegal;
