import React from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { useQuery } from 'react-query';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  fetchRegistry,
  getIzvorPrihoda,
  getTipDokumenta,
  getUserApplications,
  getUserCollaterals,
  getUserLoans,
} from '../api';
import { dateFormatReverse } from '../utils';
import ServerError from './ServerError';
import Spinner from './Spinner';

const ClientDetailsLider = ({ client }) => {
  const registry = useQuery('registry', fetchRegistry);
  const incomeSources = useQuery('incomeSources', getIzvorPrihoda);
  const loans = useQuery(['getUserLoans', client.komitent], () => getUserLoans(client.komitent));
  const applications = useQuery(['getUserApplications', client.IdKomitent], () =>
    getUserApplications(client.IdKomitent)
  );
  const collaterals = useQuery(['getUserCollaterals', client.IdKomitent], () => getUserCollaterals(client.IdKomitent));
  const documentTypes = useQuery('getDocumentTypes', getTipDokumenta);
  const theme = useTheme();

  const getIzvorName = (sifra) => {
    for (let i = 0; i < incomeSources.data.length; i++) {
      if (sifra == incomeSources.data[i].value) return incomeSources.data[i].text;
    }
    return null;
  };
  const getBracniStatusName = (sifra) => {
    for (let i = 0; i < registry.data.martialStatus.length; i++) {
      if (sifra == registry.data.martialStatus[i].value) return registry.data.martialStatus[i].text;
    }
    return null;
  };
  const nazivGrada = (sifra) => {
    for (let i = 0; i < registry.data.municipalities.length; i++) {
      if (registry.data.municipalities[i].value === sifra) {
        return registry.data.municipalities[i].text;
      }
    }
  };
  const mjestoStanovanjaname = (sifra) => {
    for (let i = 0; i < registry.data.localCommunities.length; i++) {
      if (sifra == registry.data.localCommunities[i].value) return registry.data.localCommunities[i].text;
    }
    return null;
  };
  const statusZaposlenja = (sifra) => {
    for (let i = 0; i < registry.data.employmentStatus.length; i++) {
      if (sifra == registry.data.employmentStatus[i].value) return registry.data.employmentStatus[i].text;
    }
    return null;
  };
  const zemljaPorijekla = (sifra) => {
    for (let i = 0; i < registry.data.countryOfOrigin.length; i++) {
      if (sifra == registry.data.countryOfOrigin[i].value) return registry.data.countryOfOrigin[i].text;
    }
    return null;
  };
  const getTipDokumentaName = (sifra) => {
    for (let i = 0; i < documentTypes.data.length; i++) {
      if (sifra == documentTypes.data[i].value) return documentTypes.data[i].text;
    }
    return null;
  };

  return (
    <Box>
      <Box textAlign="center" m={4} pt={2} pb={2} mt={2}>
        <Typography variant="h6">
          {client.ime} {client.otac == null ? '' : `(${client.otac})`} {client.prezime}
        </Typography>
      </Box>
      {(registry.error || incomeSources.error || documentTypes.error) && (
        <ServerError error={registry.error || incomeSources.error || documentTypes.error} />
      )}
      {(registry.isLoading || incomeSources.isLoading || documentTypes.isLoading) && <Spinner />}
      {registry.data && incomeSources.data && documentTypes.data && (
        <Box>
          <StyledAccordion variant={'outlined'} defaultExpanded={true}>
            <AccordionSummary expandIcon={<AiFillCaretDown />}>
              <Typography variant="h5"> Detalji komitenta </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0} ml={1}>
                    <Typography variant="body2">Tip</Typography>
                    <BotText variant="subtitle1">
                      {client.vrstaKomitenta == 'F' && 'Fizičko lice'}
                      {client.vrstaKomitenta == 'P' && 'Pravno lice'}
                    </BotText>
                    <Typography variant="body2">Broj mobitela</Typography>
                    <BotText variant="subtitle1">{client.telefon || '-'}</BotText>
                    <Typography variant="body2">Adresa</Typography>
                    <BotText variant="subtitle1">{client.adresa || '-'}</BotText>
                    <Typography variant="body2">Grad</Typography>
                    <BotText noWrap variant="subtitle1">
                      {nazivGrada(client.opstina) || '-'}
                    </BotText>
                    <Typography variant="body2">Mjesto stanovanja</Typography>
                    <BotText noWrap variant="subtitle1">
                      {mjestoStanovanjaname(client.mjestoStanovanja) || '-'}
                    </BotText>
                    <Typography variant="body2">Zemlja porijekla</Typography>
                    <BotText variant="subtitle1">{zemljaPorijekla(client.zemljaPorijekla) || '-'}</BotText>
                    <Typography variant="body2">Tip dokumenta</Typography>
                    <BotText variant="subtitle1">{getTipDokumentaName(client.tipDokumenta) || '-'}</BotText>
                    <Typography variant="body2">Datum isteka ID dokumenta</Typography>
                    <BotText variant="subtitle1">
                      {client.datumIstekaLK ? dateFormatReverse(client.datumValidnostiIdDok) : '-'}
                    </BotText>
                    <Typography variant="body2">Izvor prihoda</Typography>
                    <BotText noWrap variant="subtitle1">
                      {getIzvorName(client.izvorPrihoda) || '-'}
                    </BotText>
                    <Typography variant="body2">Status zaposlenja</Typography>
                    <BotText variant="subtitle1">{statusZaposlenja(client.statusZaposlenja) || '-'}</BotText>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0}>
                    <Typography variant="body2">JMBG</Typography>
                    <BotText variant="subtitle1">{client.komitent || '-'}</BotText>
                    <Typography variant="body2">Broj fiksnog</Typography>
                    <BotText variant="subtitle1">{client.telefonFix || '-'}</BotText>
                    <Typography variant="body2">Adresa cips</Typography>
                    <BotText variant="subtitle1">{client.adresaCips || '-'}</BotText>
                    <Typography variant="body2">Grad cips</Typography>
                    <BotText noWrap variant="subtitle1">
                      {client.opcinaCips || '-'}
                    </BotText>
                    <Typography variant="body2">Općina rođenja</Typography>
                    <BotText variant="subtitle1">{nazivGrada(client.opcinaRodjenja) || '-'}</BotText>
                    <Typography variant="body2">Spol</Typography>
                    <BotText variant="subtitle1">
                      {client.spol == registry.data.genders[0].value && registry.data.genders[0].text}
                      {client.spol == registry.data.genders[1].value && registry.data.genders[1].text}
                      {client.spol != registry.data.genders[0].value &&
                        client.spol != registry.data.genders[1].value &&
                        '-'}
                    </BotText>
                    <Typography variant="body2">ID dokumenta</Typography>
                    <BotText variant="subtitle1">{client.idDokumenta || '-'}</BotText>
                    <Typography variant="body2">Organ izdavanja ID dokumenta</Typography>
                    <BotText variant="subtitle1">{client.organIzdavanjaIdDok || '-'}</BotText>
                    <Typography variant="body2">Bračni status</Typography>
                    <BotText noWrap variant="subtitle1">
                      {getBracniStatusName(client.statusKomitenta) || '-'}
                    </BotText>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" p={1} pt={0} m={1} mt={0}>
                    <Typography variant="body2">Napomena</Typography>
                    <BotText variant="subtitle1">{client.napomena || '-'}</BotText>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion variant={'outlined'}>
            <AccordionSummary expandIcon={<AiFillCaretDown />}>
              <Typography variant="h5"> Statusi </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h4"> Krediti komitenta </Typography>
              {!!loans.data && (
                <List sx={{ width: '100%' }}>
                  {loans.data.length > 0 ? (
                    loans.data.map((loan) => {
                      return (
                        <Box
                          mt={2}
                          key={loan.zahtjev_id}
                          sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                        >
                          <ListItem key={loan.zahtjev_id} alignItems="flex-start">
                            <Grid container>
                              <Box width="100%" ml={2}>
                                <Typography variant="subtitle1">{`Broj ugovora: ${loan.BROJUGOVORA}`}</Typography>
                              </Box>
                              <Grid item xs={6}>
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="column"
                                  p={1}
                                  m={1}
                                  pb={0}
                                  mb={0}
                                  ml={1}
                                >
                                  <Typography variant="body2">Broj</Typography>
                                  <BotText variant="subtitle1">{loan.broj || '-'}</BotText>
                                  <Typography variant="body2">Iznos</Typography>
                                  <BotText variant="subtitle1">{loan.iznos ? `${loan.iznos} KM` : '-'}</BotText>
                                  <Typography variant="body2">Broj dana kašnjenja</Typography>
                                  <BotText variant="subtitle1">
                                    {loan.DANIKASNJENJA ? `${Math.round(loan.DANIKASNJENJA * 100) / 100}` : '0'}
                                  </BotText>
                                  <Typography variant="body2">Datum statusa</Typography>
                                  <BotText variant="subtitle1">
                                    {loan.datumStatusa ? dateFormatReverse(loan.datumStatusa) : '-'}
                                  </BotText>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0}>
                                  <Typography variant="body2">Status</Typography>
                                  <BotText variant="subtitle1">{loan.status || '-'}</BotText>
                                  <Typography variant="body2">Saldo</Typography>
                                  <BotText variant="subtitle1">
                                    {loan.SALDO ? `${Math.round(loan.SALDO * 100) / 100} KM` : '-'}
                                  </BotText>
                                  <Typography variant="body2">Ukupno za platiti</Typography>
                                  <BotText variant="subtitle1">
                                    {loan.UKUPNOZAPLATITI ? `${loan.UKUPNOZAPLATITI} KM` : '0'}
                                  </BotText>
                                  <Typography variant="body2">Savjetnik za klijente</Typography>
                                  <BotText variant="subtitle1">{loan.imeSluzbenika || '-'}</BotText>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      pt={4}
                      pb={4}
                      pl={2}
                      sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                    >
                      <Typography variant="subtitle1">Nema podataka</Typography>
                    </Box>
                  )}
                </List>
              )}
              <Typography variant="h4" mt={2}>
                {' '}
                Zahtjevi komitenta{' '}
              </Typography>
              {!!applications.data && (
                <List sx={{ width: '100%' }}>
                  {applications.data.length > 0 ? (
                    applications.data.map((application) => {
                      return (
                        <Box
                          mt={2}
                          key={application.brojzahtjeva}
                          sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                        >
                          <ListItem key={application.brojzahtjeva} alignItems="flex-start">
                            <Grid container>
                              <Box width="100%">
                                <Box width="100%" ml={2}>
                                  <Typography variant="subtitle1">{`Broj zahtjeva: ${application.brojzahtjeva}`}</Typography>
                                </Box>
                              </Box>
                              <Grid item xs={6}>
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="column"
                                  p={1}
                                  m={1}
                                  pb={0}
                                  mb={0}
                                  ml={1}
                                >
                                  <Typography variant="body2">Status</Typography>
                                  <BotText variant="subtitle1">{application.status || '-'}</BotText>
                                  <Typography variant="body2">Datum zahtjeva</Typography>
                                  <BotText variant="subtitle1">
                                    {application.datumzahtjeva ? dateFormatReverse(application.datumzahtjeva) : '-'}
                                  </BotText>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0}>
                                  <Typography variant="body2">Iznos</Typography>
                                  <BotText variant="subtitle1">
                                    {application.iznos ? `${application.iznos} KM` : '-'}
                                  </BotText>
                                  <Typography variant="body2">Savjetnik za klijente</Typography>
                                  <BotText variant="subtitle1">{application.imeSluzbenika || '-'}</BotText>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      pt={4}
                      pb={4}
                      pl={2}
                      sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                    >
                      <Typography variant="subtitle1">Nema podataka</Typography>
                    </Box>
                  )}
                </List>
              )}
              <Typography variant="h4" mt={2}>
                {' '}
                Sudužnik{' '}
              </Typography>
              {!!collaterals.data && (
                <List sx={{ width: '100%' }}>
                  {collaterals.data.length > 0 ? (
                    collaterals.data.map((collateral) => {
                      return (
                        <Box
                          mt={2}
                          key={collateral.brojzahtjeva}
                          sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                        >
                          <ListItem key={collateral.brojzahtjeva} alignItems="flex-start">
                            <Grid container>
                              <Box width="100%" ml={2}>
                                <Typography variant="subtitle1">{`Broj zahtjeva: ${collateral.brojzahtjeva}`}</Typography>
                              </Box>
                              <Grid item xs={6}>
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="column"
                                  p={1}
                                  m={1}
                                  pb={0}
                                  mb={0}
                                  ml={1}
                                >
                                  <Typography variant="body2">Status</Typography>
                                  <BotText variant="subtitle1">{collateral.status || '-'}</BotText>
                                  <Typography variant="body2">Datum zahtjeva</Typography>
                                  <BotText variant="subtitle1">
                                    {collateral.datumzahtjeva ? dateFormatReverse(collateral.datumzahtjeva) : '-'}
                                  </BotText>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box width="100%" display="flex" flexDirection="column" p={1} m={1} pb={0} mb={0}>
                                  <Typography variant="body2">Iznos</Typography>
                                  <BotText variant="subtitle1">
                                    {collateral.iznos ? `${collateral.iznos} KM` : '-'}
                                  </BotText>
                                  <Typography variant="body2">Savjetnik za klijente</Typography>
                                  <BotText variant="subtitle1">{collateral.imeSluzbenika || '-'}</BotText>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      pt={4}
                      pb={4}
                      pl={2}
                      sx={{ backgroundColor: `${theme.palette.primary.main}22`, borderRadius: '8px' }}
                    >
                      <Typography variant="subtitle1">Nema podataka</Typography>
                    </Box>
                  )}
                </List>
              )}
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      )}
    </Box>
  );
};
const BotText = styled(Typography)`
  margin-bottom: 24px;
`;
const StyledAccordion = styled(Accordion)`
  border-radius: 8px !important;
  margin-bottom: 16px !important;
  margin-top: 8px !important;
  &:before {
    display: none;
  }
`;
const StyledChip = styled(Chip)`
  font-size: 0.75rem;
  margin-bottom: 6px;
  height: 16px;
  width: 80px;
  border-radius: 3px;
  background-color: ${({ backchipcolor }) => backchipcolor || 'initial'};
  color: ${({ chipcolor }) => chipcolor || 'initial'};
`;
export default ClientDetailsLider;
