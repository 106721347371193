import { Badge, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { AiFillFileText, AiFillIdcard, AiFillReconciliation } from 'react-icons/ai';
import { FaBell, FaMapMarkerAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import selectClient from '../hooks/selectClient';

const NavBar = () => {

  let numOfUnreadNotif = 0; 
  if (selectClient() == 'Partner' && localStorage.getItem('numOfUnreadNotif'))
    numOfUnreadNotif = localStorage.getItem('numOfUnreadNotif');

  let tempPath = useLocation().pathname;
  const [path, setPath] = useState(tempPath);
  return (
    <Box>
      <Nav
        showLabels={false}
        value={path}
        onChange={(event, newValue) => {
          setPath(newValue);
        }}
      >
        <StyledBottomNavigationAction component={Link} to="/" value="/clients" icon={<AiFillIdcard size={25} />} />
        <StyledBottomNavigationAction
          component={Link}
          id="loanapplications"
          to="/loanapplications"
          value="/loanapplications"
          icon={<AiFillFileText size={25} />}
        />

        {(selectClient() == 'Partner') && (
          <StyledBottomNavigationAction 
            component={Link} 
            to="/notifications" 
            value="/notifications" 
            icon={
              <Badge badgeContent={numOfUnreadNotif} color="secondary">
                <FaBell size={25} />
              </Badge>
            }
          />
        )}

        <StyledBottomNavigationAction
          component={Link}
          to="/survey"
          value="/survey"
          icon={<AiFillReconciliation size={25} />}
        />

        {(selectClient() == 'Partner' || selectClient() == 'Lider') && (
          <StyledBottomNavigationAction component={Link} to="/map" value="/map" icon={<FaMapMarkerAlt size={25} />} />
        )}

      </Nav>
    </Box>  
  );
};
const Nav = styled(BottomNavigation)`
  ${({ theme }) => `
    position: fixed;
    z-index:1000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width:100%;
    max-width: 750px;
    box-sizing: border-box;
    border-top:1px solid ${theme.palette.gray};
    `}
`;
const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  ${({ theme }) => `
    padding: 0px !important;
    color:${theme.palette.darkGray};
  `}
`;

export default NavBar;
