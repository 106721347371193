const MuiListItem = {
  styleOverrides: {
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default MuiListItem;
