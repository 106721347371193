import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { styled, Box, Grid, Typography, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { HiOutlineArchive, HiOutlineChartPie, HiDownload } from 'react-icons/hi';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { WrapTitle } from './SurveyTitleWrapper';
import { archiveSurvey, createAnswersExcelFile } from '../../api';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SurveyDownload from './SurveyDownload';
import selectClient from '../../hooks/selectClient';

const SurveyListItem = ({ props, client, handler, surveyId }) => {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [ready, setReady] = useState(false);
  const [creatingExcelFile, setCreatingExcelFile] = useState(false);

  const messageSuccess = 'Uspješno ste arhivirali anketu.';
  const messageError = 'Desila se greška prilikom arhivhiranja ankete. Pokušajte ponovo.';

  const { mutate } = useMutation(archiveSurvey, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllSurveys');
      enqueueSnackbar(messageSuccess, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(messageError, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const handleArchive = () => {
    mutate(props._id);
  };

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      if (isMounted) setReady(true);
    }, 3000);

    //cleanup
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Wrapper pl={5} pt={5} pb={5}>
      <Grid container direction="row" alignItems="center" spacing={5}>
        <Grid xs={8} item>
          <Grid container alignItems="center" direction="row" spacing={5}>
            <Grid align="left" xs={3} item>
              {!ready && (
                <Tooltip title="Preuzimanje" placement="top-start">
                  <span>
                    <IconButton size="small" disabled>
                      <HiDownload />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {ready && (
                <PDFDownloadLink
                  document={<SurveyDownload props={props} />}
                  fileName={`${props._id}.pdf`}
                  style={{ textDecoration: 'none' }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Tooltip title="Preuzimanje" placement="top-start">
                        <span>
                          <IconButton size="small" disabled>
                            <HiDownload />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Preuzimanje" placement="top-start">
                        <span>
                          <IconButton size="small" disabled={!ready}>
                            <HiDownload />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                  }
                </PDFDownloadLink>
              )}

              {(keycloak.hasRealmRole('MK_ADMIN') && selectClient() === 'Mikra') 
                || (keycloak.hasRealmRole('admin') && selectClient() === 'Partner') ? (
                <Tooltip title="Arhiviraj" placement="top-start">
                  <IconButton size="small" onClick={handleArchive}>
                    <HiOutlineArchive />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
              {(keycloak.hasRealmRole('MK_ADMIN') && selectClient() === 'Mikra') 
                || (keycloak.hasRealmRole('admin') && selectClient() === 'Partner') ? (
                <Tooltip title="Analitika" placement="top-start">
                  <IconButton size="small" component={Link} to={`/survey/${props?._id}/analytics`}>
                    <HiOutlineChartPie />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
              {(keycloak.hasRealmRole('MK_ADMIN') && selectClient() === 'Mikra') 
                || (keycloak.hasRealmRole('admin') && selectClient() === 'Partner') ? (
                <Tooltip title="Generisanje excel" placement="top-start">
                  <IconButton
                    size="small"
                    disabled={creatingExcelFile}
                    onClick={() => createAnswersExcelFile(props, surveyId, setCreatingExcelFile)}
                  >
                    <AiOutlineFileExcel />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              xs={8}
              component={Link}
              to={{
                pathname: !!!client && props?.userDependency ? `/survey` : `/survey/${props?._id}`,
                state: { client },
              }}
              onClick={!client && props?.userDependency ? handler : null}
              sx={{ textDecoration: 'none' }}
              item
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="body2">
                    {props?.creation ? new Date(props?.creation).toLocaleDateString('de-DE') : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={props?.name} style={{ whiteSpace: 'pre-line' }}>
                    <WrapTitle variant="subtitle1">{props?.name}</WrapTitle>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={3} item>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6" color="secondary">
                {props?.questions.length}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">Pitanja</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: 8px;
  background-color: white;
`;

export default SurveyListItem;
